import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useAssignmentInfoGqlOps from 'components/AssignmentInfo/useAssignmentInfoGqlOps';
import AssignmentInfoForm, {
  AssignmentInfoFormData,
  defaultAssignmentInfoFormValues,
} from 'components/AssignmentInfoForm';
import { ButtonColor, ButtonType } from 'components/Button';

import useTemporaryAttachments from 'hooks/useTemporaryAttachments';

import {
  cancelCreatingAssignment,
  getActiveTabIndex,
  getAssignmentTypeWeights,
  getCurrentDay,
  getCurrentWeek,
  getParrotRequestId,
  getStudentCourses,
  getStudentVacations,
  selectAssignmentAndScrollToTop,
  setKeepAssignmentSelected,
  updateCurrentWeekAndDay,
} from 'redux/planning';
import { useAppDispatch } from 'redux/store';
import { PlanningTabIndex } from 'redux/types';
import { getIsParrotUser } from 'redux/user';

import { createStudentAssignmentsForAssignment } from 'utils/studentAssignment';

import styles from './NewAssignmentInfo.module.scss';

interface NewAssignmentInfoProps {
  courseId: string;
}

export default function NewAssignmentInfo({
  courseId,
}: NewAssignmentInfoProps): JSX.Element {
  const dispatch = useAppDispatch();

  const isParrotUser = useSelector(getIsParrotUser);
  const parrotRequestId = useSelector(getParrotRequestId);
  const studentCourses = useSelector(getStudentCourses);
  const studentVacations = useSelector(getStudentVacations);
  const activeTabIndex = useSelector(getActiveTabIndex);
  const currentDay = useSelector(getCurrentDay);
  const currentWeek = useSelector(getCurrentWeek);
  const assignmentTypeWeights = useSelector(getAssignmentTypeWeights);

  const { createAssignment, anyLoading } = useAssignmentInfoGqlOps();

  const formMethods = useForm<AssignmentInfoFormData>({
    defaultValues: defaultAssignmentInfoFormValues,
  });
  const { setValue, handleSubmit } = formMethods;

  const {
    tempAttachments,
    remainingAttachments,
    addTempAttachment,
    removeTempAttachment,
    clearTempAttachments,
  } = useTemporaryAttachments();

  useEffect(() => {
    setValue('assignmentTypeOption', {
      label: assignmentTypeWeights[0].customAssignmentType?.name ?? '',
      value: assignmentTypeWeights[0].customAssignmentType?.id ?? null,
    });
  }, [assignmentTypeWeights, setValue]);

  useEffect(() => {
    const initialDay =
      activeTabIndex === PlanningTabIndex.DayWeek
        ? {
            label: `Day ${currentDay}`,
            value: currentDay,
          }
        : null;
    const initialWeek =
      activeTabIndex === PlanningTabIndex.DayWeek
        ? {
            label: `Week ${currentWeek}`,
            value: currentWeek,
          }
        : null;

    setValue('day', initialDay);
    setValue('week', initialWeek);
  }, [currentDay, currentWeek, activeTabIndex, setValue]);

  const handleCreateAssignment = async (data: AssignmentInfoFormData) => {
    const duration = data.duration || 0;
    const day: number | null = data.day?.value ?? null;
    const week: number | null = data.week?.value ?? null;

    dispatch(setKeepAssignmentSelected(true));

    const studentAssignments = !isParrotUser
      ? createStudentAssignmentsForAssignment(
          studentCourses || [],
          studentVacations,
          day,
          week,
          duration
        ) || []
      : [];

    const attachments = tempAttachments.map((attachment) => ({
      url: attachment.url,
      size: attachment.size,
    }));

    const result = await createAssignment.call({
      variables: {
        assignment: {
          courseId,
          name: data.assignmentName,
          description:
            // Quill inserts this string when a user adds text and then deletes it.
            // The text box will look empty but will contain just this string.
            data.description !== '<p><br></p>' ? data.description : null,
          teachersNote:
            data.teachersNote !== '<p><br></p>' ? data.teachersNote : null,
          graded: data.graded,
          customAssignmentTypeId: data.assignmentTypeOption.value,
          day: data.day?.value,
          week: data.week?.value,
          duration,
          studentAssignments: { data: studentAssignments },
          attachments: { data: attachments },
          visible: isParrotUser ? false : true,
          parrotRequestId: isParrotUser ? parrotRequestId : null,
        },
      },
    });

    const createdAssignment = result?.data?.assignment;

    createdAssignment && clearTempAttachments();
    createdAssignment &&
      dispatch(selectAssignmentAndScrollToTop(createdAssignment?.id));
    createdAssignment && dispatch(updateCurrentWeekAndDay(createdAssignment));

    const createdAssignmentElId = `assignment-${createdAssignment?.id}-${activeTabIndex}`;
    const createdAssignmentEl = document.getElementById(createdAssignmentElId);
    createdAssignmentEl?.scrollIntoView({ behavior: 'smooth' });

    dispatch(cancelCreatingAssignment());
  };

  const buttonConfigs = {
    secondary: {
      title: `Cancel`,
      onClick: () => dispatch(cancelCreatingAssignment()),
      color: ButtonColor.Normal,
      type: ButtonType.Secondary,
    },
    primary: {
      title: `Create Assignment`,
      isSubmit: true,
      color: ButtonColor.Normal,
      type: ButtonType.Primary,
    },
  };

  return (
    <div className={styles.NewAssignmentInfo}>
      <AssignmentInfoForm
        onSubmit={handleSubmit(handleCreateAssignment)}
        formMethods={formMethods}
        isLoading={anyLoading}
        buttonConfigs={buttonConfigs}
        allowAttachments={true}
        attachments={tempAttachments}
        onDeleteAttachment={removeTempAttachment}
        maxNumberOfFiles={remainingAttachments}
        onSuccessfulAttachment={addTempAttachment}
        contentClassName={styles.ScrollableContent}
      />
    </div>
  );
}
