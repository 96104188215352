import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useAppDispatch } from 'redux/store';
import {
  getUserEmail,
  getUserId,
  getUserIsIdentified,
  getUserPlanTier,
  getUserRole,
  getUserSubscriptionStatus,
  setUserIsIdentified,
} from 'redux/user';

const isPostHogEnv = import.meta.env.REACT_APP_DEPLOY_ENV !== 'dev';

export default function usePostHog() {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const id = useSelector(getUserId);
  const email = useSelector(getUserEmail);
  const role = useSelector(getUserRole);
  const planTier = useSelector(getUserPlanTier);
  const subscriptionStatus = useSelector(getUserSubscriptionStatus);
  const isIdentified = useSelector(getUserIsIdentified);

  useEffect(() => {
    if (id && email && role && isPostHogEnv) {
      posthog.init(import.meta.env.REACT_APP_POST_HOG_KEY, {
        api_host: 'https://app.posthog.com',
        autocapture: true,
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: { password: true },
        },
        loaded: (posthog) => {
          posthog.identify(id, { email, role });
          dispatch(setUserIsIdentified(true));
        },
      });
    }
  }, [id, email, role, dispatch]);

  useEffect(() => {
    if (planTier && subscriptionStatus && isIdentified && isPostHogEnv) {
      posthog.people.set({ planTier, subscriptionStatus });
    }
  }, [planTier, subscriptionStatus, isIdentified]);

  useEffect(() => {
    isPostHogEnv && posthog.capture('$pageview');
  }, [location]);
}
