import { gql } from '@apollo/client';

import {
  CalendarStudentAssignments,
  GradebookStudentAssignment,
  StudentAssignmentToReschedule,
  StudentAssignmentToUpdateGradingStyle,
} from 'graphql/fragments/studentAssignment';

import { AssignmentTypeWeightsInfo } from './assignmentTypeWeights';
import { CommentInfo } from './comments';
import { CustomAssignmentType } from './customAssignmentType';
import { StudentsEvents } from './student';

export const CompletedStudentCourseCardInfo = gql`
  fragment CompletedStudentCourseCardInfo on student_courses {
    id
    status
    start
    end
    calendarYear
    schoolYear
    courseSnapshot
    student {
      id
      name
    }
  }
`;

export const CourseSnapshot = gql`
  fragment CourseSnapshot on student_courses {
    course {
      id
      name
      numberOfWeeks
      numberOfDaysPerWeek
      color
      description
      teacherId
      credits
      gradingStyle
      defaultDaysOfTheWeek
      subjects
      gradeYears
      introduction
      useAssignmentTypeWeights
      ...AssignmentTypeWeightsInfo
    }
    studentAssignments {
      id
      start
      end
      status
      pointsEarned
      pointsAvailable
      completed
      submissions {
        id
        url
      }
      assignment {
        id
        name
        week
        day
        duration
        graded
        customAssignmentType {
          ...CustomAssignmentType
        }
        description
        teachersNote
        created
        attachments {
          id
          url
        }
      }
      comments {
        ...CommentInfo
      }
    }
  }
  ${AssignmentTypeWeightsInfo}
  ${CustomAssignmentType}
  ${CommentInfo}
`;

export const ActiveStudentCoursesInfo = gql`
  fragment ActiveStudentCoursesInfo on student_courses {
    id
    start
    daysOfTheWeek
    courseDayOffsets
    course {
      numberOfDaysPerWeek
      numberOfWeeks
    }
    student {
      ...StudentsEvents
    }
  }
  ${StudentsEvents}
`;

export const ActiveStudentCourseCardInfo = gql`
  fragment ActiveStudentCourseCardInfo on student_courses {
    id
    start
    end
    daysOfTheWeek
    status
    dailySortOrder
    courseDayOffsets
    student {
      id
      name
      schoolYear
    }
    course {
      id
      name
      color
      gradingStyle
      numberOfDaysPerWeek
      numberOfWeeks
      useAssignmentTypeWeights
      ...AssignmentTypeWeightsInfo
    }
    studentAssignments {
      ...GradebookStudentAssignment
      ...StudentAssignmentToReschedule
    }
  }
  ${AssignmentTypeWeightsInfo}
  ${GradebookStudentAssignment}
  ${StudentAssignmentToReschedule}
`;

export const StudentCourseInfo = gql`
  fragment StudentCourseInfo on student_courses {
    id
    status
    start
    end
    daysOfTheWeek
    calendarYear
    schoolYear
    courseDayOffsets
    courseSnapshot
    student {
      id
      name
      schoolYear
      picture
    }
    course {
      id
      name
      color
      gradingStyle
      numberOfDaysPerWeek
      numberOfWeeks
      useAssignmentTypeWeights
      ...AssignmentTypeWeightsInfo
    }
    studentAssignments {
      ...GradebookStudentAssignment
      ...StudentAssignmentToReschedule
    }
  }
  ${AssignmentTypeWeightsInfo}
  ${GradebookStudentAssignment}
  ${StudentAssignmentToReschedule}
`;

export const CourseStudentCardInfo = gql`
  fragment CourseStudentCardInfo on student_courses {
    id
    student {
      id
      name
      picture
    }
  }
`;

export const PlanningStudentCourse = gql`
  fragment PlanningStudentCourse on student_courses {
    id
    start
    daysOfTheWeek
    courseDayOffsets
    student {
      id
      ...StudentsEvents
    }
    studentAssignments {
      ...StudentAssignmentToReschedule
    }
  }
  ${StudentAssignmentToReschedule}
  ${StudentsEvents}
`;

export const CalendarStudentCourse = gql`
  fragment CalendarStudentCourse on student_courses {
    id
    start
    daysOfTheWeek
    dailySortOrder
    courseDayOffsets
    student {
      id
      name
      picture
    }
    studentAssignments(
      where: { _or: [{ start: { _eq: $day } }, { end: { _eq: $day } }] }
      order_by: { assignment: { created: asc } }
    ) {
      ...CalendarStudentAssignments
    }
  }
  ${CalendarStudentAssignments}
`;

export const StudentCourseToReschedule = gql`
  fragment StudentCourseToReschedule on student_courses {
    id
    daysOfTheWeek
    studentId
    start
    courseDayOffsets
    studentAssignments(
      where: {
        _and: [
          { start: { _gte: $date } }
          { freeForm: { _eq: false } }
          { status: { _neq: COMPLETE } }
        ]
      }
    ) {
      ...StudentAssignmentToReschedule
    }
  }
  ${StudentAssignmentToReschedule}
`;

export const StudentCourseToReoffset = gql`
  fragment StudentCourseToReoffset on student_courses {
    id
    daysOfTheWeek
    studentId
    start
    courseDayOffsets
    studentAssignments(
      where: {
        _and: [
          { start: { _lt: $date } }
          {
            assignment: {
              _or: [
                { week: { _gt: $week } }
                { _and: [{ week: { _eq: $week } }, { day: { _gte: $day } }] }
              ]
            }
          }
          { id: { _neq: $studentAssignmentId } }
        ]
      }
    ) {
      ...StudentAssignmentToReschedule
    }
  }
  ${StudentAssignmentToReschedule}
`;

export const StudentCoursesToUpdateGradingStyle = gql`
  fragment StudentCoursesToUpdateGradingStyle on student_courses {
    id
    studentAssignments {
      ...StudentAssignmentToUpdateGradingStyle
    }
  }
  ${StudentAssignmentToUpdateGradingStyle}
`;

export const RequiredStudentCourseInfo = gql`
  fragment RequiredStudentCourseInfo on student_courses {
    id
    studentId
    status
    start
    end
    courseId
    daysOfTheWeek
    courseDayOffsets
  }
`;

export const ActiveStudentTranscriptCourse = gql`
  fragment ActiveStudentTranscriptCourse on student_courses {
    id
    studentAssignments {
      ...GradebookStudentAssignment
      ...StudentAssignmentToReschedule
    }
    course {
      id
      name
      color
      credits
      gradingStyle
      useAssignmentTypeWeights
      ...AssignmentTypeWeightsInfo
    }
  }
  ${AssignmentTypeWeightsInfo}
  ${GradebookStudentAssignment}
  ${StudentAssignmentToReschedule}
`;

export const CompletedStudentTranscriptCourse = gql`
  fragment CompletedStudentTranscriptCourse on student_courses {
    id
    calendarYear
    courseSnapshot
  }
`;
