import classnames from 'classnames';
import { MouseEvent, useRef } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'react-feather';

import styles from './ModalPortal.module.scss';

interface ModalPortalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  preventModalClose?: boolean;
  children: JSX.Element[] | JSX.Element;
}

export default function ModalPortal({
  className,
  isOpen,
  onClose,
  preventModalClose = false,
  children,
}: ModalPortalProps): JSX.Element | null {
  const portalRootElement: HTMLElement | null =
    document.getElementById('portal-root');
  const modalChildrenRef = useRef<HTMLDivElement>(null);

  const handleClose = (event: MouseEvent) => {
    if (
      !preventModalClose &&
      modalChildrenRef.current &&
      !modalChildrenRef.current.contains(event.target as Node)
    ) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div
      className={classnames(className, styles.Backdrop)}
      onClick={handleClose}
    >
      <div className={styles.Content} onClick={handleClose}>
        {!preventModalClose ? (
          <X className={styles.CloseIcon} />
        ) : (
          <div className={styles.CloseIcon} />
        )}
        <div className={styles.Modal} ref={modalChildrenRef}>
          {children}
        </div>
      </div>
    </div>,
    portalRootElement as HTMLElement
  );
}
