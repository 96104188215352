import { gql } from '@apollo/client';

export const GET_STORAGE_USAGE = gql`
  query GetStorageUsage {
    files: files_aggregate(where: { parrotRequestId: { _is_null: true } }) {
      aggregate {
        sum {
          size
        }
      }
    }
  }
`;
