import { gql } from '@apollo/client';

import { NewStudentAssignment } from './studentAssignment';

export const PlanningAssignment = gql`
  fragment PlanningAssignment on assignments {
    id
    courseId
    name
    description
    teachersNote
    duration
    graded
    created
    day
    week
    parrotRequestId
    studentAssignments(order_by: { student: { name: asc } }) {
      ...NewStudentAssignment
    }
    attachments {
      id
      url
      size
    }
    customAssignmentType {
      id
      name
      color
    }
  }
  ${NewStudentAssignment}
`;

export const CoursePlanCardAssignment = gql`
  fragment CoursePlanCardAssignment on assignments {
    id
    day
    week
  }
`;

export const CourseAssignmentSchedule = gql`
  fragment CourseAssignmentSchedule on assignments {
    id
    day
    week
    duration
  }
`;

export const AssignmentInfo = gql`
  fragment AssignmentInfo on assignments {
    courseId
    ...PlanningAssignment
    ...CoursePlanCardAssignment
  }
  ${PlanningAssignment}
  ${CoursePlanCardAssignment}
`;
