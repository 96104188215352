import classnames from 'classnames';
import { CSSProperties, MouseEventHandler } from 'react';

import { useTheme } from 'context/Theme';
import { HEX_CODE_OPACITY } from 'context/Theme/constants';

import styles from './Chip.module.scss';

export enum ChipType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum ChipSpacing {
  Normal = 'normal',
  Compressed = 'compressed',
}

interface ChipProps {
  label: string;
  className?: string;
  onClick?: MouseEventHandler;
  color?: string;
  type?: ChipType;
  spacing?: ChipSpacing;
}

export default function Chip({
  label,
  className,
  onClick,
  color,
  type = ChipType.Primary,
  spacing = ChipSpacing.Normal,
}: ChipProps) {
  const { theme } = useTheme();

  const classes = classnames(className, styles.Chip);

  const colorHex: string = color || theme.primary;

  const backgroundColor: string =
    type === ChipType.Primary ? colorHex : `${colorHex}${HEX_CODE_OPACITY[25]}`;
  const foregroundColor: string =
    type === ChipType.Primary ? theme.contentBackground : colorHex;

  const fontSize: string = spacing === ChipSpacing.Normal ? '1.25rem' : '1rem';
  const padding: string =
    spacing === ChipSpacing.Normal ? '0.5rem 1.25rem' : '0.4rem 1.25em';

  const chipStyles: CSSProperties = {
    padding,
    backgroundColor,
    fontSize,
    color: foregroundColor,
    cursor: onClick ? 'pointer' : 'auto',
  };

  let attributes: any = {
    className: classes,
    style: chipStyles,
  };

  if (onClick) {
    attributes.onClick = onClick;
  }

  return (
    <div {...attributes}>
      <div className={styles.ContentWrapper}>{label}</div>
    </div>
  );
}
