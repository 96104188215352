import { Fragment } from 'react';
import { useForm } from 'react-hook-form';

import Button, { ButtonColor, ButtonType } from 'components/Button';
import InputField, { InputFieldType } from 'components/InputField';
import ModalPortal from 'components/ModalPortal';

import styles from './ConfirmationModal.module.scss';

interface ConfirmationModalData {
  confirmText: string;
}

interface ConfirmationModalProps {
  isOpen: boolean;
  title: string;
  body: string;
  onCancel: () => void;
  onConfirm: () => void;
  confirmButtonText: string;
  confirmFieldText?: string;
  onClose?: () => void;
  confirmColor?: ButtonColor;
  confirmIsLoading?: boolean;
}

export default function ConfirmationModal({
  isOpen,
  title,
  body,
  onCancel,
  onConfirm,
  confirmButtonText,
  confirmFieldText,
  onClose,
  confirmColor = ButtonColor.Normal,
  confirmIsLoading = false,
}: ConfirmationModalProps): JSX.Element | null {
  const trimmedConfirmFieldText = confirmFieldText?.trim();

  const { register, handleSubmit, watch, reset } =
    useForm<ConfirmationModalData>({});

  const confirmText = watch('confirmText');

  if (!isOpen) {
    return null;
  }

  return (
    <ModalPortal
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose ? onClose() : onCancel();
      }}
    >
      <div className={styles.Modal}>
        <h1>{title}</h1>
        <p>{body}</p>

        <form onSubmit={handleSubmit(onConfirm)}>
          {trimmedConfirmFieldText ? (
            <Fragment>
              <div className={styles.Instructions}>
                Type{' '}
                <span className={styles.ConfirmText}>
                  {trimmedConfirmFieldText}
                </span>{' '}
                to confirm.
              </div>
              <InputField
                className={styles.Input}
                id="confirmTextfield"
                name="confirmText"
                label="Confirm Below"
                register={register('confirmText', {
                  validate: (value) => value === trimmedConfirmFieldText,
                })}
                hideLabel
                type={InputFieldType.Text}
              />
            </Fragment>
          ) : null}

          <div className={styles.Buttons}>
            <Button
              className={styles.CancelButton}
              onClick={() => {
                reset();
                onCancel();
              }}
              type={ButtonType.Secondary}
              disabled={confirmIsLoading}
            >
              <span>Cancel</span>
            </Button>
            <Button
              className={styles.ConfirmButton}
              submit
              color={confirmColor}
              loading={confirmIsLoading}
              disabled={
                confirmIsLoading ||
                (!!trimmedConfirmFieldText &&
                  confirmText !== trimmedConfirmFieldText)
              }
            >
              <span>{confirmButtonText}</span>
            </Button>
          </div>
        </form>
      </div>
    </ModalPortal>
  );
}
