import classnames from 'classnames';
import {
  FormEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { Controller } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { useSelector } from 'react-redux';
import { Options } from 'react-select';

import Button, { ButtonColor, ButtonType } from 'components/Button';
import Checkbox from 'components/Checkbox';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import ErrorMessage from 'components/ErrorMessage';
import FileList from 'components/FileList';
import FileUploader from 'components/FileUploader';
import { UploadedFile } from 'components/FileUploader/FileUploader';
import InputField, { InputFieldType } from 'components/InputField';
import Note from 'components/Note';
import RichTextArea, { maxLengthRichTextArea } from 'components/RichTextArea';
import Tab from 'components/Tab';
import Tabset from 'components/Tabset';

import { Files } from 'graphql/types/generated';

import {
  getAssignmentTypeWeights,
  getNumberOfDaysPerWeek,
  getNumberOfWeeks,
} from 'redux/planning';

import { generateNDropdownOptions } from 'utils/generateNDropdownOptions';

import styles from './AssignmentInfoForm.module.scss';

export const assignmentInfoFieldsId = 'assignment-info-fields';
export const assignmentNameId = 'assignment-name';

export const maxNumberOfAttachments = 10;

export const defaultAssignmentInfoFormValues = {
  assignmentName: '',
  description: '',
  teachersNote: '',
  graded: false,
  assignmentTypeOption: { label: '', value: '' },
  duration: undefined,
  day: null,
  week: null,
};

export interface AssignmentInfoFormData {
  assignmentName: string;
  description: string;
  teachersNote: string;
  graded: boolean;
  assignmentTypeOption: DropdownOption<string | null>;
  duration: number;
  day: DropdownOption<number> | null;
  week: DropdownOption<number> | null;
}

export interface AssignmentInfoFormButtonConfig {
  title: string;
  onClick?: MouseEventHandler;
  isSubmit?: boolean;
  color: ButtonColor;
  type: ButtonType;
  disabled?: boolean;
}

export interface AssignmentInfoFormButtonConfigs {
  secondary: AssignmentInfoFormButtonConfig;
  primary: AssignmentInfoFormButtonConfig;
}

export type Attachment = Pick<Files, 'id' | 'url'>;

interface AssignmentInfoFormProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
  formMethods: UseFormReturn<AssignmentInfoFormData>;
  enabledFields?: Record<string, boolean> | null;
  buttonConfigs: AssignmentInfoFormButtonConfigs;
  isLoading?: boolean;
  allowAttachments?: boolean;
  attachments?: Attachment[];
  maxNumberOfFiles?: number;
  onDeleteAttachment?: (attachmentId: string) => void;
  onSuccessfulAttachment?: (files: UploadedFile[]) => void;
  contentClassName?: string;
  formNote?: string | JSX.Element | JSX.Element[];
}

export default function AssignmentInfoForm({
  onSubmit,
  formMethods,
  enabledFields = null,
  buttonConfigs,
  isLoading,
  allowAttachments = false,
  attachments,
  maxNumberOfFiles,
  onDeleteAttachment,
  onSuccessfulAttachment,
  contentClassName,
  formNote,
}: AssignmentInfoFormProps): JSX.Element {
  const {
    register,
    control,
    getValues,
    formState: { errors },
    clearErrors,
  } = formMethods;

  const [assignmentTypeOptions, setAssignmentTypeOptions] = useState<
    Options<DropdownOption<string | null>>
  >([]);
  const [dayOptions, setDayOptions] = useState<Options<DropdownOption<number>>>(
    []
  );
  const [weekOptions, setWeekOptions] = useState<
    Options<DropdownOption<number>>
  >([]);

  const [scheduleError, setScheduleError] = useState<FieldError | undefined>(
    undefined
  );

  const numberOfDaysPerWeek = useSelector(getNumberOfDaysPerWeek);
  const numberOfWeeks = useSelector(getNumberOfWeeks);
  const assignmentTypeWeights = useSelector(getAssignmentTypeWeights);

  useEffect(() => {
    if (assignmentTypeWeights.length) {
      const assignmentTypeOptions = assignmentTypeWeights.map(
        (assignmentTypeWeight) => {
          return {
            label: assignmentTypeWeight.customAssignmentType?.name ?? '',
            value: assignmentTypeWeight.customAssignmentType?.id ?? null,
          };
        }
      );

      setAssignmentTypeOptions(assignmentTypeOptions);
    }
  }, [assignmentTypeWeights]);

  useEffect(() => {
    numberOfDaysPerWeek &&
      setDayOptions(generateNDropdownOptions('Day', numberOfDaysPerWeek));
    numberOfWeeks &&
      setWeekOptions(generateNDropdownOptions('Week', numberOfWeeks));
  }, [numberOfDaysPerWeek, numberOfWeeks]);

  useEffect(() => {
    clearErrors();
    setScheduleError(undefined);
  }, [clearErrors, enabledFields]);

  const weekAndDayAreValid = () => {
    const isDayUnscheduled = !isFieldDisabled('day') && !getValues('day');
    const isWeekUnscheduled = !isFieldDisabled('week') && !getValues('week');
    const isOnlyOneUnscheduled = isDayUnscheduled !== isWeekUnscheduled;

    if (isOnlyOneUnscheduled) {
      setScheduleError({
        type: 'weekAndDayAreValid',
        message: 'Day and Week must either be both selected or neither',
      });
      return false;
    } else {
      setScheduleError(undefined);
      return true;
    }
  };

  const isFieldDisabled = (fieldName: string) => {
    return (
      (isLoading !== null && isLoading) ||
      (enabledFields !== null && !enabledFields[fieldName])
    );
  };

  const renderAttachmentsTab = () => {
    if (
      maxNumberOfFiles === undefined ||
      onSuccessfulAttachment === undefined
    ) {
      return null;
    }

    return (
      <Tab title={`Attachments (${attachments?.length ?? 0})`}>
        <div className={styles.AttachmentsTab}>
          <FileList
            className={styles.AttachmentsList}
            label="Attachments"
            files={attachments}
            maxFileNameLength={45}
            onDeleteFile={onDeleteAttachment}
            hideLabel
          />
          {!isLoading && (
            <FileUploader
              name="assignmentAttachmentsUploader"
              height="8rem"
              width="100%"
              note="Upload PDFs, text documents, presentations, spreadsheets, or images"
              maxNumberOfFiles={maxNumberOfFiles}
              onSuccess={onSuccessfulAttachment}
              checkStorageQuota
            />
          )}
        </div>
      </Tab>
    );
  };

  const renderTeachersNote = (hideLabel: boolean) => {
    return (
      <Controller
        name="teachersNote"
        render={({ field: { value, onChange } }) => (
          <RichTextArea
            className={styles.TeachersNote}
            label="Teacher's Notes"
            id="teachers-note"
            errors={errors.teachersNote}
            disabled={isLoading || isFieldDisabled('teachersNote')}
            parentId={assignmentInfoTabContainerId}
            value={value}
            onChange={onChange}
            hideLabel={hideLabel}
          />
        )}
        control={control}
        rules={{
          validate: {
            maxLength: (value) =>
              maxLengthRichTextArea(
                value,
                5_000,
                "Teacher's notes can be no more than 5,000 characters"
              ),
          },
        }}
      />
    );
  };

  const assignmentInfoTabContainerId = 'assignment-info-tab-container';

  const fieldsClasses = classnames(
    styles.AssignmentInfoFields,
    contentClassName,
    {
      [styles.WithAttachments]: allowAttachments,
      [styles.WithoutAttachments]: !allowAttachments,
      [styles.WithFormNote]: formNote,
      [styles.WithoutFormNote]: !formNote,
    }
  );

  return (
    <form onSubmit={onSubmit} className={styles.AssignmentInfoForm}>
      <div className={fieldsClasses}>
        {formNote ? (
          <Note className={styles.FormNote}>
            <span>{formNote}</span>
          </Note>
        ) : null}
        <div className={styles.Schedule}>
          {/* The week day pair need to be validated together because they need to either 
          both be selected or neither. To show the correct error state I use a separate state 
          variable to manage the error message instead of each field's react-hook-form error. */}
          <div className={styles.ScheduleOptions}>
            <Controller
              name="week"
              render={({ field: { value, name, onChange } }) => (
                <Dropdown
                  className={styles.Week}
                  id="week"
                  label="Select Week"
                  placeholder={!isFieldDisabled('week') ? 'Unscheduled' : ''}
                  options={weekOptions}
                  isClearable
                  errors={scheduleError}
                  hideErrorMessage
                  disabled={isFieldDisabled('week')}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              rules={{
                validate: {
                  weekAndDayAreValid,
                },
              }}
            />
            <Controller
              name="day"
              render={({ field: { value, name, onChange } }) => (
                <Dropdown
                  className={styles.Day}
                  id="day"
                  label="Select Day"
                  placeholder={!isFieldDisabled('day') ? 'Unscheduled' : ''}
                  options={dayOptions}
                  isClearable
                  errors={scheduleError}
                  hideErrorMessage
                  disabled={isFieldDisabled('day')}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              rules={{
                validate: {
                  weekAndDayAreValid,
                },
              }}
            />
          </div>
          <ErrorMessage message={scheduleError?.message} />
        </div>
        <Controller
          name="assignmentTypeOption"
          render={({ field: { value, name, onChange } }) => (
            <Dropdown
              className={styles.AssignmentType}
              id="assignment-type"
              label="Select Type"
              placeholder={
                !isFieldDisabled('assignmentTypeOption') ? 'Select Type' : ''
              }
              options={assignmentTypeOptions}
              disabled={isLoading || isFieldDisabled('assignmentTypeOption')}
              name={name}
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
        />
        <Controller
          name="graded"
          render={({ field: { value, name, onChange } }) => (
            <Checkbox
              className={styles.Graded}
              id="graded"
              disabled={isLoading || isFieldDisabled('graded')}
              isSelected={value}
              name={name}
              onChange={onChange}
            >
              <span>Graded</span>
            </Checkbox>
          )}
          control={control}
        />
        <InputField
          className={styles.Name}
          id={assignmentNameId}
          name="assignmentName"
          label="Assignment Name"
          placeholder={
            !isFieldDisabled('assignmentName') ? 'New Assignment' : ''
          }
          register={
            !isFieldDisabled('assignmentName')
              ? register('assignmentName', {
                  required: 'Assignment name is required',
                  maxLength: {
                    value: 140,
                    message:
                      'Assignment name can not be more than 140 characters',
                  },
                  validate: (value) =>
                    !!value.trim() || 'Assignment name is required',
                })
              : undefined
          }
          errors={errors.assignmentName}
          type={InputFieldType.Text}
          showRequiredIndicator={!isFieldDisabled('assignmentName')}
          disabled={isLoading || isFieldDisabled('assignmentName')}
        />
        <InputField
          className={styles.Duration}
          id="duration"
          name="duration"
          label="Due In"
          postfix="day(s)"
          helpMessage={
            !isFieldDisabled('duration')
              ? 'Leave empty if due on the same day'
              : ''
          }
          register={
            !isFieldDisabled('duration')
              ? register('duration', {
                  valueAsNumber: true,
                  min: {
                    value: 0,
                    message: 'Duration must be between 0 and 365',
                  },
                  max: {
                    value: 365,
                    message: 'Duration can be no more than 365 days',
                  },
                })
              : undefined
          }
          type={InputFieldType.Number}
          errors={errors.duration}
          disabled={isLoading || isFieldDisabled('duration')}
        />
        <Controller
          name="description"
          render={({ field: { value, onChange } }) => (
            <RichTextArea
              className={styles.Description}
              id="description"
              label="Description"
              errors={errors.description}
              disabled={isLoading || isFieldDisabled('description')}
              parentId={assignmentInfoFieldsId}
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
          rules={
            !isFieldDisabled('description')
              ? {
                  validate: {
                    maxLength: (value) =>
                      maxLengthRichTextArea(
                        value,
                        5_000,
                        'Description can be no more than 5,000 characters'
                      ),
                  },
                }
              : undefined
          }
        />
        <div className={styles.Tabs} id={assignmentInfoTabContainerId}>
          {allowAttachments ? (
            <Tabset>
              {renderAttachmentsTab()}
              <Tab title="Teacher's Notes">{renderTeachersNote(true)}</Tab>
            </Tabset>
          ) : (
            renderTeachersNote(false)
          )}
        </div>
      </div>
      <div className={styles.Buttons}>
        <Button
          className={styles.SecondaryButton}
          submit={buttonConfigs.secondary.isSubmit}
          color={buttonConfigs.secondary.color}
          type={buttonConfigs.secondary.type}
          onClick={buttonConfigs.secondary.onClick}
          disabled={isLoading || buttonConfigs.secondary.disabled}
        >
          <span>{buttonConfigs.secondary.title}</span>
        </Button>
        <Button
          className={styles.PrimaryButton}
          submit={buttonConfigs.primary.isSubmit}
          color={buttonConfigs.primary.color}
          type={buttonConfigs.primary.type}
          onClick={buttonConfigs.primary.onClick}
          loading={isLoading}
          disabled={isLoading || buttonConfigs.primary.disabled}
        >
          <span>{buttonConfigs.primary.title}</span>
        </Button>
      </div>
    </form>
  );
}
