import { useVirtualizer } from '@tanstack/react-virtual';
import classnames from 'classnames';
import { decode } from 'he';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Plus } from 'react-feather';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Button from 'components/Button';
import Card from 'components/Card';
import Checkbox from 'components/Checkbox';
import Chip, { ChipSpacing, ChipType } from 'components/Chip';

import { useTheme } from 'context/Theme';

import { PlanningAssignmentFragment } from 'graphql/types/generated';

import {
  bulkSelectAssignment,
  cancelBulkUpdatingAssignments,
  cancelCreatingAssignment,
  cancelCreatingRepeatingAssignments,
  createAssignmentAndScrollToTop,
  getActiveTabIndex,
  getBulkSelectedAssignmentsMap,
  getCurrentDay,
  getCurrentWeek,
  getFilteredAssignments,
  getIsBulkUpdatingAssignments,
  getIsCreatingNewAssignment,
  getSearchPattern,
  getSelectedAssignment,
  getShouldScrollAssignmentList,
  getShouldShowAssignmentDetails,
  selectAssignment,
  setShouldScrollAssignmentList,
} from 'redux/planning';
import { useAppDispatch } from 'redux/store';
import { PlanningTabIndex } from 'redux/types';

import styles from './AssignmentList.module.scss';

interface AssignmentListProps {
  className?: string;
}

export default function AssignmentList({
  className,
}: AssignmentListProps): JSX.Element {
  const { theme } = useTheme();

  const listRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const assignments = useSelector(getFilteredAssignments);
  const selectedAssignment = useSelector(getSelectedAssignment);
  const shouldScrollAssignmentList = useSelector(getShouldScrollAssignmentList);
  const isCreatingNewAssignment = useSelector(getIsCreatingNewAssignment);
  const activeTabIndex = useSelector(getActiveTabIndex);
  const currentDay = useSelector(getCurrentDay);
  const currentWeek = useSelector(getCurrentWeek);
  const searchPattern = useSelector(getSearchPattern);
  const isShowingAssignmentDetails = useSelector(
    getShouldShowAssignmentDetails
  );
  const bulkSelectedAssignmentsMap = useSelector(getBulkSelectedAssignmentsMap);
  const isBulkUpdatingAssignments = useSelector(getIsBulkUpdatingAssignments);

  const shouldDisplayWeekDay = useMediaQuery({
    query: '(min-width: 750px)',
  });

  useEffect(() => {
    if (isCreatingNewAssignment) {
      const newAssignmentEl = document.getElementById('new-assignment');
      newAssignmentEl?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isCreatingNewAssignment]);

  const isAssignmentSelected = useCallback(
    (assignment: PlanningAssignmentFragment) =>
      assignment?.id === selectedAssignment?.id,
    [selectedAssignment]
  );

  const getAssignmentCardClasses = useCallback(
    (assignment: PlanningAssignmentFragment) =>
      classnames(styles.AssignmentCard, {
        [styles.SelectedAssignmentCard]: isAssignmentSelected(assignment),
      }),
    [isAssignmentSelected]
  );

  const handleSelectAssignment = useCallback(
    (id: string) => () => {
      dispatch(cancelCreatingAssignment());
      dispatch(cancelBulkUpdatingAssignments());
      dispatch(cancelCreatingRepeatingAssignments());

      dispatch(selectAssignment(id));
    },
    [dispatch]
  );

  const handleAddAssignment = () => {
    dispatch(cancelBulkUpdatingAssignments());
    dispatch(cancelCreatingRepeatingAssignments());
    dispatch(selectAssignment(null));

    dispatch(createAssignmentAndScrollToTop());
  };

  const renderEmptyStateMessage = () => {
    switch (activeTabIndex) {
      case PlanningTabIndex.DayWeek:
        return `No assignments planned on Week ${currentWeek}, Day ${currentDay}`;
      case PlanningTabIndex.Unscheduled:
        return 'No unscheduled assignments';
      case PlanningTabIndex.All:
        return searchPattern
          ? 'No assignments matching search'
          : 'No assignments planned';
    }
  };

  const classes = classnames(className, styles.AssignmentList);
  const assignmentsClasses = classnames(styles.Assignments, {
    [styles.WithoutButton]: isCreatingNewAssignment,
    [styles.WithButton]: !isCreatingNewAssignment,
  });

  const assignmentCardContentClasses = classnames(
    styles.AssignmentCardContent,
    {
      [styles.WithCheckbox]:
        !isShowingAssignmentDetails || isBulkUpdatingAssignments,
      [styles.WithoutCheckbox]:
        isShowingAssignmentDetails && !isBulkUpdatingAssignments,
    }
  );

  const handleBulkSelectAssignment = useCallback(
    (assignmentId: string) => () => {
      dispatch(bulkSelectAssignment(assignmentId));
    },
    [dispatch]
  );

  const assignmentListItems = useMemo(() => {
    const assignmentCards =
      assignments?.map((assignment: PlanningAssignmentFragment) => (
        <Card
          id={`assignment-${assignment.id}-${activeTabIndex}`}
          key={`assignment-${assignment.id}-${activeTabIndex}`}
          className={getAssignmentCardClasses(assignment)}
          onClick={handleSelectAssignment(assignment.id)}
        >
          <div className={styles.SelectedAssignmentCardIndicator} />
          <div className={assignmentCardContentClasses}>
            {(!isShowingAssignmentDetails || isBulkUpdatingAssignments) && (
              <Checkbox
                className={styles.AssignmentCheckbox}
                id={`assignment-checkbox-${assignment.id}-${activeTabIndex}`}
                name={`assignment-checkbox-${assignment.id}-${activeTabIndex}`}
                onClick={(e) => e.stopPropagation()}
                onChange={handleBulkSelectAssignment(assignment.id)}
                isSelected={!!bulkSelectedAssignmentsMap[assignment.id]}
              />
            )}
            <div className={styles.AssignmentNameDescription}>
              <div className={styles.AssignmentName}>{assignment.name}</div>
              {assignment.description && (
                <div className={styles.AssignmentDescription}>
                  {/* we replace with a space here instead of an empty string
                    to ensure the contents of the html element get properly spaced out.
                    html collapses multiple whitespaces in a row by default.*/}
                  {decode?.(
                    assignment.description.replace(/(<([^>]+)>)/gi, ' ')
                  )}
                </div>
              )}
            </div>

            {assignment.customAssignmentType && (
              <Chip
                className={styles.AssignmentTypeChip}
                label={assignment.customAssignmentType.name}
                color={
                  theme.colorPalette[assignment.customAssignmentType.color]
                }
                spacing={ChipSpacing.Compressed}
                type={ChipType.Secondary}
              />
            )}
            {!isShowingAssignmentDetails &&
              shouldDisplayWeekDay &&
              activeTabIndex === PlanningTabIndex.All && (
                <div className={styles.AssignmentWeekDay}>
                  {assignment.week && assignment.day
                    ? `Week ${assignment.week}, Day ${assignment.day}`
                    : 'Unscheduled'}
                </div>
              )}
          </div>
        </Card>
      )) ?? [];

    const newAssignmentCard = (
      <Card
        id="new-assignment"
        className={classnames(
          styles.AssignmentCard,
          styles.SelectedAssignmentCard
        )}
      >
        <div className={styles.SelectedAssignmentCardIndicator} />
        <div className={assignmentCardContentClasses}>
          <div className={styles.NewAssignmentCardName}>New Assignment</div>
        </div>
      </Card>
    );

    return isCreatingNewAssignment
      ? [...assignmentCards, newAssignmentCard]
      : assignmentCards;
  }, [
    activeTabIndex,
    assignmentCardContentClasses,
    assignments,
    bulkSelectedAssignmentsMap,
    getAssignmentCardClasses,
    handleBulkSelectAssignment,
    handleSelectAssignment,
    isBulkUpdatingAssignments,
    isCreatingNewAssignment,
    isShowingAssignmentDetails,
    shouldDisplayWeekDay,
    theme.colorPalette,
  ]);

  // const scrollToFn = useCallback(
  //   (offset: number, defaultScrollTo?: (offset: number) => void) => {
  //     if (defaultScrollTo && listRef.current) {
  //       const easeInOutQuint = (t: number) =>
  //         t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;

  //       const duration = 1000;
  //       const startPos = listRef.current.scrollTop;
  //       const startTime = Date.now();

  //       const run = () => {
  //         const elapsed = Date.now() - startTime;
  //         const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
  //         const distanceToScroll = startPos + (offset - startPos) * progress;

  //         defaultScrollTo(distanceToScroll);

  //         if (elapsed < duration) {
  //           requestAnimationFrame(run);
  //         }
  //       };

  //       requestAnimationFrame(run);
  //     }
  //   },
  //   []
  // );
  const scrollToFn = useCallback((offset: number) => {
    if (listRef.current) {
      const easeInOutQuint = (t: number) =>
        t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;

      const duration = 1000;
      const startPos = listRef.current.scrollTop;
      const startTime = Date.now();

      const run = () => {
        const elapsed = Date.now() - startTime;
        const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
        const distanceToScroll = startPos + (offset - startPos) * progress;

        if (listRef.current) {
          listRef.current.scrollTop = distanceToScroll;
        }

        if (elapsed < duration) {
          requestAnimationFrame(run);
        }
      };

      requestAnimationFrame(run);
    }
  }, []);

  const { getTotalSize, getVirtualItems, scrollToIndex } = useVirtualizer({
    count: assignmentListItems.length,
    getScrollElement: () => listRef.current,
    estimateSize: useCallback(() => 70, []),
    overscan: 2,
    scrollToFn,
  });

  useEffect(() => {
    if (!shouldScrollAssignmentList) {
      return;
    }

    if (selectedAssignment) {
      const assignmentIndex = assignments?.findIndex(
        (assignment) => assignment.id === selectedAssignment.id
      );

      if (assignmentIndex && assignmentIndex !== -1) {
        scrollToIndex(assignmentIndex, { align: 'start' });
      }
    } else if (isCreatingNewAssignment) {
      scrollToIndex(assignmentListItems.length - 1, { align: 'start' });
    }

    dispatch(setShouldScrollAssignmentList(false));
  }, [
    dispatch,
    scrollToIndex,
    assignments,
    selectedAssignment,
    assignmentListItems,
    isCreatingNewAssignment,
    shouldScrollAssignmentList,
  ]);

  return (
    <div className={classes}>
      <div className={assignmentsClasses} ref={listRef}>
        {!isCreatingNewAssignment && !assignments?.length ? (
          <div className={styles.EmptyState}>{renderEmptyStateMessage()}</div>
        ) : (
          <div
            style={{
              height: `${getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {getVirtualItems().map((virtualItem) => (
              <div
                key={virtualItem.key}
                className={styles.AssignmentCardWrapper}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `calc(${virtualItem.size}px - 1rem)`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
              >
                {assignmentListItems[virtualItem.index]}
              </div>
            ))}
          </div>
        )}
      </div>
      {!isCreatingNewAssignment ? (
        <div className={styles.AddAssignmentButtonWrapper}>
          <Button
            className={styles.AddAssignmentButton}
            onClick={handleAddAssignment}
          >
            <Plus size={17} />
            <div>
              New{' '}
              <span className={styles.HiddenOnSmallScreens}>Assignment</span>
            </div>
          </Button>
        </div>
      ) : null}
    </div>
  );
}
