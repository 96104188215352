import classnames from 'classnames';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useSelector } from 'react-redux';

import Button, { ButtonSpacing, ButtonType } from 'components/Button';

import {
  getCourseId,
  getCurrentDay,
  getCurrentWeek,
  getNumberOfDaysPerWeek,
  getNumberOfWeeks,
  setActiveTabIndex,
  setCurrentDay,
  setCurrentWeek,
} from 'redux/planning';
import { useAppDispatch } from 'redux/store';
import { PlanningTabIndex } from 'redux/types';

import { scrollCoursePlanDaysToDay } from 'utils/planning';

import styles from './WeekDaySelector.module.scss';

interface WeekDaySelectorProps {
  className?: string;
  onClickWeekDay?: () => void;
}

export default function WeekDaySelector({
  className,
  onClickWeekDay,
}: WeekDaySelectorProps): JSX.Element {
  const dispatch = useAppDispatch();

  const currentWeek = useSelector(getCurrentWeek);
  const currentDay = useSelector(getCurrentDay);
  const numberOfDaysPerWeek = useSelector(getNumberOfDaysPerWeek);
  const numberOfWeeks = useSelector(getNumberOfWeeks);
  const courseId = useSelector(getCourseId);

  const handleNavigateToPreviousDay = () => {
    const isPreviousWeek = currentDay === 1;
    const previousDay =
      isPreviousWeek && numberOfDaysPerWeek
        ? numberOfDaysPerWeek
        : currentDay - 1;
    const previousWeek =
      isPreviousWeek && numberOfDaysPerWeek ? currentWeek - 1 : currentWeek;

    dispatch(setCurrentDay(previousDay));
    dispatch(setCurrentWeek(previousWeek));
    dispatch(setActiveTabIndex(PlanningTabIndex.DayWeek));
    courseId && scrollCoursePlanDaysToDay(courseId, previousDay, previousWeek);
  };

  const handleNavigateToNextDay = () => {
    const isNextWeek = currentDay === numberOfDaysPerWeek;
    const nextDay = isNextWeek ? 1 : currentDay + 1;
    const nextWeek = isNextWeek ? currentWeek + 1 : currentWeek;

    dispatch(setCurrentDay(nextDay));
    dispatch(setCurrentWeek(nextWeek));
    dispatch(setActiveTabIndex(PlanningTabIndex.DayWeek));
    courseId && scrollCoursePlanDaysToDay(courseId, nextDay, nextWeek);
  };

  const weekDaySelectorClasses = classnames(className, styles.WeekDaySelector);
  const weekDayClasses = classnames(styles.WeekDay, {
    [styles.SelectableWeekDay]: !!onClickWeekDay,
  });

  return (
    <div className={weekDaySelectorClasses}>
      <Button
        className={styles.PreviousButton}
        type={ButtonType.Minimal}
        spacing={ButtonSpacing.Compressed}
        onClick={handleNavigateToPreviousDay}
        disabled={currentDay === 1 && currentWeek === 1}
      >
        <ChevronLeft size={17} />
      </Button>
      <div
        className={weekDayClasses}
        {...(onClickWeekDay ? { onClick: onClickWeekDay } : {})}
      >
        Week {currentWeek}, Day {currentDay}
      </div>
      <Button
        className={styles.NextButton}
        type={ButtonType.Minimal}
        spacing={ButtonSpacing.Compressed}
        onClick={handleNavigateToNextDay}
        disabled={
          currentDay === numberOfDaysPerWeek && currentWeek === numberOfWeeks
        }
      >
        <ChevronRight size={17} />
      </Button>
    </div>
  );
}
