import { gql } from '@apollo/client';

import { CustomAssignmentType } from './customAssignmentType';

export const AssignmentTypeWeightInfo = gql`
  fragment AssignmentTypeWeightInfo on assignment_type_weights {
    id
    weight
    assignmentType
    customAssignmentType {
      ...CustomAssignmentType
    }
    sortOrder
  }
  ${CustomAssignmentType}
`;

export const AssignmentTypeWeightsInfo = gql`
  fragment AssignmentTypeWeightsInfo on courses {
    id
    assignmentTypeWeights {
      ...AssignmentTypeWeightInfo
    }
  }
  ${AssignmentTypeWeightInfo}
`;
