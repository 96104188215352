import { useState } from 'react';

import { maxNumberOfAttachments } from 'components/AssignmentInfoForm/AssignmentInfoForm';
import { UploadedFile } from 'components/FileUploader/FileUploader';

import { Files } from 'graphql/types/generated';

export default function useTemporaryAttachments() {
  const [tempAttachments, setTempAttachments] = useState<
    Pick<Files, 'id' | 'url' | 'size'>[]
  >([]);

  const addTempAttachment = (files: UploadedFile[]) => {
    const newAttachments = files.map((file, index) => ({
      id: `${tempAttachments.length + index}`,
      url: file.url,
      size: file.size,
    }));

    setTempAttachments(tempAttachments.concat(newAttachments));
  };

  const removeTempAttachment = (attachmentId: string) =>
    setTempAttachments(
      tempAttachments.filter((attachment) => attachment.id !== attachmentId)
    );

  const clearTempAttachments = () => {
    setTempAttachments([]);
  };

  const remainingAttachments = maxNumberOfAttachments - tempAttachments.length;

  return {
    tempAttachments,
    remainingAttachments,
    addTempAttachment,
    removeTempAttachment,
    clearTempAttachments,
  };
}
