import { gql } from '@apollo/client';

import { PlanningAssignment } from 'graphql/fragments/assignment';
import { AssignmentTypeWeightsInfo } from 'graphql/fragments/assignmentTypeWeights';
import { PlanningStudentCourse } from 'graphql/fragments/studentCourse';

export const GET_PLANNING_COURSE = gql`
  query GetPlanningCourse($courseId: uuid!) {
    course(id: $courseId) {
      id
      name
      color
      numberOfWeeks
      numberOfDaysPerWeek
      assignments(order_by: { created: asc }) {
        ...PlanningAssignment
      }
      studentCourses {
        ...PlanningStudentCourse
      }
      ...AssignmentTypeWeightsInfo
    }
  }
  ${PlanningAssignment}
  ${PlanningStudentCourse}
  ${AssignmentTypeWeightsInfo}
`;

export const GET_PLANNING_COURSE_FOR_PARROT = gql`
  query GetPlanningCourseForParrot($courseId: uuid!, $parrotRequestId: uuid!) {
    course(id: $courseId) {
      id
      name
      color
      numberOfWeeks
      numberOfDaysPerWeek
      assignments(
        where: {
          _and: [
            # Hasura doesn't like checking UUIDs equality with null so we first
            # check that it's not null and then check the ID
            { parrotRequestId: { _is_null: false } }
            { parrotRequestId: { _eq: $parrotRequestId } }
          ]
        }
      ) {
        ...PlanningAssignment
      }
      studentCourses {
        ...PlanningStudentCourse
      }
      ...AssignmentTypeWeightsInfo
    }
  }
  ${PlanningAssignment}
  ${PlanningStudentCourse}
  ${AssignmentTypeWeightsInfo}
`;
