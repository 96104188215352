import { ColorOpacity, HEX_CODE_OPACITY } from './constants';
import { Theme } from './types';

function setVariants(name: string, color: string, opacities?: ColorOpacity[]) {
  document.documentElement.style.setProperty(`--${name}`, color);
  opacities?.forEach((opacity) => {
    document.documentElement.style.setProperty(
      `--${name}-${opacity}`,
      `${color}${HEX_CODE_OPACITY[opacity]}`
    );
  });
}

export default function setColors(theme: Theme) {
  // Syllabird Brand Colors
  const { syllabird, bluejay, oriole, flamingo, parakeet } = theme;

  setVariants('syllabird', syllabird);
  setVariants('bluejay', bluejay, [5, 25]);
  setVariants('oriole', oriole);
  setVariants('flamingo', flamingo, [5, 25]);
  setVariants('parakeet', parakeet);

  // Header/Topbar Colors
  const { headerBackground, headerForeground, headerForegroundActive } = theme;

  setVariants('header-background', headerBackground);
  setVariants('header-foreground', headerForeground, [15]);
  setVariants('header-foreground-active', headerForegroundActive, [15]);

  // Primary App Appearance Colors
  const {
    primary,
    appBackground,
    contentBackground,
    faintBorder,
    mediumBorder,
    elementBorder,
  } = theme;

  setVariants('primary', primary, [5, 10, 15, 25]);
  setVariants('app-background', appBackground);
  setVariants('content-background', contentBackground, [50]);
  setVariants('faint-border', faintBorder);
  setVariants('medium-border', mediumBorder);
  setVariants('element-border', elementBorder);

  // Font Colors
  const { contentContrast, contentPrimary, contentSecondary, contentTertiary } =
    theme;

  setVariants('content-contrast', contentContrast);
  setVariants('content-primary', contentPrimary, [5, 25]);
  setVariants('content-secondary', contentSecondary, [5]);
  setVariants('content-tertiary', contentTertiary, [5, 15, 50]);

  // Misc. Semantic Colors
  const { positive, negative, info, vacation } = theme;

  setVariants('positive', positive, [15]);
  setVariants('negative', negative, [15, 25]);
  setVariants('info', info);
  setVariants('vacation', vacation, [10]);
}
