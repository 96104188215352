import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ResizeObserver from 'resize-observer-polyfill';

import Auth0ProviderWithHistory from 'components/Auth0ProviderWithHistory/Auth0ProviderWithHistory';

import store from 'redux/store';

import App from './App';

// This is needed for the useResizeDetector hook from react-resize-detector
// because older browsers (specifically Safari for our users) don't have the
// ResizeObserver available.
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

// We need to calculate the vh by hand to handle mobile browsers
// not supporting vh correctly: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const calcViewportUnits = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

calcViewportUnits();
window.addEventListener('resize', calcViewportUnits);

const root = ReactDOM.createRoot(
  document.getElementById('root') ?? document.body
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </Provider>
);
