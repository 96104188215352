import classnames from 'classnames';
import React, {
  CSSProperties,
  isValidElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useResizeDetector } from 'react-resize-detector';

import Button, { ButtonSpacing, ButtonType } from 'components/Button';

import styles from './Tabset.module.scss';

interface TabsetProps {
  className?: string;
  style?: CSSProperties;
  children: (JSX.Element | null)[];
  activeTabIndex?: number | null;
  defaultActiveTabIndex?: number;
  onClickTab?: (index: number) => void;
}

const OVERFLOW_THRESHOLD = 7; // ~0.5rem
const DISABLED_SCROLL_THRESHOLD = 3.5; // ~0.25rem
const SCROLL_AMOUNT = 150;

export default function Tabset({
  className,
  style,
  children: tabs,
  activeTabIndex = null,
  defaultActiveTabIndex,
  onClickTab,
}: TabsetProps): JSX.Element {
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const { ref: tabListContainerRef, width: tabListContainerWidth } =
    useResizeDetector({
      refreshMode: 'debounce',
      refreshRate: 0,
    });

  const onResize = useCallback(() => {
    if (tabListContainerRef.current) {
      const { scrollWidth, clientWidth } = tabListContainerRef.current;
      setShowScrollButtons(scrollWidth > clientWidth + OVERFLOW_THRESHOLD);
    }
  }, [tabListContainerRef]);

  const onScroll = useCallback(() => {
    if (tabListContainerRef.current) {
      setCanScrollLeft(tabListContainerRef.current.scrollLeft > 0);
      setCanScrollRight(
        tabListContainerRef.current.scrollLeft +
          tabListContainerRef.current.clientWidth <
          tabListContainerRef.current.scrollWidth - DISABLED_SCROLL_THRESHOLD
      );
    }
  }, [tabListContainerRef, setCanScrollLeft, setCanScrollRight]);

  useEffect(() => {
    onResize();
    onScroll();
  }, [tabListContainerWidth, onResize, onScroll]);

  useEffect(() => {
    const container = tabListContainerRef.current;
    container?.addEventListener('scroll', onScroll);
    return () => container?.removeEventListener('scroll', onScroll);
  }, [tabListContainerRef, onScroll]);

  const [internalActiveTabIndex, setInternalActiveTabIndex] = useState<number>(
    defaultActiveTabIndex ?? 0
  );

  const filteredTabs = React.Children.toArray(tabs).filter((child) =>
    isValidElement(child)
  );

  // Scroll selected tab into view on initial render
  useEffect(() => {
    const tab = filteredTabs[internalActiveTabIndex] as React.ReactElement;
    const tabElId = `tab-${internalActiveTabIndex}-${tab.props.title}`;
    const tabEl = document.getElementById(tabElId);
    if (tabEl) {
      const scrollContainer = tabEl.parentElement;
      if (scrollContainer) {
        const scrollX = tabEl.offsetLeft - scrollContainer.offsetLeft;
        scrollContainer.scrollLeft = scrollX;
      }
    }
    // eslint-disable-next-line
  }, []);

  // uncontrolled tabset uses internal active tab state
  // controlled tabset uses external active tab state
  const isControlledTabset = activeTabIndex !== null;

  const handleClickTab = (index: number) => {
    const clickedCurrentActiveTab = isControlledTabset
      ? activeTabIndex === index
      : internalActiveTabIndex === index;

    if (clickedCurrentActiveTab) return;

    if (!isControlledTabset) {
      setInternalActiveTabIndex(index);
    }

    onClickTab && onClickTab(index);

    const clickedTab = filteredTabs[index] as React.ReactElement;
    clickedTab.props.onClick && clickedTab.props.onClick();
  };

  const scrollToLeft = () => {
    if (tabListContainerRef.current) {
      const container = tabListContainerRef.current;
      const scrollLeft = container.scrollLeft;
      const minScrollLeft = 0;
      const scrollDistance = Math.min(
        SCROLL_AMOUNT,
        scrollLeft - minScrollLeft
      );

      if (scrollLeft > minScrollLeft) {
        container.scrollBy({
          left: -scrollDistance,
          behavior: 'smooth',
        });
      }
    }
  };

  const scrollToRight = () => {
    if (tabListContainerRef.current) {
      const container = tabListContainerRef.current;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const scrollLeft = container.scrollLeft;
      const maxScrollDistance = maxScrollLeft - scrollLeft;
      const scrollDistance = Math.min(SCROLL_AMOUNT, maxScrollDistance);

      if (scrollLeft < maxScrollLeft) {
        container.scrollBy({
          left: scrollDistance,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <div className={classnames(className, styles.Tabset)} style={style}>
      <div className={styles.TabList}>
        <div
          className={classnames(styles.ScrollableTabList, 'syl-tablist', {
            [styles.ShowScrollButtons]: showScrollButtons,
          })}
          ref={tabListContainerRef}
        >
          {showScrollButtons && (
            <div className={styles.ScrollLeft}>
              <Button
                type={ButtonType.Link}
                spacing={ButtonSpacing.Compressed}
                onClick={scrollToLeft}
                disabled={!canScrollLeft}
              >
                <ChevronLeft size={18} />
              </Button>
            </div>
          )}
          {filteredTabs.map((tab, index) => {
            if (!isValidElement(tab)) {
              return null;
            }

            const isActiveTab = isControlledTabset
              ? activeTabIndex === index
              : internalActiveTabIndex === index;
            const tabClasses = classnames(styles.Tab, {
              [styles.ActiveTab]: isActiveTab,
            });

            return (
              <div
                id={`tab-${index}-${tab.props.title}`}
                key={`tab-${index}-${tab.props.title}`}
                className={tabClasses}
                onClick={() => handleClickTab(index)}
              >
                {tab.props.title}
              </div>
            );
          })}
        </div>
        {showScrollButtons && (
          <div className={styles.ScrollRight}>
            <Button
              type={ButtonType.Link}
              spacing={ButtonSpacing.Compressed}
              onClick={scrollToRight}
              disabled={!canScrollRight}
            >
              <ChevronRight size={18} />
            </Button>
          </div>
        )}
      </div>
      <div className={styles.TabContent}>
        {
          tabs[
            isControlledTabset && activeTabIndex
              ? activeTabIndex
              : internalActiveTabIndex
          ]
        }
      </div>
    </div>
  );
}
