import { formatISO } from 'date-fns';
import { useSelector } from 'react-redux';

import { showToast } from 'components/Toast';

import UserRole from 'enums/UserRole';

import {
  createAssignmentUpdate,
  createAssignmentsUpdate,
} from 'graphql/mutations/createAssignments';
import { createAttachmentsUpdate } from 'graphql/mutations/createAttachments';
import { deleteAssignmentUpdate } from 'graphql/mutations/deleteAssignment';
import { GET_HOME_STUDENT_ASSIGNMENTS } from 'graphql/queries/getHomeStudentAssignments';
import {
  useBulkUpdateAssignmentsMutation,
  useCreateAssignmentMutation,
  useCreateAssignmentsMutation,
  useCreateAttachmentsMutation,
  useDeleteAssignmentMutation,
  useUpdateAssignmentMutation,
  useUpdateStudentAssignmentDatesMutation,
  useUpsertStudentAssignmentsDatesMutation,
} from 'graphql/types/generated';

import { getSelectedAssignment } from 'redux/planning';
import { getUserRole } from 'redux/user';

export default function useAssignmentInfoGqlOps() {
  const userRole = useSelector(getUserRole);
  const selectedAssignment = useSelector(getSelectedAssignment);

  const queriesToRefetch = [
    {
      query: GET_HOME_STUDENT_ASSIGNMENTS,
      variables: {
        day: formatISO(new Date(), { representation: 'date' }),
        isStudent: userRole === UserRole.Student,
      },
    },
  ];

  const [updateAssignment, { loading: updateAssignmentLoading }] =
    useUpdateAssignmentMutation({
      refetchQueries: queriesToRefetch,
      onError: () =>
        showToast(
          'updateAssignmentError',
          'Error updating assignment',
          `There was an issue updating your assignment. Try updating again.`,
          'error'
        ),
    });

  const [
    updateStudentAssignmentDates,
    { loading: updateStudentAssignmentDatesLoading },
  ] = useUpdateStudentAssignmentDatesMutation({
    refetchQueries: queriesToRefetch,
    onError: () =>
      showToast(
        'updateStudentAssignmentDatesError',
        'Error updating assignment duration for students',
        'There was an issue updating the assignment duration for students enrolled in this course. Try updating the duration again.',
        'error'
      ),
  });

  const [createAssignment, { loading: createAssignmentLoading }] =
    useCreateAssignmentMutation({
      refetchQueries: queriesToRefetch,
      update: createAssignmentUpdate,
      onError: () =>
        showToast(
          'createAssignmentError',
          'Error creating assignment',
          `There was an issue creating your assignment. Try submitting again.`,
          'error'
        ),
    });

  const [deleteAssignment, { loading: deleteAssignmentLoading }] =
    useDeleteAssignmentMutation({
      update: deleteAssignmentUpdate,
      onError: () =>
        showToast(
          'deleteAssignmentError',
          'Error deleting assignment',
          'There was an issue deleting your assignment. Try deleting again.',
          'error'
        ),
    });

  const [createAttachments, { loading: createAttachmentsLoading }] =
    useCreateAttachmentsMutation({
      update: createAttachmentsUpdate(selectedAssignment),
      onError: () =>
        showToast(
          'createAttachmentsError',
          'Error uploading attachments',
          'There was an issue uploading your attachments. Try uploading them again.',
          'error'
        ),
    });

  const [bulkUpdateAssignments, { loading: bulkUpdateAssignmentLoading }] =
    useBulkUpdateAssignmentsMutation({
      onError: () =>
        showToast(
          'bulkUpdateAssignments',
          'Error bulk updating assignments',
          'There was an issue bulk updating your assignments. Try saving again.',
          'error'
        ),
    });

  const [
    upsertStudentAssignmentDates,
    { loading: upsertStudentAssignmentDatesLoading },
  ] = useUpsertStudentAssignmentsDatesMutation({
    onError: () =>
      showToast(
        'updateStudentAssignmentDates',
        'Error updating assignment dates',
        'There was an issue updating dates for your assignments. Try saving again.',
        'error'
      ),
  });

  const [createAssignments, { loading: createAssignmentsLoading }] =
    useCreateAssignmentsMutation({
      update: createAssignmentsUpdate,
      refetchQueries: queriesToRefetch,
      onError: () =>
        showToast(
          'createAssignments',
          'Error creating assignments',
          'There was an issue creating your assignments. Try submitting again.',
          'error'
        ),
    });

  return {
    updateAssignment: {
      call: updateAssignment,
      loading: updateAssignmentLoading,
    },
    updateStudentAssignmentDates: {
      call: updateStudentAssignmentDates,
      loading: updateStudentAssignmentDatesLoading,
    },
    createAssignment: {
      call: createAssignment,
      loading: createAssignmentLoading,
    },
    deleteAssignment: {
      call: deleteAssignment,
      loading: deleteAssignmentLoading,
    },
    createAttachments: {
      call: createAttachments,
      loading: createAttachmentsLoading,
    },
    bulkUpdateAssignments: {
      call: bulkUpdateAssignments,
      loading: bulkUpdateAssignmentLoading,
    },
    upsertStudentAssignmentDates: {
      call: upsertStudentAssignmentDates,
      loading: upsertStudentAssignmentDatesLoading,
    },
    createAssignments: {
      call: createAssignments,
      loading: createAssignmentsLoading,
    },
    anyLoading:
      updateAssignmentLoading ||
      updateStudentAssignmentDatesLoading ||
      createAssignmentLoading ||
      deleteAssignmentLoading ||
      bulkUpdateAssignmentLoading ||
      upsertStudentAssignmentDatesLoading ||
      createAssignmentsLoading,
  };
}
