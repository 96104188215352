import classnames from 'classnames';
import { CSSProperties, useRef } from 'react';
import { Search } from 'react-feather';

import { useTheme } from 'context/Theme';

import styles from './SearchBar.module.scss';

interface SearchBarProps {
  className?: string;
  id: string;
  name: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export default function SearchBar({
  className,
  id,
  name,
  placeholder,
  value,
  defaultValue,
  onChange,
  onFocus,
}: SearchBarProps): JSX.Element {
  const { theme } = useTheme();

  const searchInput = useRef<HTMLInputElement>(null);

  const classes = classnames(styles.SearchBar, className);

  const searchIconStyles: CSSProperties = {
    color: value?.length ? theme.contentPrimary : theme.elementBorder,
  };

  return (
    <div className={classes}>
      <input
        className={styles.SearchInput}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        type="text"
        onChange={onChange}
        onFocus={onFocus}
        autoComplete="off"
        defaultValue={defaultValue}
        ref={searchInput}
      />
      <Search
        style={searchIconStyles}
        className={styles.SearchIcon}
        size={17}
        onClick={() => searchInput?.current && searchInput.current.focus()}
      />
    </div>
  );
}
