import { useApolloClient } from '@apollo/client';
import { formatISO, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Options } from 'react-select';

import Button, { ButtonType } from 'components/Button';
import Card from 'components/Card';
import Checkbox from 'components/Checkbox';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import ErrorMessage from 'components/ErrorMessage';
import ModalPortal from 'components/ModalPortal';
import { showToast } from 'components/Toast';

import UserRole from 'enums/UserRole';

import { GET_EVENTS_INFO } from 'graphql/queries/getEventsInfo';
import { GET_HOME_STUDENT_ASSIGNMENTS } from 'graphql/queries/getHomeStudentAssignments';
import {
  GetEventsInfoQuery,
  PlanningAssignmentFragment,
  StudentAssignmentToRescheduleFragment,
  useUpdateAssignmentDayWeekMutation,
  useUpsertStudentAssignmentsDatesMutation,
} from 'graphql/types/generated';

import useLogtail from 'hooks/useLogtail';

import {
  getFollowingAssignments,
  getNumberOfDaysPerWeek,
  getNumberOfWeeks,
  getSelectedAssignment,
  getStudentCourses,
} from 'redux/planning';
import { getUserRole } from 'redux/user';

import { getDateRangesFromEvents } from 'utils/events';
import { generateNDropdownOptions } from 'utils/generateNDropdownOptions';
import { rescheduleAssignments } from 'utils/planning';
import { rescheduleStudentAssignments } from 'utils/studentAssignment';

import styles from './ShiftAssignmentModal.module.scss';

interface ShiftAssignmentFormData {
  week: DropdownOption<number> | null;
  day: DropdownOption<number> | null;
  shiftFollowingAssignments: boolean;
}

interface ShiftAssignmentModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ShiftAssignmentModal({
  isOpen,
  onClose,
}: ShiftAssignmentModalProps) {
  const logtail = useLogtail();
  const apolloClient = useApolloClient();

  const selectedAssignment = useSelector(getSelectedAssignment);
  const numberOfWeeks = useSelector(getNumberOfWeeks);
  const numberOfDaysPerWeek = useSelector(getNumberOfDaysPerWeek);
  const followingAssignments = useSelector(getFollowingAssignments);
  const studentCourses = useSelector(getStudentCourses);
  const numFollowingAssignments = followingAssignments?.length ?? 0;
  const userRole = useSelector(getUserRole);

  const currentWeek = selectedAssignment?.week;
  const currentDay = selectedAssignment?.day;

  const { control, handleSubmit, reset, watch, getValues, setValue } =
    useForm<ShiftAssignmentFormData>({
      defaultValues: {
        week: currentWeek
          ? {
              label: `Week ${currentWeek}`,
              value: currentWeek,
            }
          : null,
        day: currentDay
          ? {
              label: `Day ${currentDay}`,
              value: currentDay,
            }
          : null,
        shiftFollowingAssignments: true,
      },
    });

  const isShiftFollowingAssignmentsSelected = watch(
    'shiftFollowingAssignments'
  );
  const formWeek = watch('week')?.value;
  const formDay = watch('day')?.value;
  const isUnscheduling = !formWeek && !formDay;

  useEffect(() => {
    if (isUnscheduling) {
      setValue('shiftFollowingAssignments', false);
    }
  }, [isUnscheduling, setValue]);

  const [isShiftingAssignments, setIsShiftingAssignments] =
    useState<boolean>(false);

  const [scheduleError, setScheduleError] = useState<FieldError | undefined>(
    undefined
  );

  const [dayOptions, setDayOptions] = useState<Options<DropdownOption<number>>>(
    []
  );
  const [weekOptions, setWeekOptions] = useState<
    Options<DropdownOption<number>>
  >([]);

  useEffect(() => {
    numberOfDaysPerWeek &&
      setDayOptions(generateNDropdownOptions('Day', numberOfDaysPerWeek));
    numberOfWeeks &&
      setWeekOptions(generateNDropdownOptions('Week', numberOfWeeks));
  }, [numberOfDaysPerWeek, numberOfWeeks]);

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const [updateAssignmentDayWeek] = useUpdateAssignmentDayWeekMutation();

  const [upsertStudentAssignmentDates] =
    useUpsertStudentAssignmentsDatesMutation({
      onError: () =>
        showToast(
          'updateStudentAssignmentDates',
          'Error updating assignment dates',
          'There was an issue updating dates for your assignments. Try saving again.',
          'error'
        ),
    });

  const weekAndDayAreValid = () => {
    if (!!getValues('day') !== !!getValues('week')) {
      setScheduleError({
        type: 'weekAndDayAreValid',
        message: 'Day and Week must either be both selected or neither',
      });
      return false;
    } else {
      setScheduleError(undefined);
      return true;
    }
  };

  const handleShiftAssignments = async ({
    week,
    day,
    shiftFollowingAssignments,
  }: ShiftAssignmentFormData) => {
    setIsShiftingAssignments(true);

    const assignmentsToReschedule = (
      isUnscheduling
        ? [
            {
              ...selectedAssignment,
              week: null,
              day: null,
            } as PlanningAssignmentFragment,
          ]
        : shiftFollowingAssignments
        ? followingAssignments || []
        : [selectedAssignment]
    ) as PlanningAssignmentFragment[];

    if (
      !assignmentsToReschedule.length ||
      !selectedAssignment?.day ||
      !selectedAssignment?.week ||
      !numberOfDaysPerWeek ||
      !numberOfWeeks
    ) {
      setIsShiftingAssignments(false);
      handleClose();
    } else {
      const numWeeksToShiftAhead = week?.value
        ? week.value - selectedAssignment.week
        : 0;
      const numDaysToShiftAhead = day?.value
        ? day.value - selectedAssignment.day
        : 0;
      const rescheduledAssignments = rescheduleAssignments(
        assignmentsToReschedule,
        numWeeksToShiftAhead,
        numDaysToShiftAhead,
        numberOfDaysPerWeek,
        numberOfWeeks
      );

      const assignmentsForUpsert = rescheduledAssignments.map(
        ({
          __typename,
          studentAssignments,
          attachments,
          created,
          customAssignmentType,
          ...assignment
        }) => assignment
      );

      const updateAssignmentDayWeekResult = await updateAssignmentDayWeek({
        variables: {
          assignments: assignmentsForUpsert,
        },
      });

      const updatedAssignments =
        updateAssignmentDayWeekResult?.data?.insert_assignments?.returning;

      if (
        !updatedAssignments?.length ||
        updateAssignmentDayWeekResult.errors?.length
      ) {
        showToast(
          'updateAssignmentDayWeekWithShift',
          'Error shifting assignments in course',
          'There as an issue moving assignments. Please try refreshing and try again.',
          'error'
        );
        setIsShiftingAssignments(false);
        handleClose();
        return false;
      }

      const studentIds: string[] = [
        ...new Set(
          updatedAssignments?.flatMap(({ studentAssignments }) =>
            studentAssignments.map((sa) => sa.student.id)
          )
        ),
      ];

      const { data: getEventsInfoData, error: getEventsInfoError } =
        await apolloClient.query<GetEventsInfoQuery>({
          query: GET_EVENTS_INFO,
          variables: { studentIds },
          fetchPolicy: 'no-cache',
        });
      const events = getEventsInfoData?.events || [];

      if (getEventsInfoError) {
        showToast(
          'getStudentEvents',
          'Error fetching vacations',
          'There as an issue fetching vacations for your students to update assignments. Please try refreshing and try again.',
          'error'
        );
        setIsShiftingAssignments(false);
        handleClose();
        return false;
      }

      const updatedAssignmentIds = updatedAssignments.map(
        (assignment) => assignment.id
      );

      const rescheduledStudentAssignments = studentCourses?.flatMap(
        (studentCourse) => {
          const studentAssignmentsToReschedule =
            studentCourse.studentAssignments
              .filter((studentAssignment) =>
                updatedAssignmentIds.includes(studentAssignment.assignment.id)
              )
              .map((studentAssignment) => {
                const updatedAssignment = updatedAssignments.find(
                  (assignment) =>
                    assignment.id === studentAssignment.assignment.id
                );
                return {
                  ...studentAssignment,
                  assignment: {
                    ...studentAssignment.assignment,
                    day: updatedAssignment?.day,
                    week: updatedAssignment?.week,
                  },
                };
              });

          const currentVacationsForStudent = getDateRangesFromEvents(
            events.filter((event) =>
              event.studentEvents
                .map((studentEvent) => studentEvent.student.id)
                .includes(studentCourse.student.id)
            )
          );

          return rescheduleStudentAssignments(
            parseISO(studentCourse.start),
            studentAssignmentsToReschedule,
            studentCourse.daysOfTheWeek as Record<string, boolean>,
            currentVacationsForStudent,
            (studentCourse.courseDayOffsets ?? {}) as Record<number, number>
          );
        }
      ) as StudentAssignmentToRescheduleFragment[];

      const studentAssignmentsForUpsert =
        rescheduledStudentAssignments?.map(
          ({ __typename, assignment, ...studentAssignment }) =>
            studentAssignment
        ) || [];

      const { errors: upsertStudentAssignmentDatesError } =
        await upsertStudentAssignmentDates({
          variables: { studentAssignments: studentAssignmentsForUpsert },
          refetchQueries: [
            {
              query: GET_HOME_STUDENT_ASSIGNMENTS,
              variables: {
                day: formatISO(new Date(), { representation: 'date' }),
                isStudent: userRole === UserRole.Student,
              },
            },
          ],
        });

      if (upsertStudentAssignmentDatesError) {
        showToast(
          'upsertStudentAssignmentDatesError',
          'Error updating student assignments',
          "There as an issue updating your students' assignments. Please try refreshing and try again.",
          'error'
        );
        setIsShiftingAssignments(false);
        handleClose();
        return false;
      }

      const updatedStudentAssignmentIds = rescheduledStudentAssignments.map(
        (sa) => sa.id
      );

      logtail.info('reschedule_assignments', {
        event: {
          originalAssignmentDay: selectedAssignment.day,
          originalAssignmentWeek: selectedAssignment.week,
          newAssignmentDay: formDay ?? null,
          newAssignmentWeek: formWeek ?? null,
          numDaysToShiftAhead,
          numWeeksToShiftAhead,
          selectedAssignmentId: selectedAssignment.id,
          affectedAssignmentIds: updatedAssignmentIds.toString(),
          affectedStudentAssignmentIds: updatedStudentAssignmentIds.toString(),
        },
      });

      setIsShiftingAssignments(false);
      handleClose();
    }
  };

  const getSubmitButtonText = () => {
    if (isUnscheduling) {
      return 'Unschedule Assignment';
    } else if (
      isShiftFollowingAssignmentsSelected &&
      numFollowingAssignments > 1
    ) {
      return `Move ${numFollowingAssignments} Assignments`;
    } else {
      return 'Move 1 Assignment';
    }
  };

  return (
    <ModalPortal isOpen={isOpen} onClose={handleClose}>
      <Card>
        <form
          className={styles.Modal}
          onSubmit={handleSubmit(handleShiftAssignments)}
        >
          <h1 className={styles.Header}>Move Assignments</h1>
          <Controller
            name="shiftFollowingAssignments"
            render={({ field: { value, name, onChange } }) => (
              <Checkbox
                className={styles.Checkbox}
                id="shift-following-assignments"
                isSelected={value}
                name={name}
                onChange={onChange}
                disabled={isShiftingAssignments || isUnscheduling}
              >
                <span>Shift following assignments in course plan</span>
              </Checkbox>
            )}
            control={control}
          />
          <div>
            Move from{' '}
            <strong>
              Week {currentWeek}, Day {currentDay}{' '}
            </strong>
            to:
          </div>
          <div className={styles.WeekAndDay}>
            <Controller
              name="week"
              render={({ field: { value, name, onChange } }) => (
                <Dropdown
                  className={styles.Week}
                  id="week-shift"
                  label="Select Week"
                  placeholder="Unscheduled"
                  options={weekOptions}
                  isClearable
                  errors={scheduleError}
                  hideErrorMessage
                  disabled={isShiftingAssignments}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              rules={{
                validate: {
                  weekAndDayAreValid,
                },
              }}
            />
            <Controller
              name="day"
              render={({ field: { value, name, onChange } }) => (
                <Dropdown
                  className={styles.Day}
                  id="day-shift"
                  label="Select Day"
                  placeholder={'Unscheduled'}
                  options={dayOptions}
                  isClearable
                  errors={scheduleError}
                  hideErrorMessage
                  disabled={isShiftingAssignments}
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              rules={{
                validate: {
                  weekAndDayAreValid,
                },
              }}
            />
          </div>
          <ErrorMessage
            className={styles.ErrorMessage}
            message={scheduleError?.message}
          />
          <div className={styles.Buttons}>
            <Button
              className={styles.CancelButton}
              onClick={handleClose}
              type={ButtonType.Secondary}
              disabled={isShiftingAssignments}
            >
              <span>Cancel</span>
            </Button>
            <Button
              submit
              className={styles.SubmitButton}
              disabled={isShiftingAssignments}
              loading={isShiftingAssignments}
            >
              <span>{getSubmitButtonText()}</span>
            </Button>
          </div>
        </form>
      </Card>
    </ModalPortal>
  );
}
