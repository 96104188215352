import classnames from 'classnames';
import { format, isSameDay, parseISO } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import { Check, ChevronsRight, Copy, Feather, Trash2 } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import ActionMeatballs from 'components/ActionMeatballs';
import AssignmentInfoForm, {
  defaultAssignmentInfoFormValues,
  maxNumberOfAttachments,
} from 'components/AssignmentInfoForm';
import Button, {
  ButtonColor,
  ButtonSpacing,
  ButtonType,
} from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import { DropdownOption } from 'components/Dropdown';
import { UploadedFile } from 'components/FileUploader';
import ShiftAssignmentModal from 'components/ShiftAssignmentModal';
import UserIcon from 'components/UserIcon';

import { studentAssignmentStatusLabels } from 'constants/studentAssignmentStatusLabels';

import { Student_Assignment_Status_Enum } from 'graphql/types/generated';

import {
  getActiveTabIndex,
  getAssignmentTypeWeights,
  getFilteredAssignments,
  getIsCreatingNewAssignment,
  getParrotRequestId,
  getSelectedAssignment,
  getStudentCourses,
  getStudentVacations,
  selectAssignmentAndScrollToTop,
  setKeepAssignmentSelected,
  updateCurrentWeekAndDay,
} from 'redux/planning';
import { useAppDispatch } from 'redux/store';
import { getIsParrotUser } from 'redux/user';

import { getDateRangesFromStudentEvents } from 'utils/events';
import {
  createStudentAssignmentsForAssignment,
  rescheduleStudentAssignment,
} from 'utils/studentAssignment';

import styles from './AssignmentInfo.module.scss';
import useAssignmentInfoGqlOps from './useAssignmentInfoGqlOps';

interface AssignmentInfoFormData {
  assignmentName: string;
  description: string;
  teachersNote: string;
  graded: boolean;
  assignmentTypeOption: DropdownOption<string | null>;
  duration: number;
  day: DropdownOption<number> | null;
  week: DropdownOption<number> | null;
}

interface AssignmentInfoProps {
  className?: string;
  courseId: string;
}

const NUM_STUDENTS_TO_DISPLAY_DESKTOP = 10;
const NUM_STUDENTS_TO_DISPLAY_MOBILE = 3;

export const assignmentInfoFieldsId = 'assignment-info-fields';
export const assignmentNameId = 'assignment-name';

function renderToolTip(
  startDate: Date | null,
  endDate: Date | null,
  status: string,
  studentName: string
): string {
  const studentAssignmentSchedule =
    startDate && endDate
      ? isSameDay(startDate, endDate)
        ? `<div>
          <span>Scheduled: </span>
          ${format(endDate, 'E, M/d')}
        </div>`
        : `<div>
          <span>Scheduled: </span>
          ${format(startDate, 'E, M/d')} to ${format(endDate, 'E, M/d')}
        </div>`
      : `<div>Unscheduled</div>`;

  return `<div>
      <div>${studentName}</div>
      <span>Status: ${status}</span>
      ${studentAssignmentSchedule}
    </div>`;
}

export default function AssignmentInfo({
  className,
  courseId,
}: AssignmentInfoProps): JSX.Element | null {
  const isSmallScreen = useMediaQuery({
    query: '(max-width: 550px)',
  });

  const dispatch = useAppDispatch();

  const isParrotUser = useSelector(getIsParrotUser);
  const parrotRequestId = useSelector(getParrotRequestId);

  const {
    updateAssignment,
    updateStudentAssignmentDates,
    createAssignment,
    deleteAssignment,
    createAttachments,
    anyLoading,
  } = useAssignmentInfoGqlOps();

  const filteredAssignments = useSelector(getFilteredAssignments);
  const studentCourses = useSelector(getStudentCourses);
  const studentVacations = useSelector(getStudentVacations);

  const activeTabIndex = useSelector(getActiveTabIndex);

  const isCreatingNewAssignment = useSelector(getIsCreatingNewAssignment);
  const selectedAssignment = useSelector(getSelectedAssignment);
  const assignmentTypeWeights = useSelector(getAssignmentTypeWeights);

  const studentAssignments = selectedAssignment?.studentAssignments || [];
  const attachments = selectedAssignment?.attachments;

  const remainingAttachments =
    maxNumberOfAttachments - (attachments?.length ?? 0);

  const [isShiftAssignmentModalOpen, setIsShiftAssignmentModalOpen] =
    useState<boolean>(false);

  const formMethods = useForm<AssignmentInfoFormData>({
    defaultValues: defaultAssignmentInfoFormValues,
  });
  const { handleSubmit, reset } = formMethods;

  const [isConfirmationPortalOpen, setIsConfirmationPortalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (!assignmentTypeWeights.length) {
      return;
    }

    const assignmentTypeOption = selectedAssignment?.customAssignmentType
      ? {
          label: selectedAssignment.customAssignmentType.name,
          value: selectedAssignment.customAssignmentType.id,
        }
      : {
          label: assignmentTypeWeights[0].customAssignmentType?.name ?? '',
          value: assignmentTypeWeights[0].customAssignmentType?.id ?? null,
        };

    reset({
      assignmentName:
        selectedAssignment?.name ??
        defaultAssignmentInfoFormValues.assignmentName,
      description:
        selectedAssignment?.description ??
        defaultAssignmentInfoFormValues.description,
      teachersNote:
        selectedAssignment?.teachersNote ??
        defaultAssignmentInfoFormValues.teachersNote,
      // don't display '0' in the duration field, instead display as blank
      duration:
        selectedAssignment?.duration === 0
          ? defaultAssignmentInfoFormValues.duration
          : selectedAssignment?.duration,
      graded:
        selectedAssignment?.graded ?? defaultAssignmentInfoFormValues.graded,
      assignmentTypeOption,
      day: selectedAssignment?.day
        ? {
            label: `Day ${selectedAssignment.day}`,
            value: selectedAssignment.day,
          }
        : defaultAssignmentInfoFormValues.day,
      week: selectedAssignment?.week
        ? {
            label: `Week ${selectedAssignment.week}`,
            value: selectedAssignment.week,
          }
        : defaultAssignmentInfoFormValues.week,
    });
  }, [reset, selectedAssignment, assignmentTypeWeights]);

  const handleDuplicateAssignment = async () => {
    if (!selectedAssignment) {
      return;
    }
    const day: number | null = selectedAssignment.day ?? null;
    const week: number | null = selectedAssignment.week ?? null;

    const studentAssignments = !isParrotUser
      ? createStudentAssignmentsForAssignment(
          studentCourses || [],
          studentVacations,
          day,
          week,
          selectedAssignment.duration
        ) || []
      : [];

    const result = await createAssignment.call({
      variables: {
        assignment: {
          courseId,
          name: selectedAssignment.name,
          description:
            // Quill inserts this string when a user adds text and then deletes it.
            // The text box will look empty but will contain just this string.
            selectedAssignment.description !== '<p><br></p>'
              ? selectedAssignment.description
              : null,
          teachersNote:
            selectedAssignment.teachersNote !== '<p><br></p>'
              ? selectedAssignment.teachersNote
              : null,
          graded: selectedAssignment.graded,
          customAssignmentTypeId: selectedAssignment.customAssignmentType?.id,
          day: selectedAssignment.day,
          week: selectedAssignment.week,
          duration: selectedAssignment.duration,
          studentAssignments: {
            data: studentAssignments,
          },
          attachments: {
            data:
              attachments?.map((attachment) => ({ url: attachment.url })) || [],
          },
          visible: isParrotUser ? false : true,
          parrotRequestId: isParrotUser ? parrotRequestId : null,
        },
      },
    });

    const createdAssignment = result?.data?.assignment;

    createdAssignment &&
      dispatch(selectAssignmentAndScrollToTop(createdAssignment?.id));
    createdAssignment && dispatch(updateCurrentWeekAndDay(createdAssignment));

    const createdAssignmentElId = `assignment-${createdAssignment?.id}-${activeTabIndex}`;
    const createdAssignmentEl = document.getElementById(createdAssignmentElId);
    createdAssignmentEl?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleDeleteAssignment = async () => {
    if (!selectedAssignment || !filteredAssignments) {
      return;
    }

    await deleteAssignment.call({
      variables: {
        assignmentId: selectedAssignment.id,
      },
    });

    setIsConfirmationPortalOpen(false);
    dispatch(selectAssignmentAndScrollToTop(null));
  };

  const handleSaveAssignment = async (data: AssignmentInfoFormData) => {
    if (!selectedAssignment) {
      return;
    }

    if (
      data.day?.value !== selectedAssignment.day ||
      data.week?.value !== selectedAssignment.week
    ) {
      dispatch(setKeepAssignmentSelected(true));
    }

    const updateAssignmentResult = await updateAssignment.call({
      variables: {
        assignmentId: selectedAssignment.id,
        name: data.assignmentName,
        description:
          // Quill inserts this string when a user adds text and then deletes it.
          // The text box will look empty but will contain just this string.
          data.description !== '<p><br></p>' ? data.description : null,
        teachersNote:
          data.teachersNote !== '<p><br></p>' ? data.teachersNote : null,
        duration: data.duration || 0,
        graded: data.graded,
        customAssignmentTypeId: data.assignmentTypeOption.value,
        day: data.day?.value,
        week: data.week?.value,
      },
    });

    const updatedAssignment = updateAssignmentResult.data?.assignment;

    if (studentAssignments.length) {
      const resetCourseDayOffset =
        updatedAssignment?.day !== selectedAssignment.day ||
        updatedAssignment?.week !== selectedAssignment.week;

      const newStudentAssignments = studentAssignments.map(
        (studentAssignment) => {
          const currentVacationsForStudent = getDateRangesFromStudentEvents(
            studentAssignment.student.studentEvents
          );

          return rescheduleStudentAssignment(
            parseISO(studentAssignment.studentCourse.start),
            {
              ...studentAssignment,
              courseDayOffset: resetCourseDayOffset
                ? 0
                : studentAssignment.courseDayOffset,
            },
            studentAssignment.studentCourse.daysOfTheWeek as Record<
              string,
              boolean
            >,
            currentVacationsForStudent,
            updatedAssignment?.day ?? null,
            updatedAssignment?.week ?? null,
            updatedAssignment?.duration ?? 0,
            (studentAssignment.studentCourse.courseDayOffsets ?? {}) as Record<
              number,
              number
            >
          );
        }
      );

      newStudentAssignments.forEach((newStudentAssignment) =>
        updateStudentAssignmentDates.call({
          variables: {
            studentAssignmentId: newStudentAssignment.id,
            start: newStudentAssignment.start,
            end: newStudentAssignment.end,
            courseDayOffset: newStudentAssignment.courseDayOffset,
          },
        })
      );
    }

    updatedAssignment && updateCurrentWeekAndDay(updatedAssignment);
  };

  const handleSuccessfulAttachment = (files: UploadedFile[]) => {
    if (files.length) {
      const attachments = files.map((file) => ({
        url: file.url,
        size: file.size,
        assignmentId: selectedAssignment?.id,
      }));

      createAttachments.call({
        variables: {
          attachments,
        },
      });
    }
  };

  const renderActiveStudents = () => {
    if (!studentAssignments) {
      return null;
    }

    const numStudentsToDisplay = isSmallScreen
      ? NUM_STUDENTS_TO_DISPLAY_MOBILE
      : NUM_STUDENTS_TO_DISPLAY_DESKTOP;
    const studentAssignmentsToDisplay = studentAssignments?.slice(
      0,
      numStudentsToDisplay
    );
    const hiddenStudentAssignments =
      studentAssignments?.slice(numStudentsToDisplay);
    const hiddenStudentsNames = hiddenStudentAssignments
      ?.map((studentAssignment) => studentAssignment.student.name)
      .join(', ');

    return studentAssignments.length ? (
      <Fragment>
        <span className={styles.ActiveStudentsLabel}>Active Students: </span>
        <div className={styles.ActiveStudentsList}>
          {studentAssignmentsToDisplay.map((studentAssignment) => {
            const isCompleted =
              studentAssignment.status ===
              Student_Assignment_Status_Enum.Complete;
            return (
              <div
                key={studentAssignment.id}
                className={styles.StudentIconWrapper}
              >
                <UserIcon
                  key={`student-icon-${studentAssignment.student?.id}`}
                  className={styles.StudentIcon}
                  label={studentAssignment.student.name}
                  picture={studentAssignment.student.picture}
                  toolTip={renderToolTip(
                    studentAssignment.start
                      ? parseISO(studentAssignment.start)
                      : null,
                    studentAssignment.end
                      ? parseISO(studentAssignment.end)
                      : null,
                    studentAssignmentStatusLabels[studentAssignment.status],
                    studentAssignment.student.name
                  )}
                />
                {isCompleted ? (
                  <div className={styles.CompletedMark}>
                    <Check height={10} width={10} strokeWidth={4} />
                  </div>
                ) : null}
              </div>
            );
          })}
          {hiddenStudentAssignments?.length > 0 && (
            <span
              data-tippy-content={hiddenStudentsNames}
              className={styles.HiddenStudents}
            >
              + {hiddenStudentAssignments.length} more
            </span>
          )}
        </div>
      </Fragment>
    ) : (
      <span className={styles.NoActiveStudentsLabel}>No active students</span>
    );
  };

  const renderActionButtons = () => {
    return (
      <Fragment>
        <Button
          className={styles.ActionButton}
          spacing={ButtonSpacing.Compressed}
          type={ButtonType.Minimal}
          onClick={handleDuplicateAssignment}
        >
          <Copy />
          <span>Duplicate</span>
        </Button>
        {selectedAssignment?.day && selectedAssignment.week ? (
          <Button
            className={styles.ActionButton}
            spacing={ButtonSpacing.Compressed}
            type={ButtonType.Minimal}
            onClick={() => setIsShiftAssignmentModalOpen(true)}
          >
            <ChevronsRight />
            <span>Move</span>
          </Button>
        ) : null}
        <Button
          className={styles.ActionButton}
          spacing={ButtonSpacing.Compressed}
          type={ButtonType.Minimal}
          color={ButtonColor.Negative}
          onClick={() => setIsConfirmationPortalOpen(true)}
        >
          <Trash2 />
          <span>Delete</span>
        </Button>
      </Fragment>
    );
  };

  const renderHeader = () => {
    return (
      <Fragment>
        <div className={styles.Header}>
          {!isParrotUser && renderActiveStudents()}
          <div className={styles.HeaderRightItems}>
            {selectedAssignment?.parrotRequestId && !isParrotUser ? (
              <div
                data-tippy-content="Assignment created with Parrot"
                className={styles.ParrotRequestIcon}
              >
                <Feather size={17} />
              </div>
            ) : null}
            {!isCreatingNewAssignment ? (
              <ActionMeatballs>
                <div className={styles.ActionButtons}>
                  {renderActionButtons()}
                </div>
              </ActionMeatballs>
            ) : null}
          </div>
        </div>
        <hr />
      </Fragment>
    );
  };

  const classes = classnames(className, styles.AssignmentInfo);

  if (!selectedAssignment && !isCreatingNewAssignment) {
    return null;
  }

  const buttonConfigs = {
    secondary: {
      title: `Cancel`,
      onClick: () => dispatch(selectAssignmentAndScrollToTop(null)),
      color: ButtonColor.Normal,
      type: ButtonType.Secondary,
    },
    primary: {
      title: `Save Assignment`,
      isSubmit: true,
      color: ButtonColor.Normal,
      type: ButtonType.Primary,
    },
  };

  return (
    <div className={classes}>
      {renderHeader()}
      <AssignmentInfoForm
        onSubmit={handleSubmit(handleSaveAssignment)}
        formMethods={formMethods}
        isLoading={anyLoading}
        buttonConfigs={buttonConfigs}
        allowAttachments={true}
        attachments={attachments}
        maxNumberOfFiles={remainingAttachments}
        onSuccessfulAttachment={handleSuccessfulAttachment}
        contentClassName={styles.ScrollableContent}
      />
      <ConfirmationModal
        isOpen={isConfirmationPortalOpen}
        title={`Delete ${selectedAssignment?.name}?`}
        body={`This cannot be undone.  "${selectedAssignment?.name}" will be permanently deleted.`}
        onCancel={() => setIsConfirmationPortalOpen(false)}
        onConfirm={handleDeleteAssignment}
        confirmButtonText={'Yes, Delete Assignment'}
        confirmColor={ButtonColor.Negative}
        confirmIsLoading={deleteAssignment.loading}
      />
      <ShiftAssignmentModal
        isOpen={isShiftAssignmentModalOpen}
        onClose={() => setIsShiftAssignmentModalOpen(false)}
      />
    </div>
  );
}
