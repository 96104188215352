import styles from './LoadingIndicator.module.scss';

interface LoadingIndicatorProps {
  className?: string;
  loadingMessage?: string | null;
}

export default function LoadingIndicator({
  className,
  loadingMessage,
}: LoadingIndicatorProps): JSX.Element {
  return (
    <div className={className}>
      <div className={styles.LoaderContainer}>
        <span className={styles.Loader} />
        {loadingMessage ? (
          <div className={styles.LoadingMessage}>{loadingMessage}</div>
        ) : null}
      </div>
    </div>
  );
}
