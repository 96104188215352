import { getCoursePlanDayElId } from 'components/CoursePlanDays/CoursePlanDays';

import {
  longWeekDayToShortWeekDay,
  weekDayOrder,
} from 'constants/weekDayOrder';

import { PlanningAssignmentFragment } from 'graphql/types/generated';

import { coursePlanDaysElId } from 'pages/CoursePlanPage/CoursePlanPage';

import { getNumDayInCourse, getWeekAndDayInCourse } from './studentAssignment';

export function convertDaysObjectToList(
  daysObject: Record<string, boolean> | null | undefined
): boolean[] {
  return weekDayOrder.map((day) => daysObject?.[day] ?? false);
}

export function convertListToDaysObject(
  daysOfTheWeekList: boolean[] | null | undefined
): Record<string, boolean> {
  if (!daysOfTheWeekList) {
    return {};
  }

  return daysOfTheWeekList.reduce((acc, isDaySelected, i) => {
    const currentDay = weekDayOrder[i];
    acc[currentDay] = isDaySelected;
    return acc;
  }, {} as Record<string, boolean>);
}

export function convertDaysObjectToDisplayList(
  daysObject: Record<string, boolean> | null | undefined
): string[] {
  const selectedDays = Object.keys(daysObject ?? {}).reduce((acc, day) => {
    if (daysObject?.[day]) {
      acc = [...acc, day];
    }
    return acc;
  }, [] as string[]);

  const sortedDays = [...selectedDays].sort((dayA, dayB) => {
    const indexA = weekDayOrder.indexOf(dayA);
    const indexB = weekDayOrder.indexOf(dayB);

    return indexA - indexB; // Sorting based on the index in weekDayOrder
  });

  return sortedDays.map((day) => longWeekDayToShortWeekDay[day]);
}
export function scrollCoursePlanDaysToDay(
  courseId: string,
  day: number,
  week: number
) {
  const dayElId = getCoursePlanDayElId(courseId, day, week);
  const dayEl = document.getElementById(dayElId);
  const containerEl = document.getElementById(coursePlanDaysElId);

  if (containerEl && dayEl) {
    containerEl.scrollTop = dayEl.offsetTop - 40;
  }
}

/**
 * Return assignments after shifting their day/week in the course
 *
 * If assignments are shifted beyond the course structure, their
 * day and week are set to null
 *
 * @param assignments assignments for which to update day/week
 * @param numWeeksToShiftAhead number of weeks to shift forward (or backwards if <0)
 * @param numDaysToShiftAhead number of days to shift forward (or backwards if <0)
 * @param numDaysPerWeek number of course days in the course
 * @param numWeeksInCourse number of weeks in the course
 * @returns Assignments with updated day/week
 */
export function rescheduleAssignments(
  assignments: PlanningAssignmentFragment[],
  numWeeksToShiftAhead: number,
  numDaysToShiftAhead: number,
  numDaysPerWeek: number,
  numWeeksInCourse: number
) {
  return assignments.map((assignment) => {
    const currentWeek = assignment.week;
    const currentDay = assignment.day;
    if (!currentWeek || !currentDay) {
      return assignment;
    } else {
      const currentNumDayInCourse = getNumDayInCourse(
        numDaysPerWeek,
        currentWeek,
        currentDay
      );
      const numDaysToOffset =
        numWeeksToShiftAhead * numDaysPerWeek + numDaysToShiftAhead;
      const newNumDayInCourse = currentNumDayInCourse + numDaysToOffset;
      const { week: newWeekInCourse, day: newDayInCourse } =
        getWeekAndDayInCourse(numDaysPerWeek, newNumDayInCourse);

      if (newWeekInCourse > numWeeksInCourse) {
        return { ...assignment, week: null, day: null };
      } else {
        return { ...assignment, week: newWeekInCourse, day: newDayInCourse };
      }
    }
  });
}
