import { MutationUpdaterFn, gql } from '@apollo/client';

import { AssignmentInfo } from 'graphql/fragments/assignment';
import { DeleteAssignmentMutation } from 'graphql/types/generated';

export const DELETE_ASSIGNMENT = gql`
  mutation DeleteAssignment($assignmentId: uuid!) {
    assignment: delete_assignment(id: $assignmentId) {
      ...AssignmentInfo
    }
  }
  ${AssignmentInfo}
`;

export const deleteAssignmentUpdate: MutationUpdaterFn<
  DeleteAssignmentMutation
> = (cache, { data: deletedAssignmentData }) => {
  if (deletedAssignmentData?.assignment) {
    cache.evict({ id: cache.identify(deletedAssignmentData.assignment) });
    cache.gc();
  }
};
