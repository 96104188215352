import { animated, useTransition } from '@react-spring/web';
import classnames from 'classnames';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { MoreVertical } from 'react-feather';

import Button, { ButtonSpacing, ButtonType } from 'components/Button';

import styles from './ActionMeatballs.module.scss';

interface ActionMeatballsProps {
  className?: string;
  width?: string;
  children: JSX.Element;
}

export default function ActionMeatballs({
  className,
  width,
  children,
}: ActionMeatballsProps): JSX.Element {
  const actionMeatballsRef = useRef<HTMLDivElement>(null);
  const [isActionMenuOpen, setIsActionMenuOpen] = useState<boolean>(false);

  const transitions = useTransition(isActionMenuOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 50,
    },
  });

  const handleToggleActionMenu = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsActionMenuOpen(!isActionMenuOpen);
    },
    [isActionMenuOpen]
  );

  const handleCloseActionMenu = useCallback(() => {
    if (!isActionMenuOpen) {
      return;
    }

    setIsActionMenuOpen(false);
  }, [isActionMenuOpen]);

  useEffect(() => {
    document.addEventListener('click', handleCloseActionMenu);
    return () => {
      document.removeEventListener('click', handleCloseActionMenu);
    };
  }, [handleCloseActionMenu]);

  const classes = classnames(className, styles.ActionMeatballs);

  const positiveWidth = `${width ?? `11rem`}`;
  const actionMenuStyles: CSSProperties = {
    minWidth: positiveWidth,
  };

  return (
    <div className={classes} ref={actionMeatballsRef}>
      <Button
        spacing={ButtonSpacing.Compressed}
        type={ButtonType.Minimal}
        onClick={handleToggleActionMenu}
      >
        <MoreVertical />
      </Button>
      {transitions(
        (style, item) =>
          item && (
            <animated.div
              className={styles.ActionMenu}
              style={{ ...style, ...actionMenuStyles }}
            >
              {children}
            </animated.div>
          )
      )}
    </div>
  );
}
