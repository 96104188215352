import classnames from 'classnames';
import Quill from 'quill';
import MagicUrl from 'quill-magic-url';
import 'quill/dist/quill.snow.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form/dist/types/errors';

import ErrorMessage from 'components/ErrorMessage';

import { urlPattern } from 'constants/regexes';

import styles from './RichTextArea.module.scss';

interface LinkFormat {
  sanitize: (url: string) => string;
}

interface QuillToolbar {
  container: HTMLElement;
}

export const requiredRichTextArea = (
  value: string,
  message: string
): boolean | string => {
  const rawString = value.replace(/(<([^>]+)>)/gi, '');
  return rawString.length > 0 || message;
};

export const maxLengthRichTextArea = (
  value: string,
  maxLength: number,
  message: string
): boolean | string => {
  const rawString = value.replace(/(<([^>]+)>)/gi, '');
  return rawString.length <= maxLength || message;
};

const defaultModules = {
  keyboard: {
    bindings: {
      tab: {
        key: 9,
        handler: function () {
          return true;
        },
      },
    },
  },
  clipboard: {
    matchVisual: false,
  },
  magicUrl: {
    urlRegularExpression: urlPattern,
    globalRegularExpression: urlPattern,
  },
};

interface RichTextAreaProps {
  className?: string;
  id: string;
  label: string;
  placeholder?: string;
  value?: string;
  onChange?: any;
  errors?: FieldError | undefined;
  parentId?: string;
  showRequiredIndicator?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
}

Quill.register('modules/magicUrl', MagicUrl);

const Link = Quill.import('formats/link') as LinkFormat;
Link.sanitize = (url: string) => {
  const trimmedUrl = url.trim();
  if (!trimmedUrl.startsWith('http://') && !trimmedUrl.startsWith('https://')) {
    return `https://${trimmedUrl}`;
  }
  return trimmedUrl;
};

export default function RichTextArea({
  className,
  label,
  placeholder,
  value,
  onChange,
  errors,
  showRequiredIndicator = false,
  disabled = false,
  hideLabel = false,
}: RichTextAreaProps): JSX.Element {
  const [quill, setQuill] = useState<Quill | null>(null);
  const quillRef = useRef<HTMLDivElement>(null);

  const renderRequiredIndicator = (): JSX.Element | null =>
    showRequiredIndicator ? (
      <span className={styles.RequiredIndicator}>(Required)</span>
    ) : null;

  const labelClasses: string = classnames(styles.RichTextArea, {
    [styles.Disabled]: disabled,
    [styles.MarginZero]: hideLabel,
  });

  const inputClasses: string = classnames(styles.RichTextAreaInput, {
    [styles.ErroredRichTextAreaInput]: errors,
    [styles.HeightWithLabel]: !hideLabel,
  });

  const richTextAreaStyles = {
    ...(disabled && { backgroundColor: 'rgba(0, 0, 0, 0.05)' }),
  };

  useEffect(() => {
    if (quillRef.current && !quill) {
      const toolbarConfig = [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
      ];

      const formats = ['header', 'bold', 'italic', 'underline', 'list', 'link'];

      const modules = {
        ...defaultModules,
        toolbar: toolbarConfig,
      };
      const quillInstance = new Quill(quillRef.current, {
        modules,
        formats,
        placeholder,
        theme: 'snow',
        bounds: quillRef.current,
      });

      setQuill(quillInstance);
    }
  }, [quillRef, quill, value, placeholder]);

  useEffect(() => {
    if (quill && onChange) {
      quill.on('text-change', () => {
        const content = quill.getSemanticHTML();
        onChange && onChange(content);
      });
    }
  }, [onChange, quill]);

  useEffect(() => {
    if (quill && quill.getSemanticHTML() !== value) {
      if (value) {
        quill.clipboard.dangerouslyPasteHTML(value);
        quill.blur();
      } else {
        quill.clipboard.dangerouslyPasteHTML('<p><br></p>');
        quill.blur();
        onChange && onChange(quill.root.innerHTML);
      }
    }
  }, [quill, value, onChange]);

  useEffect(() => {
    if (quill) {
      disabled ? quill.disable() : quill.enable();

      const toolbar = quill.getModule('toolbar') as QuillToolbar;

      const clickableElements =
        toolbar.container.querySelectorAll('button, .ql-picker');
      clickableElements.forEach((el: Element) => {
        if (el.classList.contains('ql-picker')) {
          if (el.querySelector('.ql-picker-label')) {
            const label = el.querySelector('.ql-picker-label') as HTMLElement;
            label.style.cursor = disabled ? 'default' : 'pointer';
            label.style.display = disabled ? 'none' : 'inline-block';
          }
        } else {
          const button = el as HTMLButtonElement;
          button.disabled = disabled;
          button.style.cursor = disabled ? 'default' : 'pointer';
          button.style.display = disabled ? 'none' : 'inline-block';
        }
      });

      quill.root.style.cursor = disabled ? 'default' : 'text';
    }
  }, [disabled, quill]);

  const handleLabelClick = useCallback(() => {
    if (quill && !disabled) {
      quill.focus();
    }
  }, [quill, disabled]);

  return (
    <div className={className}>
      <label className={labelClasses}>
        <div
          className={classnames(styles.LabelContent, {
            [styles.HiddenLabel]: hideLabel,
          })}
          onClick={handleLabelClick}
        >
          {label} {renderRequiredIndicator()}
        </div>
      </label>
      <div className={inputClasses} style={richTextAreaStyles}>
        <div style={richTextAreaStyles} ref={quillRef} />
      </div>
      <ErrorMessage message={errors?.message} />
    </div>
  );
}
