import { gql } from '@apollo/client';

export const EventInfo = gql`
  fragment EventInfo on events {
    id
    name
    start
    end
    eventType
    studentEvents {
      id
      student {
        id
        name
        picture
      }
    }
  }
`;

export const StudentEventInfo = gql`
  fragment StudentEventInfo on student_events {
    id
    event {
      id
      start
      end
    }
  }
`;

export const CalendarVacation = gql`
  fragment CalendarVacation on events {
    id
    name
    studentEvents {
      id
      student {
        id
        name
      }
    }
  }
`;
