import { useApolloClient } from '@apollo/client';
import classnames from 'classnames';
import { formatISO, parseISO } from 'date-fns';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Accordion from 'components/Accordion';
import useAssignmentInfoGqlOps from 'components/AssignmentInfo/useAssignmentInfoGqlOps';
import AssignmentInfoForm, {
  AssignmentInfoFormData,
  defaultAssignmentInfoFormValues,
} from 'components/AssignmentInfoForm';
import { ButtonColor, ButtonType } from 'components/Button';
import Checkbox from 'components/Checkbox';
import { showToast } from 'components/Toast';

import UserRole from 'enums/UserRole';

import { GET_EVENTS_INFO } from 'graphql/queries/getEventsInfo';
import { GET_HOME_STUDENT_ASSIGNMENTS } from 'graphql/queries/getHomeStudentAssignments';
import {
  Assignments_Insert_Input,
  GetEventsInfoQuery,
  StudentAssignmentToRescheduleFragment,
} from 'graphql/types/generated';

import {
  cancelBulkUpdatingAssignments,
  getBulkSelectedAssignmentIds,
  getBulkSelectedAssignments,
  getBulkUpdatingFields,
  getBulkUpdatingFieldsMap,
  getStudentCourses,
  selectBulkUpdateableField,
} from 'redux/planning';
import { useAppDispatch } from 'redux/store';
import { getUserRole } from 'redux/user';

import {
  getDateRangesFromEvents,
  getVacationsForStudentFromEvents,
} from 'utils/events';
import { rescheduleStudentAssignments } from 'utils/studentAssignment';

import styles from './BulkUpdateAssignmentInfo.module.scss';

interface BulkUpdateAssignmentInfoProps {
  courseId: string;
}

type BulkUpdateableFieldNames =
  | 'week'
  | 'day'
  | 'assignmentTypeOption'
  | 'graded'
  | 'assignmentName'
  | 'duration'
  | 'description';

const bulkUpdateableFields: Record<string, string> = {
  week: 'Week',
  day: 'Day',
  assignmentTypeOption: 'Type',
  graded: 'Graded',
  assignmentName: 'Name',
  duration: 'Due In',
  description: 'Description',
  teachersNote: "Teacher's Notes",
};

export default function BulkUpdateAssignmentInfo({
  courseId,
}: BulkUpdateAssignmentInfoProps): JSX.Element {
  const apolloClient = useApolloClient();
  const userRole = useSelector(getUserRole);

  const dispatch = useAppDispatch();

  const [isFieldSelectionAccordionOpen, setIsFieldSelectionAccordionOpen] =
    useState<boolean>(true);

  const bulkUpdatingFields = useSelector(getBulkUpdatingFields);
  const bulkUpdatingFieldsMap = useSelector(getBulkUpdatingFieldsMap);
  const bulkSelectedAssignmentIds = useSelector(getBulkSelectedAssignmentIds);
  const bulkSelectedAssignments = useSelector(getBulkSelectedAssignments);
  const bulkSelectedUnscheduledAssignments = bulkSelectedAssignments?.filter(
    (assignment) => assignment.week === null || assignment.day === null
  );
  const studentCourses = useSelector(getStudentCourses);
  const studentIds = studentCourses?.flatMap(
    (studentCourse) => studentCourse.student.id
  );

  const { anyLoading, bulkUpdateAssignments, upsertStudentAssignmentDates } =
    useAssignmentInfoGqlOps();

  const formMethods = useForm<AssignmentInfoFormData>({
    defaultValues: defaultAssignmentInfoFormValues,
  });
  const { resetField, handleSubmit } = formMethods;

  const areBulkUpdatingFieldsValid = () => {
    return bulkSelectedUnscheduledAssignments?.length
      ? (bulkUpdatingFieldsMap['week'] && bulkUpdatingFieldsMap['day']) ||
          (!bulkUpdatingFieldsMap['week'] && !bulkUpdatingFieldsMap['day'])
      : true;
  };

  const handleToggleAccordion = () => {
    setIsFieldSelectionAccordionOpen(!isFieldSelectionAccordionOpen);
  };

  const handleSelectField = (field: string) => () => {
    const isSelected = bulkUpdatingFieldsMap[field];

    // reset the field when deselecting so it doesn't show up when it's disabled
    if (isSelected) {
      resetField(field as BulkUpdateableFieldNames);
    }

    dispatch(selectBulkUpdateableField(field));
  };

  const getAssignmentsToUpdate = (
    data: AssignmentInfoFormData
  ): Assignments_Insert_Input[] =>
    bulkSelectedAssignments?.map((assignment) => {
      const description =
        (bulkUpdatingFieldsMap['description'] && data.description) ||
        assignment.description;
      const teachersNote =
        (bulkUpdatingFieldsMap['teachersNote'] && data.teachersNote) ||
        assignment.teachersNote;
      const duration = bulkUpdatingFieldsMap['duration']
        ? data.duration || 0
        : assignment.duration;

      const updatedAssignment: Assignments_Insert_Input = {
        id: assignment.id,
        courseId,
        name:
          (bulkUpdatingFieldsMap['assignmentName'] && data.assignmentName) ||
          assignment.name,
        description:
          // Quill inserts this string when a user adds text and then deletes it.
          // The text box will look empty but will contain just this string.
          description !== '<p><br></p>' ? description : null,
        teachersNote: teachersNote !== '<p><br></p>' ? teachersNote : null,
        graded: bulkUpdatingFieldsMap['graded']
          ? data.graded
          : assignment.graded,
        customAssignmentTypeId: bulkUpdatingFieldsMap['assignmentTypeOption']
          ? data.assignmentTypeOption.value
          : assignment.customAssignmentType?.id,
        duration,
        day: bulkUpdatingFieldsMap['day'] ? data.day?.value : assignment.day,
        week: bulkUpdatingFieldsMap['week']
          ? data.week?.value
          : assignment.week,
      };

      return updatedAssignment;
    }) || [];

  const handleUpdateAssignments = async (data: AssignmentInfoFormData) => {
    let wasUpdateSuccessful = true;

    const assignmentsToUpdate = getAssignmentsToUpdate(data);
    const updatedAssignmentIds = assignmentsToUpdate.map(
      (assignment) => assignment.id
    );

    const bulkUpdateAssignmentsResult = await bulkUpdateAssignments.call({
      variables: {
        assignments: assignmentsToUpdate,
      },
    });

    const wasBulkUpdateSuccessful =
      !bulkUpdateAssignmentsResult?.errors?.length &&
      bulkUpdateAssignmentsResult?.data?.insert_assignments?.returning?.length;

    if (!wasBulkUpdateSuccessful) wasUpdateSuccessful = false;

    const shouldUpdateStudentAssignments =
      wasBulkUpdateSuccessful &&
      (bulkUpdatingFieldsMap['duration'] ||
        bulkUpdatingFieldsMap['day'] ||
        bulkUpdatingFieldsMap['week']);

    if (shouldUpdateStudentAssignments) {
      const { data: getEventsInfoData, error: getEventsInfoError } =
        await apolloClient.query<GetEventsInfoQuery>({
          query: GET_EVENTS_INFO,
          variables: { studentIds },
          fetchPolicy: 'no-cache',
        });
      const events = getEventsInfoData?.events || [];

      if (getEventsInfoError) {
        showToast(
          'getStudentEvents',
          'Error fetching vacations',
          'There as an issue fetching vacations for your students to bulk update assignments. Please try saving again.',
          'error'
        );

        wasUpdateSuccessful = false;
      }

      const rescheduledStudentAssignments = studentCourses?.flatMap(
        (studentCourse) => {
          const studentAssignmentsToReschedule =
            studentCourse.studentAssignments
              .filter((studentAssignment) =>
                updatedAssignmentIds.includes(studentAssignment.assignment.id)
              )
              .map((studentAssignment) => ({
                ...studentAssignment,
                assignment: {
                  ...studentAssignment.assignment,
                  duration: bulkUpdatingFieldsMap['duration']
                    ? data.duration || 0
                    : studentAssignment.assignment.duration,
                  day: bulkUpdatingFieldsMap['day']
                    ? data.day?.value
                    : studentAssignment.assignment.day,
                  week: bulkUpdatingFieldsMap['week']
                    ? data.week?.value
                    : studentAssignment.assignment.week,
                },
              }));

          const studentEvents =
            events?.filter((event) =>
              event.studentEvents.find(
                (studentEvent) =>
                  studentEvent.student.id === studentCourse.student.id
              )
            ) || [];

          const currentVacationsForStudent = getDateRangesFromEvents(
            getVacationsForStudentFromEvents(
              studentEvents,
              studentCourse.student.id
            )
          );

          return rescheduleStudentAssignments(
            parseISO(studentCourse.start),
            studentAssignmentsToReschedule,
            studentCourse.daysOfTheWeek as Record<string, boolean>,
            currentVacationsForStudent,
            (studentCourse.courseDayOffsets ?? {}) as Record<number, number>
          );
        }
      ) as StudentAssignmentToRescheduleFragment[];

      const studentAssignmentsForUpsert =
        rescheduledStudentAssignments?.map(
          ({ __typename, assignment, ...studentAssignment }) =>
            studentAssignment
        ) || [];

      const { errors: upsertStudentAssignmentDatesError } =
        await upsertStudentAssignmentDates.call({
          variables: { studentAssignments: studentAssignmentsForUpsert },
          refetchQueries: [
            {
              query: GET_HOME_STUDENT_ASSIGNMENTS,
              variables: {
                day: formatISO(new Date(), { representation: 'date' }),
                isStudent: userRole === UserRole.Student,
              },
            },
          ],
        });

      if (upsertStudentAssignmentDatesError) wasUpdateSuccessful = false;
    }

    if (wasUpdateSuccessful) {
      dispatch(cancelBulkUpdatingAssignments());
    }
  };

  const renderFieldSelectionAccordion = () => {
    const title = `Select Fields to Update${
      isFieldSelectionAccordionOpen
        ? ''
        : ` (${bulkUpdatingFields.length} selected)`
    }`;

    return (
      <Accordion
        className={styles.FieldSelectionAccordion}
        title={title}
        isOpen={isFieldSelectionAccordionOpen}
        onToggle={handleToggleAccordion}
      >
        <div className={styles.FieldSelectionAccordionContent}>
          <div className={styles.BulkUpdateableFieldCheckboxes}>
            {Object.keys(bulkUpdateableFields).map((field) => {
              const fieldId = `bulk-update-${field}`;
              return (
                <Checkbox
                  key={fieldId}
                  id={fieldId}
                  name={fieldId}
                  className={styles.BulkUpdateableField}
                  onChange={handleSelectField(field)}
                  isSelected={bulkUpdatingFieldsMap[field] || false}
                >
                  <span>{bulkUpdateableFields[field]}</span>
                </Checkbox>
              );
            })}
          </div>
        </div>
      </Accordion>
    );
  };

  const renderUnscheduledNote = () => {
    if (areBulkUpdatingFieldsValid()) {
      return;
    }

    return (
      <Fragment>
        You've selected <b>{`${bulkSelectedUnscheduledAssignments?.length}`}</b>{' '}
        unscheduled assignment. You must change week and day together when
        updating unscheduled assignments.
      </Fragment>
    );
  };

  const buttonConfigs = {
    secondary: {
      title: `Cancel`,
      onClick: () => dispatch(cancelBulkUpdatingAssignments()),
      color: ButtonColor.Normal,
      type: ButtonType.Secondary,
    },
    primary: {
      title: `Update ${bulkSelectedAssignmentIds.length} Assignments`,
      isSubmit: true,
      color: ButtonColor.Normal,
      type: ButtonType.Primary,
      disabled: !areBulkUpdatingFieldsValid() || !bulkUpdatingFields.length,
    },
  };

  const scrollableContentClasses = classnames(styles.ScrollableContent, {
    [styles.CollapsedFieldSelection]: !isFieldSelectionAccordionOpen,
    [styles.ExpandedFieldSelection]: isFieldSelectionAccordionOpen,
  });

  return (
    <div className={styles.BulkUpdateAssignmentInfo}>
      {renderFieldSelectionAccordion()}
      <hr />
      <AssignmentInfoForm
        onSubmit={handleSubmit(handleUpdateAssignments)}
        formMethods={formMethods}
        enabledFields={bulkUpdatingFieldsMap}
        isLoading={anyLoading}
        buttonConfigs={buttonConfigs}
        contentClassName={scrollableContentClasses}
        formNote={renderUnscheduledNote()}
      />
    </div>
  );
}
