import { DropdownOption } from 'components/Dropdown';

export const courseStatusOptions: DropdownOption<string>[] = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Archived',
    value: 'Archived',
  },
];
