import { MouseEventHandler } from 'react';

interface TabProps {
  title: string;
  children: JSX.Element;
  onClick?: MouseEventHandler;
}

export default function Tab({ children }: TabProps): JSX.Element {
  return children;
}
