import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { showToast } from 'components/Toast';

import { deleteAssignmentsUpdate } from 'graphql/mutations/deleteAssignments';
import {
  useDeleteAssignmentsMutation,
  useGetPlanningCourseForParrotLazyQuery,
  useGetPlanningCourseLazyQuery,
} from 'graphql/types/generated';

import { getIsParrotUser } from 'redux/user';

import { CoursePlanPageParams } from './CoursePlanPage';

export default function useCoursePlanPageGqlOps(
  parrotRequestId: string | null
) {
  const { courseId } =
    useParams<CoursePlanPageParams>() as CoursePlanPageParams;

  const isParrotUser = useSelector(getIsParrotUser);

  const [deleteAssignments, { loading: deleteAssignmentsLoading }] =
    useDeleteAssignmentsMutation({
      update: deleteAssignmentsUpdate,
      onError: () =>
        showToast(
          'deleteAssignmentsError',
          'Error deleting assignments',
          'There was an issue deleting your assignments. Try deleting again.',
          'error'
        ),
    });

  const [
    getPlanningCourse,
    {
      data: planningCourseData,
      error: planningCourseError,
      loading: planningCourseLoading,
    },
  ] = useGetPlanningCourseLazyQuery();

  const [
    getPlanningCourseForParrot,
    {
      data: planningCourseForParrotData,
      error: planningCourseForParrotError,
      loading: planningCourseForParrotLoading,
    },
  ] = useGetPlanningCourseForParrotLazyQuery();

  useEffect(() => {
    (planningCourseError || planningCourseForParrotError) &&
      showToast(
        'planningAssignmentListError',
        'Error getting planning data',
        `There was an issue retrieving your planning data. Try refreshing the page.`,
        'error'
      );
  }, [planningCourseError, planningCourseForParrotError]);

  useEffect(() => {
    if (isParrotUser) {
      parrotRequestId &&
        getPlanningCourseForParrot({
          variables: {
            courseId,
            parrotRequestId,
          },
        });
    } else {
      getPlanningCourse({
        variables: {
          courseId,
        },
      });
    }
  }, [
    isParrotUser,
    courseId,
    parrotRequestId,
    getPlanningCourseForParrot,
    getPlanningCourse,
  ]);

  return {
    getPlanningCourse: {
      data: isParrotUser ? planningCourseForParrotData : planningCourseData,
      loading: isParrotUser
        ? planningCourseForParrotLoading
        : planningCourseLoading,
    },
    deleteAssignments: {
      call: deleteAssignments,
      loading: deleteAssignmentsLoading,
    },
  };
}
