import { MutationUpdaterFn, gql } from '@apollo/client';
import { produce } from 'immer';

import { GET_STORAGE_USAGE } from 'graphql/queries/getStorageUsage';
import {
  CreateAttachmentsMutation,
  GetStorageUsageQuery,
} from 'graphql/types/generated';

export const CREATE_ATTACHMENTS = gql`
  mutation CreateAttachments($attachments: [files_insert_input!]!) {
    attachments: insert_files(objects: $attachments) {
      returning {
        id
        url
        size
        assignmentId
      }
    }
  }
`;

export const createAttachmentsUpdate =
  (assignment: any): MutationUpdaterFn<CreateAttachmentsMutation> =>
  (cache, { data: newAttachmentsData }) => {
    const newAttachments = newAttachmentsData?.attachments?.returning ?? [];

    cache.modify({
      id: cache.identify(assignment),
      fields: {
        attachments(existingAttachmentsRefs = []) {
          const newAttachmentsRefs = newAttachments.map((attachment) => {
            return cache.writeFragment({
              data: attachment,
              fragment: gql`
                fragment NewAttachment on files {
                  id
                  url
                  assignmentId
                }
              `,
            });
          });

          return [...existingAttachmentsRefs, ...newAttachmentsRefs];
        },
      },
    });

    const storageUsageData = cache.readQuery<GetStorageUsageQuery>({
      query: GET_STORAGE_USAGE,
    });

    if (storageUsageData) {
      const newFilesSize = newAttachments.reduce(
        (acc, attachment) => acc + attachment.size,
        0
      );

      cache.writeQuery<GetStorageUsageQuery>({
        query: GET_STORAGE_USAGE,
        data: produce(storageUsageData, (dataDraft) => {
          if (dataDraft.files.aggregate?.sum?.size) {
            dataDraft.files.aggregate.sum.size += newFilesSize;
          }
        }),
      });
    }
  };
