import { DropdownOption } from 'components/Dropdown';

import CalculatedStudentAssignmentStatus from 'enums/CalculatedStudentAssignmentStatus';
import DayOfTheWeek from 'enums/DayOfTheWeek';
import UserRole from 'enums/UserRole';

import {
  AssignmentTypeWeightInfoFragment,
  CalendarCourseFragment,
  CalendarVacationFragment,
  CourseCardInfoFragment,
  CourseOptionFragment,
  Courses,
  HomeStudentAssignmentFragment,
  Plan_Tier_Enum,
  PlanningAssignmentFragment,
  PlanningStudentCourseFragment,
  StudentOptionFragment,
  Student_Assignment_Status_Enum,
  UserSettingsFragment,
} from 'graphql/types/generated';

export type PlanningCourse = Pick<
  Courses,
  'id' | 'name' | 'color' | 'numberOfDaysPerWeek' | 'numberOfWeeks'
> | null;

export enum PlanningTabIndex {
  DayWeek,
  Unscheduled,
  All,
}

export interface PlanningState {
  course: PlanningCourse;
  assignments: PlanningAssignmentFragment[] | null;
  studentCourses: PlanningStudentCourseFragment[] | null;
  assignmentTypeWeights: AssignmentTypeWeightInfoFragment[];
  selectedAssignmentId: string | null;
  isCreatingNewAssignment: boolean;
  keepAssignmentSelected: boolean;
  activeTabIndex: PlanningTabIndex;
  currentDay: number;
  currentWeek: number;
  searchPattern: string;
  isSidebarSlideMenuOpen: boolean;
  parrotRequestId: string | null;
  bulkSelectedAssignmentsMap: Record<string, boolean>;
  isBulkUpdatingAssignments: boolean;
  bulkUpdatingFieldsMap: Record<string, boolean>;
  repeatingDaysMap: Record<string, boolean>;
  isCreatingRepeatingAssignments: boolean;
  shouldScrollAssignmentList: boolean;
}

export type CalendarData = {
  [K in DayOfTheWeek]?: {
    date: string;
    calendarCourses: CalendarCourseFragment[];
    calendarVacations: CalendarVacationFragment[];
  };
};

export interface CalendarState {
  data: CalendarData;
}

export type CoursesView = 'grid' | 'list';
export type CoursesPageType = 'teacher' | 'student' | 'organization';

export interface FilterableCourse
  extends Omit<CourseCardInfoFragment, '__typename'> {
  gradeYearsLabels: string[];
  studentNames: string[];
}

export interface CoursesFiltersState {
  currentStatus?: DropdownOption<string>;
  currentEnrolledStudents?: DropdownOption<StudentOptionFragment>[];
  currentSubjects?: DropdownOption<string>[];
  currentGradeYears?: DropdownOption<string>[];
}

export interface BaseCoursesState {
  view: CoursesView;
  courses: FilterableCourse[];
  filters: CoursesFiltersState;
  searchQuery: string;
}

export interface CoursesState {
  teacher: BaseCoursesState;
  student: BaseCoursesState;
  organization: BaseCoursesState;
}

export interface CoursesPayload<T> {
  type: CoursesPageType;
  value: T;
}

export default interface HomeFiltersState {
  students: DropdownOption<StudentOptionFragment>[];
  courses: DropdownOption<CourseOptionFragment>[];
  subjects: DropdownOption<string>[];
  assignmentTypes: DropdownOption<string>[];
  assignmentStatuses: DropdownOption<
    Student_Assignment_Status_Enum | CalculatedStudentAssignmentStatus
  >[];
  hideCompleted: boolean;
}

export interface HomeState {
  filters: HomeFiltersState;
  homeStudentAssignments: HomeStudentAssignmentFragment[] | null;
  selectedHomeStudentAssignmentId: string | null;
  isDetailsSlideMenuOpen: boolean;
  isWelcomeToSyllabirdModalOpen: boolean;
}

export interface UserState {
  id: string;
  email: string;
  subscriptionStatus: string | null;
  planTier: Plan_Tier_Enum | null;
  role: UserRole | null;
  sessionId: string;
  settings: UserSettingsFragment;
  isIdentified: boolean;
  organizationId: string | null;
  organizationName: string;
}

export interface RootState {
  home: HomeState;
  calendar: CalendarState;
  user: UserState;
  planning: PlanningState;
  courses: CoursesState;
}
