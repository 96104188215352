import styles from './Logo.module.scss';

interface LogoProps {
  className?: string;
  showName?: boolean;
}

export default function Logo({
  className,
  showName = false,
}: LogoProps): JSX.Element {
  return showName ? (
    <svg
      className={className}
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 285.05 70.55"
    >
      <title>Syllabird Logo</title>
      <path
        className={styles.Text}
        d="M94.18,50.81c-6.4,0-10.9-3.5-11.2-8.5h4.7C87.93,45,90.28,47,94.13,47c3.6,0,5.6-1.8,5.6-4.05,0-6.1-16.2-2.55-16.2-12.75,0-4.2,4-7.65,10-7.65s10,3.25,10.35,8.6H99.38c-.2-2.75-2.3-4.75-5.95-4.75-3.35,0-5.35,1.6-5.35,3.8,0,6.5,15.9,2.95,16.1,12.75C104.18,47.41,100.23,50.81,94.18,50.81Z"
        transform="translate(-2.93 -1.4)"
      />
      <path
        className={styles.Text}
        d="M129.48,23h4.7l-16.5,40.3H113l5.4-13.2-11-27.1h5l8.6,22.2Z"
        transform="translate(-2.93 -1.4)"
      />
      <path
        className={styles.Text}
        d="M138.73,13.35h4.55v37h-4.55Z"
        transform="translate(-2.93 -1.4)"
      />
      <path
        className={styles.Text}
        d="M151,13.35h4.55v37H151Z"
        transform="translate(-2.93 -1.4)"
      />
      <path
        className={styles.Text}
        d="M174.62,22.51A11.4,11.4,0,0,1,184.83,28V23h4.6v27.4h-4.6v-5.1a11.47,11.47,0,0,1-10.26,5.55c-7.3,0-12.95-5.75-12.95-14.25S167.27,22.51,174.62,22.51Zm.9,3.95c-5,0-9.25,3.65-9.25,10.1s4.25,10.25,9.25,10.25,9.31-3.75,9.31-10.2S180.53,26.46,175.52,26.46Z"
        transform="translate(-2.93 -1.4)"
      />
      <path
        className={styles.Text}
        d="M211.92,22.51c7.41,0,13,5.5,13,14.05s-5.65,14.25-13,14.25a11.43,11.43,0,0,1-10.25-5.5v5h-4.55v-37h4.55V28.06A11.61,11.61,0,0,1,211.92,22.51ZM211,26.46c-4.95,0-9.3,3.8-9.3,10.15s4.35,10.2,9.3,10.2,9.31-3.8,9.31-10.25S216,26.46,211,26.46Z"
        transform="translate(-2.93 -1.4)"
      />
      <path
        className={styles.Text}
        d="M230.17,15.41a3.05,3.05,0,1,1,3.1,3.1A3.05,3.05,0,0,1,230.17,15.41Zm.75,7.55h4.55v27.4h-4.55Z"
        transform="translate(-2.93 -1.4)"
      />
      <path
        className={styles.Text}
        d="M247.77,50.36h-4.55V23h4.55v4.45c1.55-3.05,4.5-4.95,8.85-4.95v4.7h-1.2c-4.35,0-7.65,1.95-7.65,8.3Z"
        transform="translate(-2.93 -1.4)"
      />
      <path
        className={styles.Text}
        d="M273.22,22.51a12,12,0,0,1,10.15,5.35V13.35H288v37h-4.6V45.21a11.25,11.25,0,0,1-10.2,5.6c-7.35,0-13-5.75-13-14.25S265.82,22.51,273.22,22.51Zm.85,3.95c-5,0-9.25,3.65-9.25,10.1s4.25,10.25,9.25,10.25,9.3-3.75,9.3-10.2S279.07,26.46,274.07,26.46Z"
        transform="translate(-2.93 -1.4)"
      />
      <path
        className={styles.BirdPrimary}
        d="M6.76,1.42,36.82,6.78a1.79,1.79,0,0,1,1.31,1L44.9,23.12l17.86.37,3.18,8.74a1,1,0,0,1-1,1.36l-6-.49L54.39,44.54,22.68,57.43l1.46,9.64a1,1,0,0,1-.82,1.15L4.14,71.94A1,1,0,0,1,3,70.56L19,30.45,5.66,2.87A1,1,0,0,1,6.76,1.42Z"
        transform="translate(-2.93 -1.4)"
      />
      <polygon
        className={styles.BirdShadow}
        points="19.75 56.03 16.03 29.04 24.73 54.01 19.75 56.03"
      />
      <polygon
        className={styles.BirdShadow}
        points="51.46 43.13 41.98 21.71 46.72 21.81 51.46 43.13"
      />
      <polygon
        className={styles.BirdShadow}
        points="59.83 22.08 56.01 31.7 58.77 31.92 59.83 22.08"
      />
    </svg>
  ) : (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 446.99 500"
    >
      <title>Syllabird Logo</title>
      <g id="Layer_6" data-name="Layer 6">
        <path
          className={styles.BirdPrimary}
          d="M57.56.12l213,38a12.63,12.63,0,0,1,9.32,7.31l48,108.46,126.53,2.62,22.52,61.94a7.22,7.22,0,0,1-7.38,9.67l-42.24-3.49-32.24,81.07-224.7,91.39,10.34,68.27a7.22,7.22,0,0,1-5.77,8.18L39,499.86a7.22,7.22,0,0,1-8.09-9.76L144,205.81,49.78,10.37A7.22,7.22,0,0,1,57.56.12Z"
          transform="translate(-30.39 0)"
        />
      </g>
      <g id="Layer_3" data-name="Layer 3">
        <polygon
          className={styles.BirdShadow}
          points="139.99 397.07 113.6 205.81 175.24 382.73 139.99 397.07"
        />
      </g>
      <g id="Layer_7" data-name="Layer 7">
        <polygon
          className={styles.BirdShadow}
          points="364.69 305.68 297.5 153.87 331.09 154.56 364.69 305.68"
        />
      </g>
      <g id="Layer_5" data-name="Layer 5">
        <polygon
          className={styles.BirdShadow}
          points="424.02 156.49 396.93 224.61 416.51 226.23 424.02 156.49"
        />
      </g>
    </svg>
  );
}
