import { gql } from '@apollo/client';

export const CommentInfo = gql`
  fragment CommentInfo on comments {
    id
    content
    created
    studentAccount {
      id
      name
      picture
    }
    teacher {
      id
      name
      picture
    }
    studentAssignment {
      id
    }
  }
`;
