import { useCallback, useEffect } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { showToast } from 'components/Toast';

export default function useUpdateReloadPrompt() {
  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(_) {
      console.log('Service Worker registered');
    },
    onRegisterError(error) {
      console.log('Service Worker registration error: ', error);
    },
  });

  const reloadAndUpdate = useCallback(() => {
    updateServiceWorker(true);
  }, [updateServiceWorker]);

  useEffect(() => {
    if (needRefresh) {
      showToast(
        'updateReloadPrompt',
        'New Updates Available',
        'Please click reload to get the newest updates for Syllabird',
        'info',
        0,
        reloadAndUpdate,
        'Reload'
      );
      setNeedRefresh(false);
    }
  }, [needRefresh, reloadAndUpdate, setNeedRefresh]);
}
