import classnames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';

import Button, { ButtonSpacing, ButtonType } from 'components/Button';

import styles from './Accordion.module.scss';

interface AccordionProps {
  className?: string;
  children: JSX.Element[] | JSX.Element;
  title: JSX.Element[] | JSX.Element | string;
  defaultToOpen?: boolean;
  isOpen?: boolean | null;
  onToggle?: (() => void) | null;
}

export default function Accordion({
  className,
  title,
  children,
  defaultToOpen = false,
  isOpen = null,
  onToggle = null,
}: AccordionProps): JSX.Element {
  const collapsibleContentEl = useRef<HTMLDivElement>(null);

  const [isOpenInternal, setIsOpenInternal] = useState<boolean>(
    Boolean(defaultToOpen)
  );

  const isControlled = isOpen !== null && onToggle !== null;
  const isOpenFinal = isControlled ? isOpen : isOpenInternal;

  const [currentHeight, setCurrentHeight] = useState<string | null>(null);

  useEffect(() => {
    if (isOpenFinal && collapsibleContentEl?.current?.children) {
      const heightOfChildren = Array.from(
        collapsibleContentEl.current.children
      ).reduce((a, b) => a + b.clientHeight, 0);

      setCurrentHeight(`${heightOfChildren}px`);
    } else {
      setCurrentHeight('0px');
    }
  }, [isOpenFinal, collapsibleContentEl, children]);

  const accordionClasses = classnames(styles.Accordion, className);
  const accordionHeaderClasses = classnames(
    styles.AccordionHeader,
    'syl-accordion-header'
  );
  const collapsibleContentClasses = classnames(styles.CollapsibleContent, {
    [styles.Collapsed]: !isOpenFinal,
  });

  const toggleAccordion = useCallback(() => {
    !isControlled && setIsOpenInternal(!isOpenInternal);
    isControlled && onToggle && onToggle();
  }, [isControlled, setIsOpenInternal, isOpenInternal, onToggle]);

  return (
    <div className={accordionClasses}>
      <Button
        className={accordionHeaderClasses}
        onClick={toggleAccordion}
        type={ButtonType.Minimal}
        spacing={ButtonSpacing.Compressed}
      >
        <span>{title}</span>
        <span className={styles.CollapsedIndicator}>
          {isOpenFinal ? <ChevronUp /> : <ChevronDown />}
        </span>
      </Button>
      <div
        className={collapsibleContentClasses}
        style={{ height: `${currentHeight}` }}
        ref={collapsibleContentEl}
      >
        {children}
      </div>
    </div>
  );
}
