import classnames from 'classnames';
import { MouseEventHandler } from 'react';

import styles from './Card.module.scss';

interface CardProps {
  id?: string;
  children: (JSX.Element | null)[] | JSX.Element | null;
  className?: string;
  onClick?: MouseEventHandler;
  style?: React.CSSProperties;
}

export default function Card({
  id,
  children,
  className,
  onClick,
  style,
}: CardProps): JSX.Element {
  const classes = classnames(className, styles.Card);
  return (
    <div id={id} className={classes} onClick={onClick} style={style}>
      {children}
    </div>
  );
}
