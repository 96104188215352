import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { DragDrop, StatusBar } from '@uppy/react';
import '@uppy/status-bar/dist/style.css';
import classnames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { showToast } from 'components/Toast';

import { quotaLimits } from 'constants/quotaLimits';

import UserRole from 'enums/UserRole';

import {
  useGetCustomerInfoByStudentLazyQuery,
  useGetCustomerInfoLazyQuery,
  useGetStorageUsageQuery,
} from 'graphql/types/generated';

import useUppy, {
  DocumentFileExtensions,
  ImageFileExtensions,
} from 'hooks/useUppy';

import { getUserId, getUserRole } from 'redux/user';

import styles from './FileUploader.module.scss';

export interface UploadedFile {
  url: string;
  size: number;
}

interface FileUploaderProps {
  className?: string;
  name: string;
  height: string;
  width: string;
  note: string;
  maxNumberOfFiles: number;
  checkStorageQuota?: boolean;
  onSuccess: (files: UploadedFile[]) => void;
}

export default function FileUploader({
  className,
  name,
  height,
  width,
  note,
  maxNumberOfFiles,
  onSuccess,
  checkStorageQuota = false,
}: FileUploaderProps): JSX.Element {
  const [error, setError] = useState<string>('');

  const userRole = useSelector(getUserRole);
  const userId = useSelector(getUserId);

  const [getCustomerInfo, { data: customerInfoData }] =
    useGetCustomerInfoLazyQuery();
  const [getCustomerInfoByStudent, { data: customerInfoDataByStudent }] =
    useGetCustomerInfoByStudentLazyQuery();
  const customer =
    customerInfoData?.teacher?.customer ||
    customerInfoDataByStudent?.student?.teachers.length
      ? customerInfoDataByStudent?.student?.teachers[0].teacher.customer
      : null;
  const planTier = customer?.planTier;
  const storageLimit = planTier ? quotaLimits[planTier].fileStorage : null;

  const { data: storageUsageData } = useGetStorageUsageQuery();
  const storageUsed = storageUsageData?.files.aggregate?.sum?.size;

  const uppy = useUppy(name, true, {
    restrictions: {
      maxNumberOfFiles,
      allowedFileTypes: [...ImageFileExtensions, ...DocumentFileExtensions],
    },
  });

  uppy.on('complete', (result) => {
    const files = result.successful.map((file) => {
      return {
        // The pathname includes a / at the start
        url: `https:/${new URL(file.response?.uploadURL || '').pathname}`,
        size: file.size,
      };
    });

    onSuccess(files);

    uppy.cancelAll();
    setError('');
  });

  uppy.on('upload-error', (file) => {
    showToast(
      `upload${file?.name ?? ''}Error`,
      `Error uploading ${file?.name ?? 'file'}`,
      'There was an issue uploading your file. Please wait a few minutes and try again.',
      'error'
    );
  });

  uppy.on('restriction-failed', (_file, error) => {
    setError(error.message);
  });

  useEffect(() => {
    if (userRole === UserRole.Teacher) {
      getCustomerInfo({
        variables: {
          teacherId: userId,
        },
      });
    } else if (userRole === UserRole.Student) {
      getCustomerInfoByStudent({ variables: { studentId: userId } });
    }
  }, [userId, userRole, getCustomerInfo, getCustomerInfoByStudent]);

  const classes = classnames(className, styles.FileUploader);

  const renderFileUploader = () => {
    if (
      checkStorageQuota &&
      storageUsed &&
      storageLimit &&
      storageUsed > storageLimit
    ) {
      return userRole === UserRole.Teacher ? (
        <span className={styles.EmptyState}>
          You've hit your storage quota. You can delete files to clear up space
          or upgrade your Syllabird Plan by heading over to the{' '}
          <Link to="/account">Account Page</Link>.
        </span>
      ) : (
        <span className={styles.EmptyState}>
          You've hit your teacher's storage quota. You can delete files to clear
          up space or your teacher can upgrade their Syllabird Plan.
        </span>
      );
    }

    return maxNumberOfFiles > 0 ? (
      <Fragment>
        <DragDrop
          id={`DragDrop-${uppy.getID()}`}
          uppy={uppy}
          height={height}
          width={width}
          note={note}
        />
        <StatusBar id={`StatusBar-${uppy.getID()}`} uppy={uppy} />
        {error ? <div className={styles.ErrorMessage}>{error}</div> : null}
      </Fragment>
    ) : (
      <span className={styles.EmptyState}>
        You've hit the maximum number of uploads. You can delete a file to
        upload another.
      </span>
    );
  };

  return <div className={classes}>{renderFileUploader()}</div>;
}
