import omitBy from 'lodash/omitBy';
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import UserRole from 'enums/UserRole';

import {
  OrganizationThemeFragment,
  useGetOrganizationThemeForStudentLazyQuery,
  useGetOrganizationThemeForTeacherLazyQuery,
} from 'graphql/types/generated';

import { getUserId, getUserRole } from 'redux/user';

import {
  DEFAULT_COLOR_PALETTE,
  SYLLABIRD_THEME,
  defaultValue,
} from './constants';
import setColors from './setColors';
import setFonts from './setFonts';
import { ColorPalette, Theme, ThemeContextType } from './types';

export const ThemeContext = createContext<ThemeContextType>(defaultValue);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(SYLLABIRD_THEME);
  const [isThemeLoading, setIsThemeLoading] = useState(true);

  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRole);

  const setDefaultTheme = () => {
    setTheme(SYLLABIRD_THEME);
    setColors(SYLLABIRD_THEME);
    setFonts(SYLLABIRD_THEME);
  };

  const processTheme = useCallback(
    (organizationTheme?: OrganizationThemeFragment | null) => {
      if (!organizationTheme) {
        // Skip processing null/undefined organization themes to
        // default to the Syllabird theme.
        setDefaultTheme();
        return;
      }

      const organizationOverrides = omitBy(
        organizationTheme,
        (value) => !value
      );
      const organizationColorPaletteOverrides = omitBy(
        organizationTheme?.colorPalette,
        (value) => !value
      );

      const mergedColorPalette: ColorPalette = {
        ...DEFAULT_COLOR_PALETTE,
        ...organizationColorPaletteOverrides,
      };

      const mergedTheme: Theme = {
        ...SYLLABIRD_THEME,
        ...organizationOverrides,
        colorPalette: mergedColorPalette,
      };

      // Set context value for use in JS files
      setTheme(mergedTheme);

      // Set CSS variables for use in SCSS files
      setColors(mergedTheme);

      // Add necessary font script tags and set font family CSS variable
      setFonts(mergedTheme);
    },
    []
  );

  const [
    getOrganizationThemeForTeacher,
    { data: organizationThemeForTeacherData },
  ] = useGetOrganizationThemeForTeacherLazyQuery({
    onError: () => {
      setDefaultTheme();
      setIsThemeLoading(false);
    },
  });

  const [
    getOrganizationThemeForStudent,
    { data: organizationThemeForStudentData },
  ] = useGetOrganizationThemeForStudentLazyQuery({
    onError: () => {
      setDefaultTheme();
      setIsThemeLoading(false);
    },
  });

  useEffect(() => {
    if (userId && userRole === UserRole.Teacher) {
      getOrganizationThemeForTeacher({ variables: { teacherId: userId } });
    } else if (userId && userRole === UserRole.Student) {
      getOrganizationThemeForStudent({
        variables: { studentAccountId: userId },
      });
    }
  }, [
    userId,
    userRole,
    getOrganizationThemeForTeacher,
    getOrganizationThemeForStudent,
  ]);

  useEffect(() => {
    if (organizationThemeForTeacherData?.teacher) {
      processTheme(
        organizationThemeForTeacherData?.teacher?.organization?.theme
      );
      setIsThemeLoading(false);
    } else if (organizationThemeForStudentData?.studentAccount) {
      processTheme(
        organizationThemeForStudentData?.studentAccount?.student?.teachers?.[0]
          ?.teacher?.organization?.theme
      );
      setIsThemeLoading(false);
    }
  }, [
    organizationThemeForTeacherData,
    organizationThemeForStudentData,
    processTheme,
  ]);

  return (
    <ThemeContext.Provider
      value={{
        loading: isThemeLoading,
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
