import Color from 'enums/Color';

import { BaseTheme, ColorPalette, Theme, ThemeContextType } from './types';

export const HEX_CODE_OPACITY = {
  5: '0D',
  10: '1A',
  15: '26',
  20: '33',
  25: '40',
  50: '80',
  75: 'BF',
} as const;
export type ColorOpacity = keyof typeof HEX_CODE_OPACITY;

export const BASE_THEME: BaseTheme = {
  syllabird: '#617FE9',
  bluejay: '#4BCCDD',
  oriole: '#F2904E',
  flamingo: '#EC5BA6',
  parakeet: '#52B287',
};

export const DEFAULT_COLOR_PALETTE: ColorPalette = {
  [Color.SunsetOrange]: '#ee6352',
  [Color.Clementine]: '#fa8231',
  [Color.Vermilion]: '#ff9770',
  [Color.Sunshine]: '#FFC312',
  [Color.Grapefruit]: '#ff7675',
  [Color.LimeGreen]: '#a7d24b',
  [Color.Clover]: '#61B779',
  [Color.Seafoam]: '#4C9CAD',
  [Color.RobinsEgg]: '#00cec9',
  [Color.BleuDeFrance]: '#3c91e6',
  [Color.Raindrop]: '#566B9E',
  [Color.Blueberry]: '#3B3B98',
  [Color.Liberty]: '#957FEB',
  [Color.Sugarplum]: '#B663C3',
  [Color.PinkRose]: '#e78eef',
  [Color.Berry]: '#B53471',
  [Color.KirbyPink]: '#e15a97',
  [Color.Bubblegum]: '#fd79a8',
  [Color.Dolphin]: '#9FA7C0',
  [Color.BlackBear]: '#535762',
};

export const SYLLABIRD_THEME: Theme = {
  ...BASE_THEME,
  headerBackground: '#FFFFFF',
  headerForeground: '#363636',
  headerForegroundActive: BASE_THEME.syllabird,
  primary: BASE_THEME.syllabird,
  appBackground: '#FAFAFA',
  contentBackground: '#FFFFFF',
  faintBorder: '#F6F6F6',
  mediumBorder: '#E3E3E3',
  elementBorder: '#CECECE',
  colorPalette: DEFAULT_COLOR_PALETTE,
  contentContrast: '#FFFFFF',
  contentPrimary: '#363636',
  contentSecondary: '#808080',
  contentTertiary: '#9B9B9B',
  positive: '#52B287',
  negative: '#EB7878',
  info: BASE_THEME.syllabird,
  vacation: '#FFC312',
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;1,400;1,600&display=swap&display=swap',
  fontFamily: 'Source Sans Pro',
  baseFontSize: 14,
};

export const defaultValue: ThemeContextType = {
  loading: true,
  theme: SYLLABIRD_THEME,
};
