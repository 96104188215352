import { gql } from '@apollo/client';

import { EventInfo } from 'graphql/fragments/event';

// TODO: rename to get student vacations info?
export const GET_EVENTS_INFO = gql`
  query GetEventsInfo($studentIds: [String!]) {
    events(
      where: { studentEvents: { student: { id: { _in: $studentIds } } } }
      order_by: { start: desc }
    ) {
      ...EventInfo
    }
  }
  ${EventInfo}
`;
