import classnames from 'classnames';
import { Info } from 'react-feather';

import styles from './Note.module.scss';

interface NoteProps {
  className?: string;
  icon?: JSX.Element | null;
  children: string | JSX.Element | JSX.Element[];
}

export default function Note({
  className,
  icon = <Info />,
  children,
}: NoteProps): JSX.Element {
  const classes = classnames(className, styles.Note);
  return (
    <div className={classes}>
      {icon}
      <div>{children}</div>
    </div>
  );
}
