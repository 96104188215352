export function generateNDropdownOptions(
  label: string,
  n: number,
  isSuffix: boolean = false
) {
  return Array.from({ length: n }, (_, i) => ({
    label: !isSuffix
      ? `${label} ${i + 1}`
      : `${i + 1} ${i + 1 === 1 ? label : `${label}s`}`,
    value: i + 1,
  }));
}

export function generateNDropdownOptionsFromStart(
  label: string,
  n: number,
  start: number,
  isSuffix: boolean = false
) {
  return Array.from({ length: n }, (_, i) => i + start).map((i) => ({
    label: !isSuffix
      ? `${label} ${i}`
      : `${i} ${i === 1 ? label : `${label}s`}`,
    value: i,
  }));
}

export function generateNDropdownOptionsFromList(
  label: string,
  values: number[],
  isSuffix: boolean = false
) {
  return values.map((value) => ({
    label: !isSuffix
      ? `${label} ${value}`
      : `${value} ${value === 1 ? label : `${label}s`}`,
    value,
  }));
}
