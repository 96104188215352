import { useCallback } from 'react';
import { File, FileText, Image, X } from 'react-feather';

import Button, { ButtonSpacing, ButtonType } from 'components/Button';
import { showToast } from 'components/Toast';

import { deleteFileUpdate } from 'graphql/mutations/deleteFile';
import { Files, useDeleteFileMutation } from 'graphql/types/generated';

import styles from './FileList.module.scss';

interface FileListProps {
  className?: string;
  label: string;
  files?: Pick<Files, 'id' | 'url'>[];
  maxFileNameLength?: number;
  allowDelete?: boolean;
  hideLabel?: boolean;
  onDeleteFile?: ((fileId: string) => void) | null;
}

export default function FileList({
  className,
  label,
  files,
  maxFileNameLength = 25,
  allowDelete = true,
  hideLabel = false,
  onDeleteFile,
}: FileListProps): JSX.Element | null {
  const [deleteFile] = useDeleteFileMutation({
    update: deleteFileUpdate,
    onError: () =>
      showToast(
        'deleteSubmissionError',
        'Error deleting your submission',
        'There was an issue deleting your submission. Try deleting it again.',
        'error'
      ),
  });

  const handleDeleteFile = useCallback(
    (fileId: string) => {
      onDeleteFile
        ? onDeleteFile(fileId)
        : deleteFile({
            variables: {
              fileId,
            },
          });
    },
    [deleteFile, onDeleteFile]
  );

  const renderFileIcon = (fileName: string) => {
    const extension = fileName.toLowerCase().split('.').pop();

    switch (extension) {
      case 'pdf':
      case 'doc':
      case 'docx':
      case 'txt':
        return <FileText className={styles.FileIcon} />;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return <Image className={styles.FileIcon} />;
      default:
        return <File className={styles.FileIcon} />;
    }
  };

  const renderFileName = (fileName: string) => {
    const decodedFileName = decodeURI(fileName);

    if (decodedFileName.length > maxFileNameLength) {
      const halfMaxFileNameLength = (maxFileNameLength - 3) / 2;
      return `${decodedFileName.slice(
        0,
        halfMaxFileNameLength
      )} ... ${decodedFileName.slice(
        decodedFileName.length - halfMaxFileNameLength,
        decodedFileName.length
      )}`;
    }

    return decodedFileName;
  };

  const renderDeleteButton = (fileId: string) => {
    return (
      <Button
        className={styles.DeleteButton}
        spacing={ButtonSpacing.Compressed}
        type={ButtonType.Minimal}
        onClick={() => handleDeleteFile(fileId)}
      >
        <X />
      </Button>
    );
  };

  const renderFile = (file: Pick<Files, 'id' | 'url'>, index: number) => {
    const fileName = file.url.split('/').pop();

    return fileName ? (
      // using index here in the off chance they upload multiple of the same file
      <div
        key={`${label.toLowerCase()}-${fileName}-${index}`}
        className={styles.SubmittedFile}
      >
        {renderFileIcon(fileName)}
        <a href={file?.url} target="_blank" rel="noreferrer">
          {renderFileName(fileName)}
        </a>
        {allowDelete ? renderDeleteButton(file?.id) : null}
      </div>
    ) : null;
  };

  const renderFiles = () => {
    return (
      <div className={styles.Files}>
        {!hideLabel ? <div className={styles.FilesLabel}>{label}</div> : null}
        <div className={styles.SubmittedFiles}>
          {files?.length ? (
            files.map((file, index) => renderFile(file, index))
          ) : (
            <div className={styles.EmptyState}>
              There are no {label.toLowerCase()}
            </div>
          )}
        </div>
      </div>
    );
  };

  if (!files) {
    return null;
  }

  return <div className={className}>{renderFiles()}</div>;
}
