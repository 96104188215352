import { MutationUpdaterFn, gql } from '@apollo/client';

import { DeleteAssignmentsMutation } from 'graphql/types/generated';

export const DELETE_ASSIGNMENTS = gql`
  mutation DeleteAssignments($assignmentIds: [uuid!]) {
    assignments: delete_assignments(where: { id: { _in: $assignmentIds } }) {
      returning {
        id
      }
    }
  }
`;

export const deleteAssignmentsUpdate: MutationUpdaterFn<
  DeleteAssignmentsMutation
> = (cache, { data }) => {
  data?.assignments?.returning.forEach((assignmentId) => {
    cache.evict({ id: cache.identify(assignmentId) });
    cache.gc();
  });
};
