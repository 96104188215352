// This is resolve react and redux-toolkit warnings: https://github.com/reduxjs/redux-toolkit/issues/2008
import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary, setUser } from '@sentry/react';
import AppLoader from 'AppLoader';
import { Suspense, lazy, useEffect, useState } from 'react';
import 'symbol-observable';

import PageLoader from 'components/PageLoader';

import { ThemeProvider } from 'context/Theme';

import useAuthApolloClient from 'hooks/useAuthApolloClient';
import usePostHog from 'hooks/usePostHog';
import useQueryParam from 'hooks/useQueryParam';
import useSentry from 'hooks/useSentry';
import useUpdateReloadPrompt from 'hooks/useUpdateReloadPrompt';

import ErrorPage from 'pages/ErrorPage';

import { useAppDispatch } from 'redux/store';
import { setUserEmail, setUserId, setUserRole } from 'redux/user';

import styles from './App.module.scss';

const LazyApp = lazy(() => import('./LazyApp'));

function App(): JSX.Element | null {
  const [isPseudoLoading, setIsPseudoLoading] = useState<boolean>(true);

  const redirectTo = useQueryParam('redirectTo');
  const organizationCode = useQueryParam('organizationCode');
  const organizationName = useQueryParam('organizationName');

  const client = useAuthApolloClient();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsPseudoLoading(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  useUpdateReloadPrompt();

  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  useSentry();
  usePostHog();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      (async () => {
        const targetUrl = organizationCode
          ? organizationName
            ? `${window.location.pathname}?organizationCode=${organizationCode}&organizationName=${organizationName}`
            : `${window.location.pathname}?organizationCode=${organizationCode}`
          : window.location.pathname;

        await loginWithRedirect({
          authorizationParams:
            redirectTo === 'signup'
              ? {
                  action: 'signup',
                }
              : {},
          appState: {
            targetUrl,
          },
        });
      })();
    }
  }, [
    isLoading,
    isAuthenticated,
    redirectTo,
    organizationCode,
    organizationName,
    loginWithRedirect,
  ]);

  useEffect(() => {
    if (user?.sub && user?.email) {
      setUser({
        id: user.sub,
        email: user.email,
      });

      dispatch(setUserId(user.sub));
      dispatch(setUserEmail(user.email));

      const roles = user['https://app.syllabird.com/roles'];

      if (roles.length) {
        dispatch(setUserRole(roles?.[0]));
      }
    }
  }, [user, getAccessTokenSilently, dispatch]);

  if (isLoading || !isAuthenticated) {
    return null;
  }

  const loadingIndicator = <PageLoader className={styles.LoadingIndicator} />;

  return isPseudoLoading ? (
    loadingIndicator
  ) : (
    <ErrorBoundary fallback={<ErrorPage />}>
      <Suspense fallback={loadingIndicator}>
        <ApolloProvider client={client}>
          <ThemeProvider>
            <AppLoader>
              <LazyApp />
            </AppLoader>
          </ThemeProvider>
        </ApolloProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
