import { Plan_Tier_Enum } from 'graphql/types/generated';

export interface QuotaLimit {
  fileStorage: number | null;
}

export const quotaLimits: Record<Plan_Tier_Enum, QuotaLimit> = {
  TRIAL: {
    // 100MB
    fileStorage: 100_000_000,
  },
  TIER1: {
    // 50GB
    fileStorage: 50_000_000_000,
  },
  TIER2: {
    // 250GB
    fileStorage: 250_000_000_000,
  },
};
