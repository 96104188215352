import classnames from 'classnames';
import { CSSProperties, Suspense, lazy } from 'react';
import { FieldError, FieldErrors } from 'react-hook-form/dist/types/errors';
import {
  GroupBase,
  OnChangeValue,
  Options,
  Props,
  StylesConfig,
} from 'react-select';

import ErrorMessage from 'components/ErrorMessage';

import { useTheme } from 'context/Theme';
import { HEX_CODE_OPACITY } from 'context/Theme/constants';

import styles from './Dropdown.module.scss';

const Select = lazy(() => import('react-select'));
const CreatableSelect = lazy(() => import('react-select/creatable'));

export interface DropdownOption<T> {
  label: string | JSX.Element;
  value: T;
}

interface DropdownProps<T>
  extends Pick<
    Props,
    | 'noOptionsMessage'
    | 'placeholder'
    | 'isClearable'
    | 'isSearchable'
    | 'isMulti'
    | 'isLoading'
    | 'menuPlacement'
    | 'menuPosition'
    | 'menuPortalTarget'
    | 'formatOptionLabel'
  > {
  className?: string;
  id: string;
  name: string;
  options: Options<DropdownOption<T>>;
  label?: string;
  value?: DropdownOption<T> | DropdownOption<T>[] | null;
  onChange?(option: OnChangeValue<unknown, boolean>): void;
  getOptionValue?(option: OnChangeValue<unknown, boolean>): any;
  onCreateOption?: (inputValue: string) => void;
  disabled?: boolean;
  showRequiredIndicator?: boolean;
  errors?: FieldError | FieldErrors<DropdownOption<T>> | undefined;
  hideErrorMessage?: boolean;
  containerStyles?: CSSProperties;
}

export default function Dropdown<T>({
  className,
  id,
  name,
  placeholder,
  options,
  label,
  isClearable = false,
  isSearchable = false,
  isMulti = false,
  isLoading = false,
  value,
  onChange,
  getOptionValue,
  onCreateOption,
  disabled = false,
  showRequiredIndicator = false,
  errors,
  hideErrorMessage = false,
  containerStyles = {},
  noOptionsMessage,
  menuPlacement = 'auto',
  menuPosition = 'fixed',
  menuPortalTarget,
  formatOptionLabel,
}: DropdownProps<T>): JSX.Element {
  const { theme } = useTheme();

  const selectStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
    container: (provided, { isDisabled }) => ({
      ...provided,
      minWidth: '10rem',
      marginTop: label ? '0.5rem' : '0',
      cursor: 'pointer',
      opacity: isDisabled ? 0.5 : 1,
      ...containerStyles,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.elementBorder,
    }),
    valueContainer: (provided) => ({
      ...provided,
      minHeight: '2.5rem',
      cursor: 'default',
    }),
    control: (provided, { isFocused, isDisabled }) => {
      const unfocusColor = errors ? theme.negative : theme.elementBorder;
      const focusColor = errors ? theme.negative : theme.primary;

      return {
        ...provided,
        backgroundColor: isDisabled
          ? 'rgba(0, 0, 0, 0.05)'
          : theme.contentBackground,
        borderRadius: '0.5rem',
        boxShadow: isFocused
          ? `0 0 0 2px ${focusColor}${HEX_CODE_OPACITY[10]}`
          : 'none',
        border: `1px solid ${isFocused ? focusColor : unfocusColor}`,
        cursor: 'pointer',
        ':hover': {
          boxShadow: isFocused
            ? `0 0 0 2px ${focusColor}${HEX_CODE_OPACITY[10]}`
            : 'none',
          border: `1px solid ${isFocused ? focusColor : unfocusColor}`,
        },
      };
    },
    option: (provided) => ({
      ...provided,
      color: styles.contentSecondary,
      cursor: 'pointer',
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'none',
      border: `1px solid ${theme.elementBorder}`,
      overflow: 'hidden',
    }),

    menuList: (provided) => ({
      ...provided,
      padding: 0,
      backgroundColor: theme.contentBackground,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 200, // $ceil-stack
    }),
    singleValue: (provided, { isDisabled }) => ({
      ...provided,
      color: isDisabled
        ? `${theme.contentPrimary}${HEX_CODE_OPACITY[75]}`
        : theme.contentPrimary,
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '0.5rem',
      backgroundColor: `${theme.primary}${HEX_CODE_OPACITY[10]}`,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: theme.primary,
      fontWeight: 'bold',
      fontSize: '0.9rem',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: theme.contentPrimary,
      borderRadius: '0 0.5rem 0.5rem 0',
      transition: 'background-color 0.1s ease-in-out, color 0.1s ease-in-out',
      ':hover': {
        backgroundColor: theme.negative,
        color: 'white',
        cursor: 'pointer',
      },
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: theme.contentTertiary,
    }),
  };

  const renderRequiredIndicator = (): JSX.Element | null =>
    showRequiredIndicator ? (
      <span className={styles.RequiredIndicator}>(Required)</span>
    ) : null;

  // Not clear yet why or when we'd use these nested errors, so this is enforcing
  // the dropdown to only use the root message if it exists for now.
  const hasRootErrorMessage = (
    errors: FieldError | FieldErrors<DropdownOption<T>> | undefined
  ): errors is FieldError => {
    return !!errors?.hasOwnProperty('message');
  };

  const SelectComponent = onCreateOption ? CreatableSelect : Select;

  return (
    <Suspense fallback={null}>
      <div className={className}>
        <label className={styles.Dropdown} htmlFor={id}>
          {label ? (
            <div
              className={classnames(styles.DropdownLabel, {
                [styles.Disabled]: disabled,
              })}
            >
              {label} {renderRequiredIndicator()}
            </div>
          ) : null}
          <div className={styles.DropdownSelect}>
            <SelectComponent
              menuPortalTarget={menuPortalTarget}
              inputId={id}
              name={name}
              placeholder={placeholder}
              options={options}
              value={value}
              onChange={onChange}
              isClearable={isClearable}
              isSearchable={isSearchable}
              isMulti={isMulti}
              menuPlacement={menuPlacement}
              menuPosition={menuPosition}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={selectStyles}
              theme={({ colors, ...provided }) => ({
                ...provided,
                colors: {
                  ...colors,
                  primary: `${theme.primary}${HEX_CODE_OPACITY[15]}`,
                  primary75: `${theme.primary}${HEX_CODE_OPACITY[15]}`,
                  primary50: `${theme.primary}${HEX_CODE_OPACITY[10]}`,
                  primary25: `${theme.primary}${HEX_CODE_OPACITY[5]}`,
                  danger: theme.negative,
                  dangerLight: `${theme.negative}${HEX_CODE_OPACITY[50]}`,
                },
              })}
              isDisabled={disabled}
              getOptionValue={getOptionValue}
              maxMenuHeight={200}
              onCreateOption={onCreateOption ?? undefined}
              noOptionsMessage={noOptionsMessage}
              isLoading={isLoading}
              formatOptionLabel={formatOptionLabel}
            />
          </div>
        </label>
        {!hideErrorMessage ? (
          <ErrorMessage
            message={hasRootErrorMessage(errors) ? errors.message : undefined}
          />
        ) : null}
      </div>
    </Suspense>
  );
}
