import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import Fuse, { IFuseOptions } from 'fuse.js';
import orderBy from 'lodash/orderBy';

import {
  AssignmentTypeWeightInfoFragment,
  PlanningAssignmentFragment,
  PlanningStudentCourseFragment,
} from 'graphql/types/generated';

import { scrollCoursePlanDaysToDay } from 'utils/planning';

import {
  PlanningCourse,
  PlanningState,
  PlanningTabIndex,
  RootState,
} from './types';

/**
 * Reducers
 */
const initialState: PlanningState = {
  course: null,
  assignments: null,
  studentCourses: null,
  assignmentTypeWeights: [],
  selectedAssignmentId: null,
  isCreatingNewAssignment: false,
  keepAssignmentSelected: false,
  activeTabIndex: PlanningTabIndex.DayWeek,
  currentDay: 1,
  currentWeek: 1,
  searchPattern: '',
  isSidebarSlideMenuOpen: false,
  parrotRequestId: null,
  bulkSelectedAssignmentsMap: {},
  isBulkUpdatingAssignments: false,
  bulkUpdatingFieldsMap: {},
  repeatingDaysMap: {},
  isCreatingRepeatingAssignments: false,
  shouldScrollAssignmentList: false,
};

export const planningSlice = createSlice({
  name: 'planning',
  initialState,
  reducers: {
    setAssignments: (
      state: PlanningState,
      action: PayloadAction<PlanningAssignmentFragment[]>
    ) => {
      state.assignments = action.payload || [];
    },
    setStudentCourses: (
      state: PlanningState,
      action: PayloadAction<PlanningStudentCourseFragment[]>
    ) => {
      state.studentCourses = action.payload || [];
    },
    setAssignmentTypeWeights: (
      state: PlanningState,
      action: PayloadAction<AssignmentTypeWeightInfoFragment[]>
    ) => {
      state.assignmentTypeWeights = action.payload || [];
    },
    setCourse: (
      state: PlanningState,
      action: PayloadAction<PlanningCourse>
    ) => {
      state.course = action.payload;
    },
    selectAssignment: (
      state: PlanningState,
      action: PayloadAction<string | null>
    ) => {
      state.selectedAssignmentId = action.payload;
      state.isCreatingNewAssignment = false;
    },
    setKeepAssignmentSelected: (
      state: PlanningState,
      action: PayloadAction<boolean>
    ) => {
      state.keepAssignmentSelected = action.payload;
    },
    startCreatingAssignment: (state: PlanningState) => {
      state.isCreatingNewAssignment = true;
    },
    cancelCreatingAssignment: (state: PlanningState) => {
      state.isCreatingNewAssignment = false;
    },
    setCurrentDay: (state: PlanningState, action: PayloadAction<number>) => {
      state.currentDay = action.payload;
    },
    setCurrentWeek: (state: PlanningState, action: PayloadAction<number>) => {
      state.currentWeek = action.payload;
    },
    setActiveTabIndex: (
      state: PlanningState,
      action: PayloadAction<number>
    ) => {
      state.activeTabIndex = action.payload;
    },
    setSearchPattern: (state: PlanningState, action: PayloadAction<string>) => {
      state.searchPattern = action.payload;
    },
    setIsSidebarSlideMenuOpen: (
      state: PlanningState,
      action: PayloadAction<boolean>
    ) => {
      state.isSidebarSlideMenuOpen = action.payload;
    },
    setParrotRequestId: (
      state: PlanningState,
      action: PayloadAction<string | null>
    ) => {
      state.parrotRequestId = action.payload;
    },
    resetPlanningView: () => initialState,
    bulkSelectAssignment: (
      state: PlanningState,
      action: PayloadAction<string>
    ) => {
      state.bulkSelectedAssignmentsMap[action.payload] =
        !state.bulkSelectedAssignmentsMap[action.payload];
    },
    deselectAllAssignments: (state: PlanningState) => {
      state.bulkSelectedAssignmentsMap = {};
    },
    setBulkSelectedAssignmentsMap: (
      state: PlanningState,
      action: PayloadAction<Record<string, boolean>>
    ) => {
      state.bulkSelectedAssignmentsMap = action.payload;
    },
    startBulkUpdatingAssignment: (state: PlanningState) => {
      state.isBulkUpdatingAssignments = true;
    },
    cancelBulkUpdatingAssignments: (state: PlanningState) => {
      state.isBulkUpdatingAssignments = false;
      state.bulkUpdatingFieldsMap = {};
      state.bulkSelectedAssignmentsMap = {};
    },
    selectBulkUpdateableField: (
      state: PlanningState,
      action: PayloadAction<string>
    ) => {
      state.bulkUpdatingFieldsMap[action.payload] =
        !state.bulkUpdatingFieldsMap[action.payload];
    },
    selectRepeatingDay: (
      state: PlanningState,
      action: PayloadAction<number>
    ) => {
      state.repeatingDaysMap[action.payload] =
        !state.repeatingDaysMap[action.payload];
    },
    startCreatingRepeatingAssignments: (state: PlanningState) => {
      state.isCreatingRepeatingAssignments = true;
    },
    cancelCreatingRepeatingAssignments: (state: PlanningState) => {
      state.isCreatingRepeatingAssignments = false;
      state.repeatingDaysMap = {};
    },
    setShouldScrollAssignmentList: (
      state: PlanningState,
      action: PayloadAction<boolean>
    ) => {
      state.shouldScrollAssignmentList = action.payload;
    },
  },
});

/**
 * Actions
 */
export const {
  setAssignments,
  setStudentCourses,
  setCourse,
  setAssignmentTypeWeights,
  selectAssignment,
  setKeepAssignmentSelected,
  startCreatingAssignment,
  cancelCreatingAssignment,
  setActiveTabIndex,
  setCurrentDay,
  setCurrentWeek,
  setSearchPattern,
  setIsSidebarSlideMenuOpen,
  setParrotRequestId,
  resetPlanningView,
  bulkSelectAssignment,
  deselectAllAssignments,
  setBulkSelectedAssignmentsMap,
  startBulkUpdatingAssignment,
  cancelBulkUpdatingAssignments,
  selectBulkUpdateableField,
  selectRepeatingDay,
  startCreatingRepeatingAssignments,
  cancelCreatingRepeatingAssignments,
  setShouldScrollAssignmentList,
} = planningSlice.actions;

export const selectAssignmentAndScrollToTop = createAsyncThunk(
  'planning/selectAssignmentAndScrollToTop',
  async (selectedAssignmentId: string | null, thunkApi) => {
    thunkApi.dispatch(selectAssignment(selectedAssignmentId));
    thunkApi.dispatch(setShouldScrollAssignmentList(true));
  }
);

export const createAssignmentAndScrollToTop = createAsyncThunk(
  'planning/createAssignmentAndScrollToTop',
  async (_, thunkApi) => {
    thunkApi.dispatch(selectAssignmentAndScrollToTop(null));
    thunkApi.dispatch(startCreatingAssignment());
    thunkApi.dispatch(setShouldScrollAssignmentList(true));
  }
);

export const updateCurrentWeekAndDay = createAsyncThunk<
  void,
  PlanningAssignmentFragment,
  { state: RootState }
>(
  'planning/updateCurrentWeekAndDay',
  (assignment: PlanningAssignmentFragment, thunkApi) => {
    const state = thunkApi.getState();

    const activeTabIndex = getActiveTabIndex(state);
    const currentDay = getCurrentDay(state);
    const currentWeek = getCurrentWeek(state);
    const courseId = getCourseId(state);

    const newDay = assignment?.day;
    const newWeek = assignment?.week;

    if (activeTabIndex === PlanningTabIndex.All) {
      return;
    }

    if (
      courseId &&
      newDay &&
      newWeek &&
      (newDay !== currentDay || newWeek !== currentWeek)
    ) {
      thunkApi.dispatch(setCurrentDay(newDay));
      thunkApi.dispatch(setCurrentWeek(newWeek));
      scrollCoursePlanDaysToDay(courseId, newDay, newWeek);
    }

    if (newDay && newWeek) {
      // navigate to day/week tab if you're scheduling an unscheduled assignment
      activeTabIndex === PlanningTabIndex.Unscheduled &&
        thunkApi.dispatch(setActiveTabIndex(PlanningTabIndex.DayWeek));
    }

    if (!newDay && !newWeek) {
      // navigate to unscheduled tab if you're unscheduling a scheduled assignment
      activeTabIndex === PlanningTabIndex.DayWeek &&
        thunkApi.dispatch(setActiveTabIndex(PlanningTabIndex.Unscheduled));
    }
  }
);

export const selectAllAssignments = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('planning/selectAllAssignments', (_, thunkApi) => {
  const state = thunkApi.getState();

  const filteredAssignments = getFilteredAssignments(state);

  const bulkSelectedAssignments =
    filteredAssignments?.reduce((bulkMap, assignment) => {
      bulkMap[assignment.id] = true;
      return bulkMap;
    }, {} as Record<string, boolean>) ?? {};

  thunkApi.dispatch(setBulkSelectedAssignmentsMap(bulkSelectedAssignments));
});

/**
 * Selectors
 */
const getPlanning = (state: RootState): PlanningState => state.planning;

export const getAssignments = createSelector(
  getPlanning,
  (planning: PlanningState) => planning.assignments
);

export const getAssignmentTypeWeights = createSelector(
  getPlanning,
  (planning: PlanningState) => planning.assignmentTypeWeights
);

export const getStudentCourses = createSelector(
  getPlanning,
  (planning: PlanningState) => planning.studentCourses
);

export const getStudentVacations = createSelector(
  getStudentCourses,
  (studentCourses: PlanningStudentCourseFragment[] | null) => {
    if (!studentCourses) {
      return [];
    }

    return studentCourses.map((studentCourse) =>
      studentCourse.student.studentEvents.map((studentEvent) => {
        return {
          from: parseISO(studentEvent.event.start),
          to: parseISO(studentEvent.event.end),
        };
      })
    );
  }
);

export const getCourse = createSelector(
  getPlanning,
  (planning: PlanningState) => planning.course
);

export const getCourseId = createSelector(
  getCourse,
  (course: PlanningCourse) => course?.id
);

export const getNumberOfDaysPerWeek = createSelector(
  getCourse,
  (course: PlanningCourse) => course?.numberOfDaysPerWeek
);

export const getNumberOfWeeks = createSelector(
  getCourse,
  (course: PlanningCourse) => course?.numberOfWeeks
);

export const getSelectedAssignmentId = createSelector(
  getPlanning,
  (planning: PlanningState) => planning.selectedAssignmentId
);

export const getKeepAssignmentSelected = createSelector(
  getPlanning,
  (planning: PlanningState) => planning.keepAssignmentSelected
);

export const getSelectedAssignment = createSelector(
  getAssignments,
  getSelectedAssignmentId,
  (
    planningAssignments: PlanningAssignmentFragment[] | null,
    selectedPlanningAssignmentId: string | null
  ) => {
    if (!selectedPlanningAssignmentId) {
      return null;
    }

    return planningAssignments?.find(
      (planningAssignment) =>
        planningAssignment.id === selectedPlanningAssignmentId
    );
  }
);

export const getIsCreatingNewAssignment = createSelector(
  getPlanning,
  (planning) => planning.isCreatingNewAssignment
);

export const getBulkSelectedAssignmentsMap = createSelector(
  getPlanning,
  (planning) => planning.bulkSelectedAssignmentsMap
);

export const getBulkSelectedAssignmentIds = createSelector(
  getBulkSelectedAssignmentsMap,
  (bulkSelectedAssignmentsMap) => {
    return Object.keys(bulkSelectedAssignmentsMap).filter(
      (id) => bulkSelectedAssignmentsMap[id]
    );
  }
);

export const getBulkSelectedAssignments = createSelector(
  getBulkSelectedAssignmentIds,
  getAssignments,
  (bulkSelectedAssignmentIds, assignments) => {
    return assignments?.filter((assignment) =>
      bulkSelectedAssignmentIds.includes(assignment.id)
    );
  }
);

export const getIsBulkUpdatingAssignments = createSelector(
  getPlanning,
  (planning) => planning.isBulkUpdatingAssignments
);

export const getBulkUpdatingFieldsMap = createSelector(
  getPlanning,
  (planning) => planning.bulkUpdatingFieldsMap
);

export const getBulkUpdatingFields = createSelector(
  getBulkUpdatingFieldsMap,
  (fields) => Object.keys(fields).filter((field) => !!fields[field])
);

export const getRepeatingDaysMap = createSelector(
  getPlanning,
  (planning) => planning.repeatingDaysMap
);

export const getRepeatingAssignmentDays = createSelector(
  getRepeatingDaysMap,
  (repeatingDaysMap) =>
    Object.keys(repeatingDaysMap).filter((day) => repeatingDaysMap[day])
);

export const getIsCreatingRepeatingAssignments = createSelector(
  getPlanning,
  (planning) => planning.isCreatingRepeatingAssignments
);

export const getShouldShowAssignmentDetails = createSelector(
  getSelectedAssignmentId,
  getIsCreatingNewAssignment,
  getIsBulkUpdatingAssignments,
  getIsCreatingRepeatingAssignments,
  (
    selectedAssignmentId,
    isCreatingNewAssignment,
    isBulkUpdatingAssignments,
    isCreatingRepeatingAssignments
  ) => {
    return (
      !!selectedAssignmentId ||
      isCreatingNewAssignment ||
      isBulkUpdatingAssignments ||
      isCreatingRepeatingAssignments
    );
  }
);

export const getCurrentDay = createSelector(
  getPlanning,
  (planning) => planning.currentDay
);

export const getCurrentWeek = createSelector(
  getPlanning,
  (planning) => planning.currentWeek
);

export const getActiveTabIndex = createSelector(
  getPlanning,
  (planning) => planning.activeTabIndex
);

export const getSearchPattern = createSelector(
  getPlanning,
  (planning) => planning.searchPattern
);

export const getIsSidebarSlideMenuOpen = createSelector(
  getPlanning,
  (planning) => planning.isSidebarSlideMenuOpen
);

export const getParrotRequestId = createSelector(
  getPlanning,
  (planning) => planning.parrotRequestId
);

export const getShouldScrollAssignmentList = createSelector(
  getPlanning,
  (planning) => planning.shouldScrollAssignmentList
);

export const getNumberOfAssignmentsDayWeekTab = createSelector(
  getPlanning,
  (planning) =>
    planning.assignments?.filter(
      (assignment) =>
        assignment.day === planning.currentDay &&
        assignment.week === planning.currentWeek
    ).length ?? 0
);

export const getNumberOfAssignmentsUnscheduledTab = createSelector(
  getPlanning,
  (planning) =>
    planning.assignments?.filter(
      (assignment) => !assignment.day && !assignment.week
    ).length ?? 0
);

export const getNumberOfAssignmentsInCourse = createSelector(
  getPlanning,
  (planning) => planning.assignments?.length ?? 0
);

const getSearchResults = (
  searchField: string,
  assignments?: PlanningAssignmentFragment[]
) => {
  if (!searchField || !assignments) {
    return assignments;
  }

  const fuseOptions: IFuseOptions<PlanningAssignmentFragment> = {
    // TODO: refine these settings
    // https://fusejs.io/api/options.html
    keys: [
      { name: 'name', weight: 3 },
      { name: 'customAssignmentType.name', weight: 2 },
      { name: 'description', weight: 1 },
      { name: 'attachments.url', weight: 1 },
      { name: 'teachersNote', weight: 1 },
    ],
    threshold: 0.2,
    ignoreLocation: true,
    ignoreFieldNorm: true,
    getFn: (obj, path) => {
      // Config is not on the exported Fuse type
      // @ts-ignore
      const value = Fuse.config.getFn(obj, path);

      const pathString = Array.isArray(path) ? path.join('') : path;

      if (pathString === 'attachments.url' && value.length) {
        return value[0].split('/').pop() ?? '';
      }

      return value;
    },
  };

  const fuse = new Fuse(assignments, fuseOptions);

  return fuse.search(searchField).map((result) => result.item);
};

const planningTabPredicates = {
  [PlanningTabIndex.DayWeek]:
    (currentDay: number, currentWeek: number) =>
    (assignment: PlanningAssignmentFragment) =>
      assignment.day === currentDay && assignment.week === currentWeek,
  [PlanningTabIndex.Unscheduled]:
    () => (assignment: PlanningAssignmentFragment) =>
      assignment.day === null && assignment.week === null,
  [PlanningTabIndex.All]: () => () => true,
};

export const getFilteredAssignments = createSelector(
  getAssignments,
  getActiveTabIndex,
  getCurrentDay,
  getCurrentWeek,
  getSearchPattern,
  (assignments, activeTabIndex, currentDay, currentWeek, searchField) => {
    const isAssignmentInActiveTab = planningTabPredicates[
      activeTabIndex as PlanningTabIndex
    ](currentDay, currentWeek);

    const filteredAssignments =
      assignments?.filter(isAssignmentInActiveTab) || [];

    const sortedFilteredAssignments = orderBy(filteredAssignments, [
      (a) => a.week,
      (a) => a.day,
      (a) => a.created,
    ]);

    return activeTabIndex === PlanningTabIndex.All
      ? getSearchResults(searchField, sortedFilteredAssignments)
      : sortedFilteredAssignments;
  }
);

export const getFollowingAssignments = createSelector(
  getAssignments,
  getSelectedAssignment,
  (assignments, selectedAssignment) => {
    if (!selectedAssignment) {
      return [];
    }

    const assignmentsOnOrAfterThisAssignment = assignments?.filter(
      (assignment) => {
        if (
          !assignment.week ||
          !assignment.day ||
          !selectedAssignment.day ||
          !selectedAssignment.week
        ) {
          return false;
        }
        const isOnOrAfterCurrentAssignment =
          assignment.week > selectedAssignment.week ||
          (assignment.week === selectedAssignment.week &&
            assignment.day >= selectedAssignment.day);
        return isOnOrAfterCurrentAssignment;
      }
    );

    return assignmentsOnOrAfterThisAssignment;
  }
);
