import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import UserRole from 'enums/UserRole';

import { Plan_Tier_Enum, UserSettingsFragment } from 'graphql/types/generated';

import { RootState, UserState } from './types';

/**
 * Reducers
 */
const initialState: UserState = {
  id: '',
  email: '',
  subscriptionStatus: null,
  planTier: null,
  role: null,
  sessionId: uuidv4(),
  settings: {
    id: '',
    showEmptyAssignmentDays: false,
  },
  isIdentified: false,
  organizationId: null,
  organizationName: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId: (state: UserState, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setUserEmail: (state: UserState, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserSubscriptionStatus: (
      state: UserState,
      action: PayloadAction<string | null>
    ) => {
      state.subscriptionStatus = action.payload;
    },
    setUserPlanTier: (
      state: UserState,
      action: PayloadAction<Plan_Tier_Enum>
    ) => {
      state.planTier = action.payload;
    },
    setUserRole: (state: UserState, action: PayloadAction<UserRole>) => {
      state.role = action.payload;
    },
    setUserSettings: (
      state: UserState,
      action: PayloadAction<UserSettingsFragment>
    ) => {
      state.settings = action.payload;
    },
    setUserIsIdentified: (state: UserState, action: PayloadAction<boolean>) => {
      state.isIdentified = action.payload;
    },
    setUserOrganizationId: (
      state: UserState,
      action: PayloadAction<string>
    ) => {
      state.organizationId = action.payload;
    },
    setUserOrganizationName: (
      state: UserState,
      action: PayloadAction<string>
    ) => {
      state.organizationName = action.payload;
    },
  },
});

/**
 * Actions
 */
export const {
  setUserId,
  setUserEmail,
  setUserSubscriptionStatus,
  setUserPlanTier,
  setUserRole,
  setUserSettings,
  setUserIsIdentified,
  setUserOrganizationId,
  setUserOrganizationName,
} = userSlice.actions;

/**
 * Selectors
 */
const getUser = (state: RootState): UserState => state?.user;

export const getUserId = createSelector(getUser, (user: UserState) => user.id);

export const getUserEmail = createSelector(
  getUser,
  (user: UserState) => user.email
);

export const getUserSubscriptionStatus = createSelector(
  getUser,
  (user: UserState) => user.subscriptionStatus
);

export const getUserPlanTier = createSelector(
  getUser,
  (user: UserState) => user.planTier
);

export const getUserRole = createSelector(
  getUser,
  (user: UserState) => user.role
);

export const getIsParrotUser = createSelector(
  getUser,
  (user: UserState) => user.role === UserRole.Parrot
);

export const getUserSessionId = createSelector(
  getUser,
  (user: UserState) => user.sessionId
);

export const getUserSettings = createSelector(
  getUser,
  (user: UserState) => user.settings
);

export const getUserIsIdentified = createSelector(
  getUser,
  (user: UserState) => user.isIdentified
);

export const getUserOrganizationId = createSelector(
  getUser,
  (user: UserState) => user.organizationId
);

export const getUserOrganizationName = createSelector(
  getUser,
  (user: UserState) => user.organizationName
);
