import { parseISO } from 'date-fns';

import {
  EventInfoFragment,
  StudentEventInfoFragment,
} from 'graphql/types/generated';

import { DateRange } from 'utils/studentAssignment';

/**
 * Return a list of vacations for the student with studentId
 *
 * @param events list of events, possibly for more than 1 student
 * @param studentId student for which to retrieve vacations
 */
export function getVacationsForStudentFromEvents(
  events: EventInfoFragment[],
  studentId: string
): EventInfoFragment[] {
  const currentVacationsForStudent = events.filter((event) =>
    event.studentEvents.some(
      (studentEvent) => studentEvent.student.id === studentId
    )
  );
  return currentVacationsForStudent;
}

/**
 * Return a list of DateRanges created based on the start and end
 * date of the event
 *
 * @param events list of events
 */
export function getDateRangesFromEvents(
  events: EventInfoFragment[]
): DateRange[] {
  return events.map((event) => {
    return { from: parseISO(event.start), to: parseISO(event.end) };
  });
}

/**
 * Return a list of DateRanges created based on the start and end
 * date of the student event
 *
 * @param studentEvents list of student events
 */
export function getDateRangesFromStudentEvents(
  studentEvents: StudentEventInfoFragment[]
): DateRange[] {
  return studentEvents.map((studentEvent) => {
    return {
      from: parseISO(studentEvent.event.start),
      to: parseISO(studentEvent.event.end),
    };
  });
}
