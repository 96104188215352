import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import { Check, HelpCircle } from 'react-feather';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
  children?: JSX.Element;
  className?: string;
  id: string;
  name: string;
  onChange?: ChangeEventHandler;
  onClick?: MouseEventHandler;
  isSelected?: boolean;
  register?: any;
  helpMessage?: string;
  disabled?: boolean;
  hideLabel?: boolean;
}

export default function Checkbox({
  children,
  className,
  id,
  name,
  onChange,
  onClick,
  isSelected,
  register,
  helpMessage,
  disabled = false,
  hideLabel = false,
}: CheckboxProps): JSX.Element {
  const classes = classnames(className, styles.Checkbox, {
    [styles.Disabled]: disabled,
    [styles.Enabled]: !disabled,
  });
  const renderHelpIcon = (): JSX.Element | null =>
    helpMessage ? (
      <Tippy
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: helpMessage,
            }}
          />
        }
      >
        <div data-tippy-content={helpMessage} className={styles.HelpIcon}>
          <HelpCircle size={15} />
        </div>
      </Tippy>
    ) : null;

  return (
    <div className={classes} onClick={onClick}>
      <label>
        <input
          id={id}
          name={name}
          type="checkbox"
          onChange={onChange}
          checked={isSelected}
          disabled={disabled}
          {...register}
        />
        <div
          className={classnames(styles.CheckboxInput, {
            [styles.HiddenLabel]: hideLabel,
          })}
        >
          {isSelected && <Check height={12} width={12} strokeWidth={4} />}
        </div>
        <div
          className={classnames(styles.CheckboxLabel, {
            [styles.HiddenLabel]: hideLabel,
          })}
        >
          {children} {renderHelpIcon()}
        </div>
      </label>
    </div>
  );
}
