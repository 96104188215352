import { Middleware } from '@reduxjs/toolkit';

import { HIDE_COMPLETED_KEY, toggleHideCompleted } from './home';
import { RootState } from './types';

export const homeFiltersLocalStorageMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    const result = next(action);
    if (toggleHideCompleted.match(action)) {
      try {
        const state = store.getState();
        localStorage.setItem(
          HIDE_COMPLETED_KEY,
          JSON.stringify(state.home.filters.hideCompleted)
        );
      } catch (error) {
        console.warn('Failed to set home filter state in local storage', error);
      }
    }
    return result;
  };
