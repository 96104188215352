import styles from './ErrorPage.module.scss';

export default function ErrorPage(): JSX.Element {
  return (
    <div className={styles.ErrorPage}>
      <h1 className={styles.Title}>
        Whoops! Something went wrong...{' '}
        <span role="img" aria-label="Sad emoji">
          😢
        </span>
      </h1>
      <p className={styles.Message}>
        We apologize for the hiccup and are looking into the issue. You may have
        to come back later. For now, try refreshing the page and checking for
        updates to your browser or Apple device.
      </p>
      <p className={styles.Message}>
        If the problem doesn't go away please reach out to us at{' '}
        <a href="mailto:support@syllabird.com">support@syllabird.com</a>.
      </p>
    </div>
  );
}
