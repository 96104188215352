import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { differenceInCalendarDays, parseISO } from 'date-fns';

import { DropdownOption } from 'components/Dropdown';

import CalculatedStudentAssignmentStatus from 'enums/CalculatedStudentAssignmentStatus';

import {
  CourseOptionFragment,
  HomeStudentAssignmentFragment,
  StudentOptionFragment,
  Student_Assignment_Status_Enum,
} from 'graphql/types/generated';

import HomeFiltersState, { HomeState, RootState } from './types';

export const HIDE_COMPLETED_KEY = 'syllabird.home.filters.hideCompleted';

/**
 * Reducers
 */
const loadInitialState = (): HomeState => {
  let savedHideCompleted: boolean = true;
  try {
    savedHideCompleted =
      JSON.parse(localStorage?.getItem(HIDE_COMPLETED_KEY) ?? 'true') ?? true;
  } catch (error) {
    console.warn('Failed to fetch home filter state from local storage', error);
  }

  return {
    filters: {
      students: [],
      courses: [],
      subjects: [],
      assignmentTypes: [],
      assignmentStatuses: [],
      hideCompleted: savedHideCompleted,
    },
    homeStudentAssignments: null,
    selectedHomeStudentAssignmentId: null,
    isDetailsSlideMenuOpen: false,
    isWelcomeToSyllabirdModalOpen: false,
  };
};

export const homeSlice = createSlice({
  name: 'home',
  initialState: loadInitialState(),
  reducers: {
    // Assignments State
    setHomeStudentAssignments: (
      state: HomeState,
      action: PayloadAction<HomeStudentAssignmentFragment[] | null>
    ) => {
      state.homeStudentAssignments = action.payload;
    },
    selectHomeStudentAssignment: (
      state: HomeState,
      action: PayloadAction<string | null>
    ) => {
      state.selectedHomeStudentAssignmentId = action.payload;

      // only open menu if we've actually selected an assignment
      if (action.payload !== null) {
        state.isDetailsSlideMenuOpen = true;
      }
    },

    // Filters State
    setStudentsFilter: (
      state: HomeState,
      action: PayloadAction<DropdownOption<StudentOptionFragment>[]>
    ) => {
      state.filters.students = action.payload;
    },
    setCoursesFilter: (
      state: HomeState,
      action: PayloadAction<DropdownOption<CourseOptionFragment>[]>
    ) => {
      state.filters.courses = action.payload;
    },
    setSubjectsFilter: (
      state: HomeState,
      action: PayloadAction<DropdownOption<string>[]>
    ) => {
      state.filters.subjects = action.payload;
    },
    setAssignmentTypesFilter: (
      state: HomeState,
      action: PayloadAction<DropdownOption<string>[]>
    ) => {
      state.filters.assignmentTypes = action.payload;
    },
    setAssignmentStatusesFilter: (
      state: HomeState,
      action: PayloadAction<
        DropdownOption<
          Student_Assignment_Status_Enum | CalculatedStudentAssignmentStatus
        >[]
      >
    ) => {
      state.filters.assignmentStatuses = action.payload;
    },
    toggleHideCompleted: (state: HomeState) => {
      const hideCompletedNext = !state.filters.hideCompleted;
      state.filters.hideCompleted = hideCompletedNext;

      // Deselect "Complete" if completed, and you opt to "Hide Complete"
      if (hideCompletedNext) {
        state.filters.assignmentStatuses =
          state.filters.assignmentStatuses.filter(
            (status) => status.value !== Student_Assignment_Status_Enum.Complete
          );
      }
    },
    clearFilters: (state: HomeState) => {
      state.filters = loadInitialState().filters;
    },

    // UI State
    setIsDetailsSlideMenuOpen: (
      state: HomeState,
      action: PayloadAction<boolean>
    ) => {
      state.isDetailsSlideMenuOpen = action.payload;
    },
    setIsWelcomeToSyllabirdModalOpen: (
      state: HomeState,
      action: PayloadAction<boolean>
    ) => {
      state.isWelcomeToSyllabirdModalOpen = action.payload;
    },
  },
});

/**
 * Actions
 */
export const {
  setHomeStudentAssignments,
  selectHomeStudentAssignment,
  setStudentsFilter,
  setCoursesFilter,
  setSubjectsFilter,
  setAssignmentTypesFilter,
  setAssignmentStatusesFilter,
  toggleHideCompleted,
  clearFilters,
  setIsDetailsSlideMenuOpen,
  setIsWelcomeToSyllabirdModalOpen,
} = homeSlice.actions;

/**
 * Selectors
 */
const getHome = (state: RootState): HomeState => state.home;

// Filters Selectors
export const getFilters = createSelector(
  getHome,
  (home: HomeState) => home.filters
);

export const getCurrentStudentsFilter = createSelector(
  getFilters,
  (filters: HomeFiltersState) => filters.students
);

export const getCurrentCoursesFilter = createSelector(
  getFilters,
  (filters: HomeFiltersState) => filters.courses
);

export const getCurrentSubjectsFilter = createSelector(
  getFilters,
  (filters: HomeFiltersState) => filters.subjects
);

export const getCurrentAssignmentTypesFilter = createSelector(
  getFilters,
  (filters: HomeFiltersState) => filters.assignmentTypes
);

export const getCurrentAssignmentStatusesFilter = createSelector(
  getFilters,
  (filters: HomeFiltersState) => filters.assignmentStatuses
);

export const getHideCompleted = createSelector(
  getFilters,
  (filters: HomeFiltersState) => filters.hideCompleted
);

export const getNumFiltersApplied = createSelector(
  getFilters,
  (filters: HomeFiltersState) => {
    const {
      students,
      courses,
      subjects,
      assignmentTypes,
      assignmentStatuses,
      hideCompleted,
    } = filters;

    return (
      (students?.length ?? 0) +
      (courses?.length ?? 0) +
      (subjects?.length ?? 0) +
      (assignmentTypes?.length ?? 0) +
      (assignmentStatuses?.length ?? 0) +
      (hideCompleted ? 1 : 0)
    );
  }
);

export const getFilteredStudentIds = createSelector(
  getCurrentStudentsFilter,
  (studentsFilter: DropdownOption<StudentOptionFragment>[]) =>
    studentsFilter.map((option) => option.value.id)
);

export const getFilteredCourseIds = createSelector(
  getCurrentCoursesFilter,
  (courses: DropdownOption<CourseOptionFragment>[]) =>
    courses.map((option) => option.value.id)
);

export const getFilteredSubjects = createSelector(
  getCurrentSubjectsFilter,
  (subjects: DropdownOption<string>[]) => subjects.map((option) => option.value)
);

export const getFilteredAssignmentTypeIds = createSelector(
  getCurrentAssignmentTypesFilter,
  (assignmentTypes: DropdownOption<string>[]) =>
    assignmentTypes.map((option) => option.value)
);

export const getFilteredAssignmentStatuses = createSelector(
  getCurrentAssignmentStatusesFilter,
  (
    assignmentStatuses: DropdownOption<
      Student_Assignment_Status_Enum | CalculatedStudentAssignmentStatus
    >[]
  ) => assignmentStatuses.map((option) => option.value)
);

// Assignment List Selectors
export const getHomeStudentAssignments = createSelector(
  getHome,
  (home: HomeState) => home.homeStudentAssignments
);

export const getHomeStudentAssignmentsLength = createSelector(
  getHomeStudentAssignments,
  (studentAssignments: HomeStudentAssignmentFragment[] | null) =>
    studentAssignments?.length ?? null
);

// UI State Selectors
export const getSelectedHomeStudentAssignmentId = createSelector(
  getHome,
  (home: HomeState) => home.selectedHomeStudentAssignmentId
);

export const getIsDetailsSlideMenuOpen = createSelector(
  getHome,
  (home: HomeState) => home.isDetailsSlideMenuOpen
);

export const getIsWelcomeToSyllabirdModalOpen = createSelector(
  getHome,
  (home: HomeState) => home.isWelcomeToSyllabirdModalOpen
);

export const isSelectedHomeStudentAssignment = (studentAssignmentId: string) =>
  createSelector(
    getSelectedHomeStudentAssignmentId,
    (selectedHomeStudentAssignmentId: string | null) =>
      studentAssignmentId === selectedHomeStudentAssignmentId
  );

export const getFilteredHomeStudentAssignments = createSelector(
  getHomeStudentAssignments,
  getFilteredStudentIds,
  getFilteredCourseIds,
  getFilteredSubjects,
  getFilteredAssignmentTypeIds,
  getFilteredAssignmentStatuses,
  getHideCompleted,
  (
    studentAssignments: HomeStudentAssignmentFragment[] | null,
    filteredStudentIds: string[],
    filteredCourseIds: string[],
    filteredSubjects: string[],
    filteredAssignmentTypeIds: string[],
    filteredAssignmentStatuses: (
      | Student_Assignment_Status_Enum
      | CalculatedStudentAssignmentStatus
    )[],
    hideCompleted: boolean
  ) => {
    const matchesStudentsFilter = (
      studentAssignment: HomeStudentAssignmentFragment
    ) =>
      filteredStudentIds.length === 0 ||
      filteredStudentIds.includes(studentAssignment.student.id);

    const matchesCoursesFilter = (
      studentAssignment: HomeStudentAssignmentFragment
    ) =>
      filteredCourseIds.length === 0 ||
      filteredCourseIds.includes(studentAssignment.assignment.course.id);

    const matchesSubjectsFilter = (
      studentAssignment: HomeStudentAssignmentFragment
    ) => {
      const studentAssignmentSubjects = (studentAssignment.assignment.course
        .subjects ?? []) as string[];

      return (
        filteredSubjects.length === 0 ||
        filteredSubjects.some((subject) =>
          studentAssignmentSubjects.includes(subject)
        )
      );
    };

    const matchesAssignmentTypesFilter = (
      studentAssignment: HomeStudentAssignmentFragment
    ) => {
      return (
        filteredAssignmentTypeIds.length === 0 ||
        (studentAssignment.assignment?.customAssignmentType?.id &&
          filteredAssignmentTypeIds.includes(
            studentAssignment.assignment.customAssignmentType.id
          ))
      );
    };

    const matchesAssignmentStatusesFilter = (
      studentAssignment: HomeStudentAssignmentFragment
    ) => {
      if (
        // Always filter out unscheduled student assignments
        !studentAssignment.end
      ) {
        return false;
      }

      const studentAssignmentEnd = parseISO(studentAssignment.end);
      const daysTilDue = differenceInCalendarDays(
        studentAssignmentEnd,
        new Date()
      );

      const studentAssignmentStatus = studentAssignment.status;

      const isOverdue = daysTilDue < 0;
      const isDueToday = daysTilDue === 0;
      const isInProgress = daysTilDue > 0;
      const needsGrading =
        studentAssignmentStatus === Student_Assignment_Status_Enum.Complete &&
        studentAssignment.pointsEarned === null &&
        studentAssignment.assignment.graded;

      if (
        // Always filter out completed assignments that don't need grading if setting turned on
        hideCompleted &&
        studentAssignment.status === Student_Assignment_Status_Enum.Complete &&
        !needsGrading
      ) {
        return false;
      }

      // Treat no filtered statuses as all statuses
      if (filteredAssignmentStatuses.length === 0) {
        return true;
      }

      const matchesOverdueFilter =
        filteredAssignmentStatuses.includes(
          CalculatedStudentAssignmentStatus.Overdue
        ) && isOverdue;
      const matchesDueTodayFilter =
        filteredAssignmentStatuses.includes(
          CalculatedStudentAssignmentStatus.DueToday
        ) && isDueToday;
      const matchesInProgressFilter =
        filteredAssignmentStatuses.includes(
          CalculatedStudentAssignmentStatus.InProgress
        ) && isInProgress;
      const matchesNeedsGradingFilter =
        filteredAssignmentStatuses.includes(
          CalculatedStudentAssignmentStatus.NeedsGrading
        ) && needsGrading;
      const matchesCompleteFilter =
        !needsGrading &&
        filteredAssignmentStatuses.includes(
          Student_Assignment_Status_Enum.Complete
        ) &&
        studentAssignmentStatus === Student_Assignment_Status_Enum.Complete;
      const matchesIncompleteFilter =
        filteredAssignmentStatuses.includes(
          Student_Assignment_Status_Enum.Incomplete
        ) &&
        studentAssignmentStatus === Student_Assignment_Status_Enum.Incomplete;

      return (
        matchesOverdueFilter ||
        matchesDueTodayFilter ||
        matchesInProgressFilter ||
        matchesNeedsGradingFilter ||
        matchesCompleteFilter ||
        matchesIncompleteFilter
      );
    };

    const filteredHomeStudentAssignments = studentAssignments
      ?.filter(matchesStudentsFilter)
      .filter(matchesCoursesFilter)
      .filter(matchesSubjectsFilter)
      .filter(matchesAssignmentTypesFilter)
      .filter(matchesAssignmentStatusesFilter);

    return filteredHomeStudentAssignments ?? null;
  }
);

export const getFilteredHomeStudentAssignmentsLength = createSelector(
  getFilteredHomeStudentAssignments,
  (filteredStudentAssignments: HomeStudentAssignmentFragment[] | null) =>
    filteredStudentAssignments?.length ?? null
);
