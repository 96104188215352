import { SYLLABIRD_THEME } from './constants';
import { Theme } from './types';

function setCssVariables(fontFamily: string, baseFontSize: number) {
  document.documentElement.style.setProperty('--font-family', fontFamily);
  document.documentElement.style.setProperty(
    '--base-font-size',
    `${baseFontSize}px`
  );
}

export default function setFonts(theme: Theme) {
  const { fontFamily, fontUrl } = theme;

  const defaultFontFamily = SYLLABIRD_THEME.fontFamily;
  const defaultFontUrl = SYLLABIRD_THEME.fontUrl;

  const defaultFontFamilyValue = `"${defaultFontFamily}", sans-serif`;

  if (
    !fontFamily ||
    !fontUrl ||
    (fontFamily === defaultFontFamily && fontUrl === defaultFontUrl)
  ) {
    setCssVariables(defaultFontFamilyValue, SYLLABIRD_THEME.baseFontSize);
    return;
  }

  const preloadLink = document.createElement('link');
  preloadLink.rel = 'preload';
  preloadLink.as = 'style';
  preloadLink.href = fontUrl;

  const stylesheetLink = document.createElement('link');
  stylesheetLink.rel = 'stylesheet';
  stylesheetLink.href = fontUrl;
  stylesheetLink.media = 'print';
  stylesheetLink.onload = function () {
    // @ts-ignore
    this.media = 'all';
  };

  const noscriptLink = document.createElement('link');
  noscriptLink.rel = 'stylesheet';
  noscriptLink.href = fontUrl;

  document.head.appendChild(preloadLink);
  document.head.appendChild(stylesheetLink);

  const noscriptElement = document.createElement('noscript');
  noscriptElement.appendChild(noscriptLink);
  document.head.appendChild(noscriptElement);

  const fontFamilyCssValue = `"${theme.fontFamily}", ${defaultFontFamilyValue}`;

  setCssVariables(fontFamilyCssValue, theme.baseFontSize);
}
