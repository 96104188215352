import { animated, useTransition } from '@react-spring/web';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';

import Button from 'components/Button';

import styles from './ButtonMenu.module.scss';

interface ButtonMenuProps {
  children: JSX.Element[] | JSX.Element;
  name: string;
  width?: string;
}

export default function ButtonMenu({
  children,
  name,
  width,
}: ButtonMenuProps): JSX.Element {
  const buttonMenuRef = useRef<HTMLDivElement>(null);
  const [isButtonMenuOpen, setIsButtonMenuOpen] = useState<boolean>(false);

  const transitions = useTransition(isButtonMenuOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 50,
    },
  });

  const handleToggleButtonMenu = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsButtonMenuOpen(!isButtonMenuOpen);
    },
    [isButtonMenuOpen]
  );

  const handleCloseButtonMenu = useCallback(() => {
    if (!isButtonMenuOpen) {
      return;
    }

    setIsButtonMenuOpen(false);
  }, [isButtonMenuOpen]);

  useEffect(() => {
    document.addEventListener('click', handleCloseButtonMenu);
    return () => {
      document.removeEventListener('click', handleCloseButtonMenu);
    };
  }, [handleCloseButtonMenu]);

  const menuStyles: CSSProperties = {
    minWidth: width ?? `12rem`,
  };

  return (
    <div className={styles.ButtonMenu} ref={buttonMenuRef}>
      <Button onClick={handleToggleButtonMenu}>
        <div className={styles.ButtonContent}>
          {name}
          {isButtonMenuOpen ? (
            <ChevronUp className={styles.Chevron} size={17} />
          ) : (
            <ChevronDown className={styles.Chevron} size={17} />
          )}
        </div>
      </Button>
      {transitions(
        (style, item) =>
          item && (
            <animated.div
              className={styles.ActionMenu}
              style={{ ...style, ...menuStyles }}
            >
              {children}
            </animated.div>
          )
      )}
    </div>
  );
}
