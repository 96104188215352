// when looping through the daysOfTheWeek object from the database, we are not guaranteed the day ordering
// this constant defines the ordering the days of the week should appear in
export const weekDayOrder = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const dateFnsWeekDayOrder = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const weekDayOrderShort = ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su'];

export const longWeekDayToShortWeekDay: Record<string, string> = {
  Monday: 'M',
  Tuesday: 'Tu',
  Wednesday: 'W',
  Thursday: 'Th',
  Friday: 'F',
  Saturday: 'Sa',
  Sunday: 'Su',
};

export const defaultIsDayOfTheWeekChecked: Record<string, boolean> = {
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  Sunday: false,
};

export const defaultThreeDaysOfTheWeekChecked: Record<string, boolean> = {
  Monday: true,
  Tuesday: false,
  Wednesday: true,
  Thursday: false,
  Friday: true,
  Saturday: false,
  Sunday: false,
};

export const defaultFiveDaysOfTheWeekChecked: Record<string, boolean> = {
  Monday: true,
  Tuesday: true,
  Wednesday: true,
  Thursday: true,
  Friday: true,
  Saturday: false,
  Sunday: false,
};

export const defaultSevenDaysOfTheWeekChecked: Record<string, boolean> = {
  Monday: true,
  Tuesday: true,
  Wednesday: true,
  Thursday: true,
  Friday: true,
  Saturday: true,
  Sunday: true,
};
