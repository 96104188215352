import { gql } from '@apollo/client';

import { StudentAssignmentDetails } from 'graphql/fragments/studentAssignment';

export const GET_HOME_STUDENT_ASSIGNMENTS = gql`
  query GetHomeStudentAssignments($day: date!, $isStudent: Boolean!) {
    studentAssignments: student_assignments(
      where: {
        _or: [
          { _and: [{ end: { _lt: $day } }, { status: { _eq: INCOMPLETE } }] } # Overdue
          { end: { _eq: $day } } # Due Today
          { _and: [{ start: { _lte: $day } }, { end: { _gt: $day } }] } # In Progress
        ]
      }
    ) {
      ...StudentAssignmentDetails
    }
    studentAssignmentsForGrading: student_assignments(
      where: {
        _and: [
          { pointsEarned: { _is_null: true } }
          { assignment: { graded: { _eq: true } } }
          { status: { _eq: COMPLETE } }
        ]
      }
    ) {
      ...StudentAssignmentDetails
    }
  }
  ${StudentAssignmentDetails}
`;
