import sortBy from 'lodash/sortBy';
import { CSSProperties, Fragment, useEffect, useState } from 'react';
import {
  CheckSquare,
  ChevronRight,
  Edit,
  Plus,
  RefreshCw,
  Trash2,
  X,
} from 'react-feather';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import ActionMeatballs from 'components/ActionMeatballs';
import AssignmentInfo from 'components/AssignmentInfo';
import AssignmentList from 'components/AssignmentList';
import BulkUpdateAssignmentInfo from 'components/BulkUpdateAssignmentInfo/BulkUpdateAssignmentInfo';
import Button, { ButtonColor, ButtonType } from 'components/Button';
import ButtonMenu from 'components/ButtonMenu';
import Card from 'components/Card';
import ConfirmationModal from 'components/ConfirmationModal';
import CoursePlanDays from 'components/CoursePlanDays';
import { Orientation } from 'components/CoursePlanDays/CoursePlanDays';
import LoadingIndicator from 'components/LoadingIndicator';
import NewAssignmentInfo from 'components/NewAssignmentInfo/NewAssignmentInfo';
import RepeatingAssignmentInfo from 'components/RepeatingAssignmentInfo/RepeatingAssignmentInfo';
import SearchBar from 'components/SearchBar';
import SlideMenu, { FromDirection } from 'components/SlideMenu/SlideMenu';
import Tab from 'components/Tab';
import Tabset from 'components/Tabset';
import WeekDaySelector from 'components/WeekDaySelector';

import { useTheme } from 'context/Theme';

import {
  cancelBulkUpdatingAssignments,
  cancelCreatingAssignment,
  cancelCreatingRepeatingAssignments,
  createAssignmentAndScrollToTop,
  deselectAllAssignments,
  getActiveTabIndex,
  getBulkSelectedAssignmentIds,
  getCurrentDay,
  getCurrentWeek,
  getFilteredAssignments,
  getIsBulkUpdatingAssignments,
  getIsCreatingNewAssignment,
  getIsCreatingRepeatingAssignments,
  getIsSidebarSlideMenuOpen,
  getKeepAssignmentSelected,
  getNumberOfAssignmentsDayWeekTab,
  getNumberOfAssignmentsInCourse,
  getNumberOfAssignmentsUnscheduledTab,
  getParrotRequestId,
  getSearchPattern,
  getSelectedAssignmentId,
  getShouldShowAssignmentDetails,
  resetPlanningView,
  selectAllAssignments,
  selectAssignment,
  selectAssignmentAndScrollToTop,
  setActiveTabIndex,
  setAssignmentTypeWeights,
  setAssignments,
  setCourse,
  setCurrentDay,
  setCurrentWeek,
  setIsSidebarSlideMenuOpen,
  setKeepAssignmentSelected,
  setSearchPattern,
  setStudentCourses,
  startBulkUpdatingAssignment,
  startCreatingRepeatingAssignments,
} from 'redux/planning';
import { useAppDispatch } from 'redux/store';
import { PlanningTabIndex } from 'redux/types';
import { getIsParrotUser } from 'redux/user';

import styles from './CoursePlanPage.module.scss';
import useCoursePlanPageGqlOps from './useCoursePlanPageGqlOps';

export type CoursePlanPageParams = {
  courseId: string;
};

export const coursePlanDaysElId = 'course-plan-days';

export default function CoursePlanPage(): JSX.Element | null {
  const {
    theme: { colorPalette },
  } = useTheme();

  const dispatch = useAppDispatch();

  const [isConfirmationPortalOpen, setIsConfirmationPortalOpen] =
    useState<boolean>(false);

  const isParrotUser = useSelector(getIsParrotUser);

  const { courseId } =
    useParams<CoursePlanPageParams>() as CoursePlanPageParams;

  const parrotRequestId = useSelector(getParrotRequestId);

  const filteredAssignments = useSelector(getFilteredAssignments);
  const isCreatingNewAssignment = useSelector(getIsCreatingNewAssignment);
  const keepAssignmentSelected = useSelector(getKeepAssignmentSelected);

  const activeTabIndex = useSelector(getActiveTabIndex);
  const selectedAssignmentId = useSelector(getSelectedAssignmentId);
  const currentDay = useSelector(getCurrentDay);
  const currentWeek = useSelector(getCurrentWeek);
  const searchPattern = useSelector(getSearchPattern);
  const isSidebarSlideMenuOpen = useSelector(getIsSidebarSlideMenuOpen);
  const numberOfAssignmentsDayWeekTab = useSelector(
    getNumberOfAssignmentsDayWeekTab
  );
  const numberOfAssignmentsUnscheduledTab = useSelector(
    getNumberOfAssignmentsUnscheduledTab
  );
  const numberOfAssignmentsInCourse = useSelector(
    getNumberOfAssignmentsInCourse
  );
  const shouldShowAssignmentDetails = useSelector(
    getShouldShowAssignmentDetails
  );
  const bulkSelectedAssignments = useSelector(getBulkSelectedAssignmentIds);
  const isBulkUpdatingAssignments = useSelector(getIsBulkUpdatingAssignments);
  const isCreatingRepeatingAssignments = useSelector(
    getIsCreatingRepeatingAssignments
  );

  const { getPlanningCourse, deleteAssignments } =
    useCoursePlanPageGqlOps(parrotRequestId);

  const course = getPlanningCourse?.data?.course;
  const isDataLoading = getPlanningCourse?.loading;

  const isSmallScreen = useMediaQuery({
    query: '(max-width: 1199px)',
  });

  useEffect(() => {
    if (isDataLoading) {
      return;
    }

    dispatch(setAssignments(course?.assignments || []));
    dispatch(setStudentCourses(course?.studentCourses || []));

    const sortedAssignmentTypeWeights =
      sortBy(course?.assignmentTypeWeights, 'sortOrder') ?? [];
    dispatch(setAssignmentTypeWeights(sortedAssignmentTypeWeights));

    course &&
      dispatch(
        setCourse({
          id: course.id,
          name: course.name,
          color: course.color,
          numberOfDaysPerWeek: course.numberOfDaysPerWeek,
          numberOfWeeks: course.numberOfWeeks,
        })
      );
  }, [dispatch, isDataLoading, course]);

  const handleClickDay = (day: number, week: number) => {
    if (!(currentDay === day && currentWeek === week)) {
      dispatch(setCurrentDay(day));
      dispatch(setCurrentWeek(week));
      dispatch(cancelCreatingAssignment());
    }
    dispatch(setActiveTabIndex(PlanningTabIndex.DayWeek));
  };

  // Deselects assignment if it's not in the new filteredAssignments list, unless
  // keepAssignmentSelected is true in which case it waits until it finds the assignment
  // in a list again before deselecting.
  useEffect(() => {
    if (!selectedAssignmentId || !filteredAssignments) {
      return;
    }

    if (
      filteredAssignments.some(
        (assignment) => assignment.id === selectedAssignmentId
      )
    ) {
      dispatch(setKeepAssignmentSelected(false));
    } else if (!keepAssignmentSelected) {
      dispatch(selectAssignmentAndScrollToTop(null));
    }

    // We do not want to run this hook when the active tab changes or
    // when the keepAssignmentSelected state changes
    // eslint-disable-next-line
  }, [dispatch, filteredAssignments, selectedAssignmentId]);

  // Scrolls selected assignment into view if you change tabs and the selected
  // assignment is still in the list
  useEffect(() => {
    if (!selectedAssignmentId || !filteredAssignments) {
      return;
    }

    if (
      filteredAssignments.some(
        (assignment) => assignment.id === selectedAssignmentId
      )
    ) {
      dispatch(selectAssignmentAndScrollToTop(selectedAssignmentId));
    }

    // We only want this scroll to happen when we change tabs and the assignment
    // is still in the list
    // eslint-disable-next-line
  }, [activeTabIndex]);

  useEffect(() => {
    return () => {
      dispatch(resetPlanningView());
    };

    // We want this hook to only run when component will unmount
    // eslint-disable-next-line
  }, []);

  if (isDataLoading) {
    return <LoadingIndicator className={styles.LoadingIndicator} />;
  }

  const handleClickTab = (index: number) => {
    if (index !== PlanningTabIndex.All) {
      dispatch(setSearchPattern(''));
    }

    dispatch(setActiveTabIndex(index));
    dispatch(cancelCreatingAssignment());
  };

  const handleOpenCoursePlanDaysSlideMenu = () => {
    dispatch(setIsSidebarSlideMenuOpen(true));
  };

  const handleCloseCoursePlanDaysSlideMenu = () => {
    dispatch(setIsSidebarSlideMenuOpen(false));
  };

  const handleCloseAssignmentInfoSlideMenu = () => {
    selectedAssignmentId && dispatch(selectAssignment(null));
    isBulkUpdatingAssignments && dispatch(cancelBulkUpdatingAssignments());
    isCreatingNewAssignment && dispatch(cancelCreatingAssignment());
    isCreatingRepeatingAssignments &&
      dispatch(cancelCreatingRepeatingAssignments());
  };

  const handleClickEditAssignments = () => {
    dispatch(cancelCreatingAssignment());
    dispatch(selectAssignment(null));
    dispatch(cancelCreatingRepeatingAssignments());

    dispatch(startBulkUpdatingAssignment());
  };

  const handleClickCreateSingleAssignment = () => {
    dispatch(selectAssignment(null));
    dispatch(cancelBulkUpdatingAssignments());
    dispatch(cancelCreatingRepeatingAssignments());

    dispatch(createAssignmentAndScrollToTop());
  };

  const handleClickCreateRepeatingAssignment = () => {
    dispatch(selectAssignment(null));
    dispatch(cancelBulkUpdatingAssignments());
    dispatch(cancelCreatingRepeatingAssignments());

    dispatch(startCreatingRepeatingAssignments());
  };

  const handleDeleteAssignments = async () => {
    if (!bulkSelectedAssignments.length) {
      return;
    }

    await deleteAssignments.call({
      variables: {
        assignmentIds: bulkSelectedAssignments,
      },
    });

    dispatch(cancelBulkUpdatingAssignments());
    setIsConfirmationPortalOpen(false);
  };

  if (isParrotUser && !parrotRequestId) {
    return (
      <div className={styles.InvalidParams}>
        <div className={styles.InvalidParamsMessage}>
          No valid Parrot Request ID found
        </div>
        <Link to="/plan">
          <span>Go Planning Sessions</span>
          <ChevronRight size={17} />
        </Link>
      </div>
    );
  }

  if (course === null) {
    return (
      <div className={styles.InvalidParams}>
        <div className={styles.InvalidParamsMessage}>
          No valid course with that ID found
        </div>
        <Link to="/plan">
          <span>Go Planning Sessions</span>
          <ChevronRight size={17} />
        </Link>
      </div>
    );
  }

  if (!course) {
    return null;
  }

  const renderCoursePlanDays = () => (
    <CoursePlanDays
      className={styles.CoursePlanDays}
      id={coursePlanDaysElId}
      assignments={course.assignments}
      numberOfWeeks={course.numberOfWeeks}
      numberOfDaysPerWeek={course.numberOfDaysPerWeek}
      currentDay={currentDay}
      currentWeek={currentWeek}
      courseId={course.id}
      courseColor={course.color}
      orientation={Orientation.Vertical}
      onClickDay={handleClickDay}
    />
  );

  const renderSlideMenus = () => {
    return (
      <Fragment>
        <SlideMenu
          slideMenuClassName={styles.SidebarSlideMenu}
          backdropClassName={styles.SidebarBackdrop}
          isOpen={isSidebarSlideMenuOpen}
          onClose={handleCloseCoursePlanDaysSlideMenu}
          fromDirection={FromDirection.Left}
        >
          {renderCoursePlanDays()}
        </SlideMenu>
        <SlideMenu
          slideMenuClassName={styles.AssignmentInfoSlideMenu}
          backdropClassName={styles.AssignmentInfoBackdrop}
          isOpen={shouldShowAssignmentDetails}
          onClose={handleCloseAssignmentInfoSlideMenu}
          fromDirection={FromDirection.Right}
        >
          <Fragment>
            {isBulkUpdatingAssignments && (
              <BulkUpdateAssignmentInfo courseId={courseId} />
            )}
            {isCreatingNewAssignment && (
              <NewAssignmentInfo courseId={courseId} />
            )}
            {selectedAssignmentId && <AssignmentInfo courseId={courseId} />}
            {isCreatingRepeatingAssignments && (
              <RepeatingAssignmentInfo courseId={courseId} />
            )}
          </Fragment>
        </SlideMenu>
      </Fragment>
    );
  };

  const renderHeader = () => {
    const courseNameBackground: CSSProperties = {
      backgroundColor: colorPalette[course.color],
    };

    const courseName = (
      <div className={styles.CourseName} style={courseNameBackground}>
        {course.name}
      </div>
    );

    const searchBar = (
      <SearchBar
        className={styles.AssignmentSearchBar}
        id="assignmentListSearch"
        name="assignmentListSearch"
        placeholder="Search Assignments"
        value={searchPattern}
        onChange={(e) => dispatch(setSearchPattern(e.target.value))}
        onFocus={() => {
          dispatch(setActiveTabIndex(PlanningTabIndex.All));
          dispatch(selectAssignmentAndScrollToTop(null));
        }}
      />
    );

    const weekDaySelector = (
      <WeekDaySelector
        className={styles.WeekDaySelector}
        onClickWeekDay={handleOpenCoursePlanDaysSlideMenu}
      />
    );

    const renderBulkEditButton = (type: ButtonType = ButtonType.Primary) => {
      if (!bulkSelectedAssignments?.length || shouldShowAssignmentDetails) {
        return null;
      }

      const buttonSuffix = `${bulkSelectedAssignments.length} Assignment${
        bulkSelectedAssignments.length > 1 ? 's' : ''
      }`;

      return (
        <Fragment>
          <Button
            className={styles.ActionButton}
            type={type}
            onClick={handleClickEditAssignments}
          >
            <Edit size={17} />
            <span>{`Edit ${buttonSuffix}`}</span>
          </Button>
        </Fragment>
      );
    };

    const renderDeleteButton = () => {
      if (!bulkSelectedAssignments.length) {
        return null;
      }

      return (
        <Button
          className={styles.ActionButton}
          type={ButtonType.Minimal}
          onClick={() => setIsConfirmationPortalOpen(true)}
          color={ButtonColor.Negative}
        >
          <Trash2 size={17} />
          <span>{`Delete ${bulkSelectedAssignments.length} Assignment${
            bulkSelectedAssignments.length > 1 ? 's' : ''
          }`}</span>
        </Button>
      );
    };

    const renderCreateAssignmentButtons = () => {
      return (
        <Fragment>
          <Button
            className={styles.NewAssignmentOption}
            type={ButtonType.Minimal}
            onClick={handleClickCreateSingleAssignment}
          >
            <Plus size={17} />
            <span>Single Assignment</span>
          </Button>
          <Button
            className={styles.NewAssignmentOption}
            type={ButtonType.Minimal}
            onClick={handleClickCreateRepeatingAssignment}
          >
            <RefreshCw size={17} />
            <span>Repeating Assignments</span>
          </Button>
        </Fragment>
      );
    };

    const renderCreateAssignmentMenu = () => {
      if (bulkSelectedAssignments?.length) {
        return null;
      }

      return (
        <ButtonMenu name="New Assignments" width="15rem">
          {renderCreateAssignmentButtons()}
        </ButtonMenu>
      );
    };

    const renderAssignmentsSelectButton = () => {
      if (!bulkSelectedAssignments || shouldShowAssignmentDetails) {
        return null;
      }

      return bulkSelectedAssignments.length ? (
        <Button
          className={styles.ActionButton}
          type={ButtonType.Minimal}
          onClick={() => dispatch(deselectAllAssignments())}
        >
          <X size={17} />
          <span>Deselect All</span>
        </Button>
      ) : (
        <Button
          className={styles.ActionButton}
          type={ButtonType.Minimal}
          onClick={() => dispatch(selectAllAssignments())}
        >
          <CheckSquare size={17} />
          <span>Select All</span>
        </Button>
      );
    };

    const renderAssignmentActionMenu = () => {
      return (
        <ActionMeatballs width="15rem">
          <div className={styles.AssignmentActionButtons}>
            {!bulkSelectedAssignments?.length &&
              renderCreateAssignmentButtons()}
            {renderBulkEditButton(ButtonType.Minimal)}
            {renderDeleteButton()}
            {renderAssignmentsSelectButton()}
          </div>
        </ActionMeatballs>
      );
    };

    return (
      <Fragment>
        <Card className={styles.Header}>
          {courseName}
          {searchBar}
          <div className={styles.Actions}>
            {renderDeleteButton()}
            {renderAssignmentsSelectButton()}
            {renderBulkEditButton()}
            {renderCreateAssignmentMenu()}
          </div>
        </Card>
        <Card className={styles.SmallHeader}>
          {courseName}
          <div className={styles.Actions}>
            {activeTabIndex === PlanningTabIndex.All
              ? searchBar
              : weekDaySelector}
            {renderAssignmentActionMenu()}
          </div>
        </Card>
      </Fragment>
    );
  };

  const assignmentListWidth = shouldShowAssignmentDetails ? 350 : 950;
  const assignmentDetailsWidth = shouldShowAssignmentDetails ? 600 : 0;
  const layoutStyles: CSSProperties = {
    gridTemplateColumns: isSmallScreen
      ? '100%'
      : `250px ${assignmentListWidth}px ${assignmentDetailsWidth}px`,
  };
  const assignmentListStyles: CSSProperties = {
    marginRight: !isSmallScreen && shouldShowAssignmentDetails ? '1rem' : 0,
  };

  const renderAllTabTitle = () => {
    if (activeTabIndex === PlanningTabIndex.All && searchPattern !== '') {
      return `All (${filteredAssignments?.length} of ${numberOfAssignmentsInCourse})`;
    } else {
      return `All (${numberOfAssignmentsInCourse})`;
    }
  };

  return (
    <div className={styles.CoursePlanPage}>
      <div className={styles.ContentWrapper} style={layoutStyles}>
        {renderHeader()}
        <Card className={styles.Sidebar}>
          <Fragment>
            <WeekDaySelector className={styles.WeekDaySelector} />
            {renderCoursePlanDays()}
          </Fragment>
        </Card>
        <Tabset
          className={styles.AssignmentList}
          style={assignmentListStyles}
          activeTabIndex={activeTabIndex}
          onClickTab={handleClickTab}
        >
          <Tab
            title={`Week ${currentWeek}, Day ${currentDay} (${numberOfAssignmentsDayWeekTab})`}
          >
            <AssignmentList />
          </Tab>
          <Tab title={`Unscheduled (${numberOfAssignmentsUnscheduledTab})`}>
            <AssignmentList />
          </Tab>
          <Tab title={renderAllTabTitle()}>
            <AssignmentList />
          </Tab>
        </Tabset>
        {!isSmallScreen &&
          (selectedAssignmentId ||
            isCreatingNewAssignment ||
            isBulkUpdatingAssignments ||
            isCreatingRepeatingAssignments) && (
            <Card className={styles.AssignmentInfoCard}>
              <Fragment>
                {isBulkUpdatingAssignments && (
                  <BulkUpdateAssignmentInfo courseId={courseId} />
                )}
                {isCreatingNewAssignment && (
                  <NewAssignmentInfo courseId={courseId} />
                )}
                {selectedAssignmentId && <AssignmentInfo courseId={courseId} />}
                {isCreatingRepeatingAssignments && (
                  <RepeatingAssignmentInfo courseId={courseId} />
                )}
              </Fragment>
            </Card>
          )}
      </div>
      {isSmallScreen && renderSlideMenus()}

      <ConfirmationModal
        isOpen={isConfirmationPortalOpen}
        title={`Delete ${bulkSelectedAssignments.length} assignment${
          bulkSelectedAssignments.length > 1 ? 's' : ''
        }?`}
        body={`This cannot be undone. ${
          bulkSelectedAssignments.length > 1 ? 'These' : 'This'
        } assignment${
          bulkSelectedAssignments.length > 1 ? 's' : ''
        } will be permanently deleted.`}
        onCancel={() => setIsConfirmationPortalOpen(false)}
        onConfirm={handleDeleteAssignments}
        confirmButtonText={`Yes, Delete Assignment${
          bulkSelectedAssignments.length > 1 ? 's' : ''
        }`}
        confirmColor={ButtonColor.Negative}
        confirmIsLoading={deleteAssignments.loading}
      />
    </div>
  );
}
