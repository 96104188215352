import classnames from 'classnames';
import { HTMLProps } from 'react';
import { Edit, User } from 'react-feather';

import styles from './UserIcon.module.scss';

interface UserIconProps extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  className?: string;
  label: string;
  picture?: string | null;
  onClick?: () => void;
  toolTip?: string | JSX.Element | JSX.Element[] | null;
  showEdit?: boolean;
  showUserIcon?: boolean;
  size?: 'large' | 'medium' | 'small';
}

export default function UserIcon({
  className,
  label,
  picture,
  onClick,
  toolTip,
  showEdit = false,
  showUserIcon = false,
  size = 'medium',
  ...rest
}: UserIconProps): JSX.Element {
  const classes = classnames(className, styles.UserIcon, {
    [styles.PhotoUserIcon]: picture,
    [styles.Large]: size === 'large',
    [styles.Medium]: size === 'medium',
    [styles.Small]: size === 'small',
    [styles.Pointer]: onClick,
  });

  const contentWrapper = (wrappedContent: JSX.Element) => {
    if (!toolTip) {
      return wrappedContent;
    }

    return <div data-tippy-content={toolTip}>{wrappedContent}</div>;
  };

  const renderLabel = () => {
    if (showUserIcon && !label) {
      return <User size={64} />;
    }

    return label.charAt(0).toUpperCase();
  };

  return contentWrapper(
    <div onClick={onClick} className={classes} {...rest}>
      {picture ? (
        // TODO: adding alt text here makes things really ugly when loading
        <img src={picture} alt="" />
      ) : (
        <span className={styles.Label}>{renderLabel()}</span>
      )}
      {showEdit ? (
        <div className={styles.Edit}>
          <Edit size={17} />
        </div>
      ) : null}
    </div>
  );
}
