import classnames from 'classnames';

import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
  className?: string;
  message?: string;
}

export default function ErrorMessage({
  className,
  message,
}: ErrorMessageProps): JSX.Element | null {
  const classes = classnames(className, styles.ErrorMessage);
  return message ? (
    <div
      className={classes}
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    />
  ) : null;
}
