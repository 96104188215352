import { animated, easings, useTransition } from '@react-spring/web';
import classnames from 'classnames';
import { CSSProperties, Fragment, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'react-feather';

import Button, { ButtonSpacing, ButtonType } from 'components/Button';

import styles from './SlideMenu.module.scss';

interface SlideMenuProps {
  slideMenuClassName?: string;
  backdropClassName?: string;
  closeButtonClassName?: string;
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element | JSX.Element[];
  openFromLeft?: boolean;
  fromDirection?: FromDirection;
}

export enum FromDirection {
  Left = 'left',
  Right = 'right',
}

export default function SlideMenu({
  slideMenuClassName,
  backdropClassName,
  closeButtonClassName,
  isOpen,
  onClose,
  children,
  fromDirection = FromDirection.Left,
}: SlideMenuProps): JSX.Element | null {
  const portalRootElement: HTMLElement | null =
    document.getElementById('portal-root');

  const xTranslate = fromDirection === FromDirection.Left ? '-100%' : '100%';
  const menuTransitions = useTransition(isOpen, {
    from: { transform: `translate3d(${xTranslate},0,0)` },
    enter: { transform: 'translate3d(0%,0,0)' },
    leave: { transform: `translate3d(${xTranslate},0,0)` },
    config: {
      duration: 250,
      easing: easings.easeOutCubic,
    },
  });

  const backdropTransitions = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 250,
      easing: easings.easeOutCubic,
    },
  });

  const [slideMenuStyles, setSlideMenuStyles] = useState<CSSProperties>({});

  const handleWindowResize = () => {
    const slideMenuHeight = window?.innerHeight || 0;
    setSlideMenuStyles({
      height: `${slideMenuHeight}px`,
    });
  };

  const closeButtonStyles: CSSProperties = {
    top: '0.5rem',
    ...(fromDirection === FromDirection.Left
      ? { right: '0.5rem' }
      : { left: '0.5rem' }),
  };

  useEffect(() => {
    // set initialize height on load
    handleWindowResize();

    // add listener on resize to update height on resize
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  if (!portalRootElement) {
    return null;
  }

  const renderContent = () => {
    const classes = classnames(slideMenuClassName, styles.SlideMenu);

    return menuTransitions(
      (style, item) =>
        item && (
          <animated.div
            className={classes}
            style={{ ...style, ...slideMenuStyles }}
          >
            <Button
              className={classnames(closeButtonClassName, styles.CloseButton)}
              style={closeButtonStyles}
              type={ButtonType.Minimal}
              spacing={ButtonSpacing.Compressed}
              onClick={onClose}
            >
              <X />
            </Button>
            {children}
          </animated.div>
        )
    );
  };

  const renderBackdrop = () => {
    const classes = classnames(backdropClassName, styles.Backdrop);

    return backdropTransitions(
      (style, item) =>
        item && (
          <animated.div
            className={classes}
            style={style}
            onClick={() => onClose()}
          />
        )
    );
  };

  return createPortal(
    <Fragment>
      {renderContent()}
      {renderBackdrop()}
    </Fragment>,
    portalRootElement
  );
}
