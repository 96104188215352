export function getApiRoot(): string {
  const hostname = import.meta.env.REACT_APP_API_HOST;

  if (import.meta.env.REACT_APP_DEPLOY_ENV === 'prod') {
    return 'https://api.syllabird.com';
  } else if (import.meta.env.REACT_APP_DEPLOY_ENV === 'test') {
    return `https://${hostname}.onrender.com`;
  } else {
    return `http://${hostname}`;
  }
}

interface Headers {
  headers: {
    Authorization: string;
  };
}

export function getHeaders(token: string): Headers {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
