import { MutationUpdaterFn, gql } from '@apollo/client';
import { produce } from 'immer';

import { GET_STORAGE_USAGE } from 'graphql/queries/getStorageUsage';
import {
  DeleteFileMutation,
  GetStorageUsageQuery,
} from 'graphql/types/generated';

export const DELETE_FILE = gql`
  mutation DeleteFile($fileId: uuid!) {
    file: delete_file(id: $fileId) {
      id
      size
      parrotRequestId
    }
  }
`;

export const deleteFileUpdate: MutationUpdaterFn<DeleteFileMutation> = (
  cache,
  { data: deletedFileData }
) => {
  if (deletedFileData?.file) {
    cache.evict({ id: cache.identify(deletedFileData.file) });
    cache.gc();

    // We want to ignore when they delete files from parrot requests because
    // we don't count those against them
    if (deletedFileData.file.parrotRequestId) {
      return;
    }

    const storageUsageData = cache.readQuery<GetStorageUsageQuery>({
      query: GET_STORAGE_USAGE,
    });

    if (storageUsageData) {
      cache.writeQuery<GetStorageUsageQuery>({
        query: GET_STORAGE_USAGE,
        data: produce(storageUsageData, (dataDraft) => {
          if (dataDraft.files.aggregate?.sum?.size) {
            dataDraft.files.aggregate.sum.size -= deletedFileData.file?.size;
          }
        }),
      });
    }
  }
};
