import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { CalendarData, CalendarState, RootState } from './types';

/**
 * Reducers
 */
const initialState: CalendarState = {
  data: {},
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    updateCalendarData: (
      state: CalendarState,
      action: PayloadAction<CalendarData>
    ) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
  },
});

/**
 * Actions
 */
export const { updateCalendarData } = calendarSlice.actions;

/**
 * Selectors
 */
const getCalendar = (state: RootState): CalendarState => state.calendar;

export const getCalendarData = createSelector(
  getCalendar,
  (calendar: CalendarState) => calendar.data
);
