import { useState } from 'react';
import { HelpCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useAssignmentInfoGqlOps from 'components/AssignmentInfo/useAssignmentInfoGqlOps';
import { defaultAssignmentInfoFormValues } from 'components/AssignmentInfoForm';
import Checkbox from 'components/Checkbox';
import RepeatingAssignmentInfoForm, {
  RepeatingAssignmentInfoFormData,
} from 'components/RepeatingAssignmentInfoForm';

import { Assignments_Insert_Input } from 'graphql/types/generated';

import useTemporaryAttachments from 'hooks/useTemporaryAttachments';

import {
  cancelCreatingRepeatingAssignments,
  getAssignmentTypeWeights,
  getNumberOfDaysPerWeek,
  getParrotRequestId,
  getRepeatingAssignmentDays,
  getRepeatingDaysMap,
  getStudentCourses,
  getStudentVacations,
  selectRepeatingDay,
} from 'redux/planning';
import { useAppDispatch } from 'redux/store';
import { getIsParrotUser } from 'redux/user';

import { expandAssignmentField } from 'utils/patterns';
import { createStudentAssignmentsForAssignment } from 'utils/studentAssignment';

import styles from './RepeatingAssignmentInfo.module.scss';

interface RepeatingAssignmentInfoProps {
  courseId: string;
}

export default function RepeatingAssignmentInfo({
  courseId,
}: RepeatingAssignmentInfoProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const numberOfDaysPerWeek = useSelector(getNumberOfDaysPerWeek);
  const repeatingDaysMap = useSelector(getRepeatingDaysMap);
  const repeatingAssignmentDays = useSelector(getRepeatingAssignmentDays);
  const studentCourses = useSelector(getStudentCourses);
  const studentVacations = useSelector(getStudentVacations);
  const isParrotUser = useSelector(getIsParrotUser);
  const parrotRequestId = useSelector(getParrotRequestId);
  const assignmentTypeWeights = useSelector(getAssignmentTypeWeights);

  const [isCreatingAssignments, setIsCreatingAssignments] =
    useState<boolean>(false);

  const formMethods = useForm<RepeatingAssignmentInfoFormData>({
    defaultValues: {
      assignmentName: defaultAssignmentInfoFormValues.assignmentName,
      description: defaultAssignmentInfoFormValues.description,
      teachersNote: defaultAssignmentInfoFormValues.teachersNote,
      graded: defaultAssignmentInfoFormValues.graded,
      assignmentTypeOption: {
        label: assignmentTypeWeights[0].customAssignmentType?.name ?? '',
        value: assignmentTypeWeights[0].customAssignmentType?.id ?? null,
      },
      duration: defaultAssignmentInfoFormValues.duration,
      startWeek: null,
      endWeek: null,
    },
  });
  const { handleSubmit } = formMethods;

  const { createAssignments, anyLoading } = useAssignmentInfoGqlOps();

  const {
    tempAttachments,
    remainingAttachments,
    addTempAttachment,
    removeTempAttachment,
  } = useTemporaryAttachments();

  const handleSelectRepeatingDay = (i: number) => () => {
    dispatch(selectRepeatingDay(i));
  };

  const handleCreateRepeatingAssignment = async (
    data: RepeatingAssignmentInfoFormData
  ) => {
    setIsCreatingAssignments(true);
    const repeatingAssignments = getRepeatingAssignmentsToCreate(data);
    await createAssignments.call({
      variables: {
        assignments: repeatingAssignments,
      },
    });

    setIsCreatingAssignments(false);
    dispatch(cancelCreatingRepeatingAssignments());
  };

  const getRepeatingAssignmentsToCreate = (
    data: RepeatingAssignmentInfoFormData
  ): Assignments_Insert_Input[] => {
    const duration = data.duration || 0;
    const startWeek = data.startWeek?.value ?? 0;
    const endWeek = data.endWeek?.value ?? 0;
    const weekFrequency = data.weekFrequency?.value ?? 1;
    const startDay = data.startDay?.value ?? 1;

    const attachments = tempAttachments.map((attachment) => ({
      url: attachment.url,
      size: attachment.size,
    }));

    const sharedFields = {
      courseId,
      graded: data.graded,
      customAssignmentTypeId: data.assignmentTypeOption.value,
      duration,
      attachments: { data: attachments },
      teachersNote: data.teachersNote,
      visible: isParrotUser ? false : true,
      parrotRequestId: isParrotUser ? parrotRequestId : null,
    };

    const fullRepeatingAssignments: Assignments_Insert_Input[] = [];
    for (let week = startWeek; week <= endWeek; week = week + weekFrequency) {
      repeatingAssignmentDays.forEach((day) => {
        const studentAssignments = !isParrotUser
          ? createStudentAssignmentsForAssignment(
              studentCourses || [],
              studentVacations,
              parseInt(day, 10),
              week,
              duration
            ) || []
          : [];

        fullRepeatingAssignments.push({
          ...sharedFields,
          day: parseInt(day, 10),
          week,
          studentAssignments: { data: studentAssignments },
        });
      });
    }

    const indexOfStartDay = repeatingAssignmentDays.indexOf(
      startDay.toString()
    );
    const repeatingAssignments = fullRepeatingAssignments.slice(
      indexOfStartDay,
      fullRepeatingAssignments.length
    );

    const expandedNames = expandAssignmentField(
      data.assignmentName,
      repeatingAssignments.length
    );
    const expandedDescriptions = expandAssignmentField(
      data.description !== '<p><br></p>' ? data.description : null,
      repeatingAssignments.length
    );

    // Number of repeating assignments is limited to the Assignment Name pattern,
    // if expanding the Assignment Name returns less assignments than expected we
    // will return a number of repeating assignments to match
    return repeatingAssignments
      .slice(0, expandedNames.length)
      .map((repeatingAssignment, i) => {
        return {
          ...repeatingAssignment,
          name: expandedNames[i],
          description: expandedDescriptions[i],
        };
      });
  };

  if (!numberOfDaysPerWeek) {
    return null;
  }

  return (
    <div className={styles.RepeatingAssignmentInfo}>
      <div className={styles.RepeatingDaySelection}>
        <div className={styles.RepeatingDayHeader}>
          <div className={styles.RepeatingDayLabel}>Repeat On</div>
          <a
            className={styles.RepeatingDayHelp}
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.syllabird.com/assignments/create-assignments-with-patterns/"
          >
            <HelpCircle size={17} /> Pattern Help
          </a>
        </div>
        <div className={styles.RepeatingDayOptions}>
          {Array.from({ length: numberOfDaysPerWeek }, (_, i) => {
            const day = i + 1;
            const label = `Day ${day}`;
            const id = `repeating-day-${day}`;
            return (
              <Checkbox
                key={id}
                id={id}
                name={id}
                className={styles.RepeatingDay}
                onChange={handleSelectRepeatingDay(day)}
                isSelected={!!repeatingDaysMap[day]}
              >
                <span>{label}</span>
              </Checkbox>
            );
          })}
        </div>
      </div>
      <hr />
      <RepeatingAssignmentInfoForm
        formMethods={formMethods}
        onSubmit={handleSubmit(handleCreateRepeatingAssignment)}
        attachments={tempAttachments}
        onDeleteAttachment={removeTempAttachment}
        maxNumberOfFiles={remainingAttachments}
        onSuccessfulAttachment={addTempAttachment}
        isLoading={anyLoading || isCreatingAssignments}
      />
    </div>
  );
}
