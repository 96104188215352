import { gql } from '@apollo/client';

export const CustomAssignmentType = gql`
  fragment CustomAssignmentType on custom_assignment_types {
    id
    name
    color
    created
    sortOrder
    hidden
  }
`;
