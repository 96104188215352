import { gql } from '@apollo/client';

import { CourseAssignmentSchedule } from 'graphql/fragments/assignment';

export const GET_COURSE_ASSIGNMENTS_SCHEDULE = gql`
  query GetCourseAssignmentsSchedule($courseId: uuid!) {
    course(id: $courseId) {
      id
      assignments {
        ...CourseAssignmentSchedule
      }
    }
  }
  ${CourseAssignmentSchedule}
`;

export const GET_COURSES_ASSIGNMENTS_SCHEDULE = gql`
  query GetCoursesAssignmentsSchedule($courseIds: [uuid!]!) {
    courses(where: { id: { _in: $courseIds } }) {
      id
      numberOfDaysPerWeek
      numberOfWeeks
      assignments {
        ...CourseAssignmentSchedule
      }
    }
  }
  ${CourseAssignmentSchedule}
`;
