enum Color {
  SunsetOrange = 'SUNSETORANGE',
  Clementine = 'CLEMENTINE',
  Vermilion = 'VERMILION',
  Sunshine = 'SUNSHINE',
  Grapefruit = 'GRAPEFRUIT',
  LimeGreen = 'LIMEGREEN',
  Clover = 'CLOVER',
  Seafoam = 'SEAFOAM',
  RobinsEgg = 'ROBINSEGG',
  BleuDeFrance = 'BLEUDEFRANCE',
  Raindrop = 'RAINDROP',
  Blueberry = 'BLUEBERRY',
  Liberty = 'LIBERTY',
  Sugarplum = 'SUGARPLUM',
  PinkRose = 'PINKROSE',
  Berry = 'BERRY',
  KirbyPink = 'KIRBYPINK',
  Bubblegum = 'BUBBLEGUM',
  Dolphin = 'DOLPHIN',
  BlackBear = 'BLACKBEAR',
}

export function randomColor() {
  const colorValues = Object.values(Color);
  return colorValues[Math.floor(Math.random() * colorValues.length)];
}

export default Color;
