import { expand } from 'braces';

const unboundedNumberPattern = /{[0-9]+\.\.}/g;
const unboundedLowerCaseLetterPattern = /{[a-z]\.\.}/g;
const unboundedUpperCaseLetterPattern = /{[A-Z]\.\.}/g;
const boundedNumberPattern = /{[0-9]+\.\.[0-9]+}/g;
const boundedLowerCaseLetterPattern = /{[a-z]\.\.[a-z]}/g;
const boundedUpperCaseLetterPattern = /{[A-Z]\.\.[A-Z]}/g;
const pageRangePattern = /{[0-9]+-[0-9]+}/g;
const setPattern = /{(.*,)+.*}/g;

const bashPatterns = new RegExp(
  [
    unboundedNumberPattern,
    unboundedLowerCaseLetterPattern,
    unboundedUpperCaseLetterPattern,
    boundedNumberPattern,
    boundedLowerCaseLetterPattern,
    boundedUpperCaseLetterPattern,
    setPattern,
  ]
    .map((pattern) => pattern.source)
    .join('|')
);

const allPatterns = new RegExp(
  [
    unboundedNumberPattern,
    unboundedLowerCaseLetterPattern,
    unboundedUpperCaseLetterPattern,
    boundedNumberPattern,
    boundedLowerCaseLetterPattern,
    boundedUpperCaseLetterPattern,
    pageRangePattern,
    setPattern,
  ]
    .map((pattern) => pattern.source)
    .join('|')
);

/**
 * Splits a range of pages to be read into less than or equal to number of readings.
 *
 * @param startPage {number} first page to read
 * @param endPage {number} last page to read
 * @param numReadings {number} number of readings to split page range up into
 * @return {Array<Array<number>>} a 2d Array of numbers representing the page range to read in that reading,
 * length may be less than numReadings
 */
function pageRangeToArray(
  startPage: number,
  endPage: number,
  numReadings: number
): number[][] {
  const totalNumPages = endPage - startPage + 1;
  const allPages = Array.from(
    { length: totalNumPages },
    (_, i) => i + startPage
  );

  const pageChunks = [];
  for (let i = numReadings; i > 0; i--) {
    pageChunks.push(allPages.splice(0, Math.ceil(allPages.length / i)));
  }

  const readings = pageChunks.map((pageChunk) => {
    if (pageChunk.length === 0) {
      return [];
    } else if (pageChunk.length === 1) {
      return [pageChunk[0], pageChunk[0]];
    } else {
      return [pageChunk[0], pageChunk[pageChunk.length - 1]];
    }
  });

  return readings;
}

/**
 * Takes a string containing page range patterns ( e.g. {10-20}) and expands the reading into
 * the given assignments. If no page range patterns are found return the given assignments.
 *
 * @param assignmentFieldStr {string} string that contains page range patterns to expand
 * @param assignments {Array<string>} Array of assignments to ass page numbers to
 * @return {Array<string>} Array of assignments with the page range patterns expanded
 */
export function expandPageRanges(
  assignmentFieldStr: string,
  assignments: string[]
): string[] {
  const pageNumberPatterns = assignmentFieldStr.match(pageRangePattern);

  if (!pageNumberPatterns) {
    return assignments;
  }

  const pageNumberRanges = pageNumberPatterns.map((pageNumberPattern) =>
    pageNumberPattern.replace(/{|}/g, '').split('-').map(Number)
  );

  const dailyPageRangesList = pageNumberRanges.map((pageNumberRange) =>
    pageNumberRange.length === 2
      ? pageRangeToArray(
          pageNumberRange[0],
          pageNumberRange[1],
          assignments.length
        )
      : []
  );

  const dailyPagesStrLists = dailyPageRangesList.map((dailyPageRanges) =>
    dailyPageRanges.map((dailyPageRange) => {
      if (dailyPageRange.length !== 2) {
        return '(none)';
      }

      const start = dailyPageRange[0];
      const end = dailyPageRange[1];
      return start === end ? `${start}` : `${start}-${end}`;
    })
  );

  const expandedStrList = dailyPagesStrLists.map((dailyPagesStrList) => [
    ...dailyPagesStrList,
    ...Array.from(
      { length: assignments.length - dailyPagesStrList.length },
      (_, _i) => {
        return '(none)';
      }
    ),
  ]);

  pageNumberPatterns.forEach((pageNumberPattern, i) =>
    expandedStrList[i].forEach(
      (dailyPageStr, j) =>
        (assignments[j] = assignments[j].replace(
          pageNumberPattern,
          dailyPageStr
        ))
    )
  );

  return assignments;
}

/**
 * Takes a string containing range patterns ( e.g. {1..2}, {1..}, {one,two}) and expands into
 * a maximum of numAssignments strings.
 *
 * @param assignmentFieldStr {string} string that contains range patterns to expand
 * @param assignments {number} number of assignment to produce from expansion
 * @return {Array<string>} Array of expanded assignments of length numAssignments or less
 */
export function expandRanges(
  assignmentFieldStr: string,
  numAssignments: number
): string[] {
  assignmentFieldStr = assignmentFieldStr.replace(
    unboundedNumberPattern,
    (match) => {
      const parts = match.split('..');
      return parts.length === 2
        ? [
            parts[0],
            '..',
            numAssignments + parseInt(parts[0].substring(1)) - 1,
            parts[1],
          ].join('')
        : match;
    }
  );

  assignmentFieldStr = assignmentFieldStr.replace(
    unboundedLowerCaseLetterPattern,
    (match) => {
      const parts = match.split('..');
      return parts.length === 2
        ? [parts[0], '..', 'z', parts[1]].join('')
        : match;
    }
  );

  assignmentFieldStr = assignmentFieldStr.replace(
    unboundedUpperCaseLetterPattern,
    (match) => {
      const parts = match.split('..');
      return parts.length === 2
        ? [parts[0], '..', 'Z', parts[1]].join('')
        : match;
    }
  );

  const expandedAssignments = expand(assignmentFieldStr);

  return expandedAssignments.slice(0, numAssignments);
}

/**
 * Returns array of expanded assignment strings that is of length numAssignments or less. It supports expanding
 * the following patterns:
 * {1..}: Unbounded number or letter range
 * {1..2}: Bounded number or letter range
 * {1-10}: Page number range
 * {one,two,three}: Item set
 *
 * @param assignmentFieldStr {string | null} string to expand into numAssignments if null return
 * numAssignments duplicates of this string
 * @param numAssignments {number} number of assignments needed
 * @return {Array<string>} Array of expanded assignments of length numAssignments or less
 */
export function expandAssignmentField(
  assignmentFieldStr: string | null,
  numAssignments: number
): string[] {
  if (!assignmentFieldStr || !assignmentFieldStr?.match(allPatterns)?.length) {
    return Array(numAssignments).fill(assignmentFieldStr);
  }

  let expandedAssignments: string[] = [];
  if (assignmentFieldStr.match(bashPatterns)?.length) {
    expandedAssignments = expandRanges(assignmentFieldStr, numAssignments);
    expandedAssignments = expandPageRanges(
      assignmentFieldStr,
      expandedAssignments
    );
  } else {
    expandedAssignments = Array(numAssignments).fill(assignmentFieldStr);
    expandedAssignments = expandPageRanges(
      assignmentFieldStr,
      expandedAssignments
    );
  }

  return expandedAssignments;
}
