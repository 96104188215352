import classnames from 'classnames';
import { Link } from 'react-router-dom';

import DayPointer from 'components/DayPointer';

import { useTheme } from 'context/Theme';
import { HEX_CODE_OPACITY } from 'context/Theme/constants';

import { Assignments } from 'graphql/types/generated';

import styles from './CoursePlanDays.module.scss';

export enum Orientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export function getCoursePlanDayElId(
  courseId: string,
  day: number,
  week: number
) {
  return `course-${courseId}-week-${week}-day-${day}`;
}

/**
 * TODO: test this
 * Return a 2D array of the number of assignments assigned on each day of a course
 * @param assignments assignments in a course
 * @param numberOfWeeks duration of course in weeks
 * @param numberOfDaysPerWeek  number of days the course has each week
 */
function getAssignmentCounts(
  assignments: Pick<Assignments, 'id' | 'week' | 'day'>[],
  numberOfWeeks: number,
  numberOfDaysPerWeek: number
): number[][] {
  // initialize assignment count to 0 for all days
  let assignmentCounts = Array(numberOfWeeks).fill(0);
  for (let week = 0; week < numberOfWeeks; week++) {
    assignmentCounts[week] = Array(numberOfDaysPerWeek).fill(0);
  }

  assignments.forEach((assignment) => {
    if (assignment.week && assignment.day) {
      assignmentCounts[assignment.week - 1][assignment.day - 1] += 1;
    }
  });

  return assignmentCounts;
}

interface CoursePlanDaysProps {
  className?: string;
  id?: string;
  assignments: Pick<Assignments, 'id' | 'week' | 'day'>[];
  numberOfWeeks: number;
  numberOfDaysPerWeek: number;
  currentWeek: number | undefined;
  currentDay: number | undefined;
  courseId: string;
  courseColor: string;
  orientation: Orientation;
  dayLink?: string;
  onClickDay?: (day: number, week: number) => void;
}

export default function CoursePlanDays({
  className,
  id,
  assignments,
  numberOfWeeks,
  numberOfDaysPerWeek,
  currentWeek,
  currentDay,
  courseId,
  courseColor,
  orientation,
  dayLink,
  onClickDay,
}: CoursePlanDaysProps): JSX.Element {
  const { theme } = useTheme();

  const weeks = Array.from(Array(numberOfWeeks).keys());
  const days = Array.from(Array(numberOfDaysPerWeek).keys());
  const assignmentCounts = getAssignmentCounts(
    assignments,
    numberOfWeeks,
    numberOfDaysPerWeek
  );

  const getDayInlineStyles = (
    numberOfAssignments: number,
    color: string,
    isCurrentDay: boolean
  ) => {
    let styles: any = {};
    if (isCurrentDay && numberOfAssignments > 0) {
      styles = {
        backgroundColor: `${theme.primary}${HEX_CODE_OPACITY[25]}`,
        border: `1px solid ${theme.primary}`,
        color: theme.primary,
      };
    } else if (isCurrentDay && numberOfAssignments === 0) {
      styles = {
        backgroundColor: theme.contentBackground,
        border: `1px solid ${theme.primary}`,
        color: theme.primary,
      };
    } else if (numberOfAssignments > 0) {
      styles = {
        backgroundColor: `${color}${HEX_CODE_OPACITY[25]}`,
        border: `1px solid ${color}`,
        color: color,
      };
    } else {
      styles = {
        backgroundColor: theme.contentBackground,
        border: `1px solid ${theme.elementBorder}`,
        color: theme.contentTertiary,
      };
    }

    if (onClickDay) {
      styles.cursor = 'pointer';
    }

    return styles;
  };

  const renderDayContainer = (
    isCurrentDay: boolean,
    assignmentCount: number,
    day: number,
    week: number
  ) => {
    const attributes: any = {
      style: getDayInlineStyles(
        assignmentCount,
        theme.colorPalette[courseColor],
        isCurrentDay
      ),
    };

    if (onClickDay) {
      attributes.onClick = () => onClickDay(day, week);
    }

    const dayContainerId = getCoursePlanDayElId(courseId, day, week);

    return (
      <div
        id={dayContainerId}
        key={dayContainerId}
        className={styles.DayContainer}
        data-tippy-content={`<div class=${
          styles.DayToolTip
        }><span>Week ${week} | Day ${day}</span><span>${
          assignmentCount
            ? `${assignmentCount} Assignment${assignmentCount !== 1 ? 's' : ''}`
            : ''
        }</span></div>`}
      >
        {isCurrentDay ? <DayPointer className={styles.DayPointer} /> : null}
        {dayLink ? (
          <Link to={dayLink}>
            <div className={styles.DayLink} {...attributes} />
          </Link>
        ) : (
          <div className={styles.DayLink} {...attributes} />
        )}
      </div>
    );
  };

  const weeksStyles = classnames(
    className,
    { [styles.WeeksHorizontal]: orientation === Orientation.Horizontal },
    { [styles.WeeksVertical]: orientation === Orientation.Vertical }
  );
  const weekStyles = classnames(
    { [styles.WeekHorizontal]: orientation === Orientation.Horizontal },
    { [styles.WeekVertical]: orientation === Orientation.Vertical }
  );

  return (
    <div id={id} className={weeksStyles}>
      {weeks.map((weekIndex: number) => {
        return (
          <div key={`plan-week-${weekIndex}`} className={weekStyles}>
            <span className={styles.WeekNumber}>{weekIndex + 1}</span>
            {days.map((dayIndex: number) => {
              const day = dayIndex + 1;
              const week = weekIndex + 1;

              const isCurrentDay = currentDay === day && currentWeek === week;

              const assignmentCount = assignmentCounts[weekIndex][dayIndex];
              return renderDayContainer(
                isCurrentDay,
                assignmentCount,
                day,
                week
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
