import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { calendarSlice } from './calendar';
import { coursesSlice } from './courses';
import { homeSlice } from './home';
import { homeFiltersLocalStorageMiddleware } from './middleware';
import { planningSlice } from './planning';
import { userSlice } from './user';

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    calendar: calendarSlice.reducer,
    user: userSlice.reducer,
    planning: planningSlice.reducer,
    courses: coursesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(homeFiltersLocalStorageMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>(); // Export a hook that can be reused to resolve types

export default store;
