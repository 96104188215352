import { useTheme } from 'context/Theme';

interface DayPointerProps {
  className?: string;
  color?: string;
}

export default function DayPointer({ className, color }: DayPointerProps) {
  const { theme } = useTheme();

  return (
    <svg
      className={className}
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L5 5.5L9 1L5 3L1 1Z"
        fill={color || theme.primary}
        stroke={color || theme.primary}
        strokeLinejoin="round"
      />
    </svg>
  );
}
