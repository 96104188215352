import CalculatedStudentAssignmentStatus from 'enums/CalculatedStudentAssignmentStatus';

import { Student_Assignment_Status_Enum } from 'graphql/types/generated';

export const studentAssignmentStatusLabels = {
  [Student_Assignment_Status_Enum.Incomplete]: 'Incomplete',
  [Student_Assignment_Status_Enum.Complete]: 'Complete',
  [CalculatedStudentAssignmentStatus.Overdue]: 'Overdue',
  [CalculatedStudentAssignmentStatus.DueToday]: 'Due Today',
  [CalculatedStudentAssignmentStatus.InProgress]: 'In Progress',
  [CalculatedStudentAssignmentStatus.NeedsGrading]: 'Needs Grading',
};
