import { FormEventHandler, useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types/errors';
import { useSelector } from 'react-redux';
import { Options } from 'react-select';

import { Attachment } from 'components/AssignmentInfoForm';
import Button, { ButtonColor, ButtonType } from 'components/Button';
import Checkbox from 'components/Checkbox';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import ErrorMessage from 'components/ErrorMessage';
import FileList from 'components/FileList';
import { UploadedFile } from 'components/FileUploader';
import FileUploader from 'components/FileUploader';
import InputField, { InputFieldType } from 'components/InputField';
import RichTextArea, { maxLengthRichTextArea } from 'components/RichTextArea';
import Tab from 'components/Tab';
import Tabset from 'components/Tabset';

import {
  cancelCreatingRepeatingAssignments,
  getAssignmentTypeWeights,
  getNumberOfDaysPerWeek,
  getNumberOfWeeks,
  getRepeatingAssignmentDays,
} from 'redux/planning';
import { useAppDispatch } from 'redux/store';

import {
  generateNDropdownOptions,
  generateNDropdownOptionsFromList,
  generateNDropdownOptionsFromStart,
} from 'utils/generateNDropdownOptions';

import styles from './RepeatingAssignmentInfoForm.module.scss';

export const assignmentInfoFieldsId = 'repeating-assignment-info-fields';
export const assignmentNameId = 'repeating-assignment-name';

export interface RepeatingAssignmentInfoFormData {
  assignmentName: string;
  description: string;
  teachersNote: string;
  graded: boolean;
  assignmentTypeOption: DropdownOption<string | null>;
  duration: number;
  startWeek: DropdownOption<number> | null;
  endWeek: DropdownOption<number> | null;
  weekFrequency: DropdownOption<number> | null;
  startDay: DropdownOption<number> | null;
}

interface RepeatingAssignmentInfoFormProps {
  formMethods: UseFormReturn<RepeatingAssignmentInfoFormData>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  attachments?: Attachment[];
  maxNumberOfFiles?: number;
  onDeleteAttachment?: (attachmentId: string) => void;
  onSuccessfulAttachment?: (files: UploadedFile[]) => void;
  isLoading?: boolean;
}

export default function RepeatingAssignmentInfoForm({
  formMethods,
  onSubmit,
  attachments,
  maxNumberOfFiles,
  onDeleteAttachment,
  onSuccessfulAttachment,
  isLoading,
}: RepeatingAssignmentInfoFormProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [assignmentTypeOptions, setAssignmentTypeOptions] = useState<
    Options<DropdownOption<string | null>>
  >([]);
  const [startWeekOptions, setStartWeekOptions] = useState<
    Options<DropdownOption<number>>
  >([]);
  const [endWeekOptions, setEndWeekOptions] = useState<
    Options<DropdownOption<number>>
  >([]);
  const [weekFrequencyOptions, setWeekFrequencyOptions] = useState<
    Options<DropdownOption<number>>
  >([]);
  const [startDayOptions, setStartDayOptions] = useState<
    Options<DropdownOption<number>>
  >([]);

  const [weeksError, setWeeksError] = useState<FieldError | undefined>(
    undefined
  );

  const assignmentTypeWeights = useSelector(getAssignmentTypeWeights);
  const numberOfWeeks = useSelector(getNumberOfWeeks);
  const numberOfDaysPerWeek = useSelector(getNumberOfDaysPerWeek);
  const repeatingAssignmentDays = useSelector(getRepeatingAssignmentDays);
  const isRepeatingAssignmentDaysValid = !!repeatingAssignmentDays?.length;

  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = formMethods;

  const startWeek = watch('startWeek');
  const endWeek = watch('endWeek');
  const weekFrequency = watch('weekFrequency');

  const areFieldsDisabled = !isRepeatingAssignmentDaysValid || isLoading;

  const weeksAreValid = () => {
    const areWeeksSet = getValues('startWeek') && getValues('endWeek');

    if (!areWeeksSet) {
      setWeeksError({
        type: 'weeksAreValid',
        message: 'Start and end week must both be selected',
      });
      return false;
    } else {
      setWeeksError(undefined);
      return true;
    }
  };

  useEffect(() => {
    if (assignmentTypeWeights.length) {
      const assignmentTypeOptions = assignmentTypeWeights.map(
        (assignmentTypeWeight) => {
          return {
            label: assignmentTypeWeight.customAssignmentType?.name ?? '',
            value: assignmentTypeWeight.customAssignmentType?.id ?? null,
          };
        }
      );

      setAssignmentTypeOptions(assignmentTypeOptions);
    }
  }, [assignmentTypeWeights]);

  // initialize the start, end, frequency, and start day options based on the number of weeks
  // and number of days per week
  useEffect(() => {
    if (
      numberOfWeeks &&
      numberOfDaysPerWeek &&
      !startWeekOptions.length &&
      !endWeekOptions.length &&
      !weekFrequencyOptions.length &&
      !startDayOptions.length
    ) {
      const options = generateNDropdownOptions('Week', numberOfWeeks);
      setStartWeekOptions(options);
      setEndWeekOptions(options);

      const frequencyOptions = generateNDropdownOptions(
        'Week',
        numberOfWeeks > 1 ? numberOfWeeks - 1 : numberOfWeeks,
        true
      );
      setWeekFrequencyOptions(frequencyOptions);
      setValue('weekFrequency', frequencyOptions?.[0] ?? null);

      const startOptions = generateNDropdownOptions('Day', numberOfDaysPerWeek);
      setStartDayOptions(startOptions);
      setValue('startDay', startOptions?.[0] ?? null);
    }
  }, [
    numberOfWeeks,
    startWeekOptions,
    endWeekOptions,
    weekFrequencyOptions,
    numberOfDaysPerWeek,
    startDayOptions,
    setValue,
  ]);

  // update the end week options and selection based on the current selection for week start
  useEffect(() => {
    if (startWeek && numberOfWeeks) {
      const startingEndWeek = startWeek.value;

      // we want users to be able to select the same start/end week
      // so that they can add duplicate assignments over one week
      const numRemainingEndWeeks = numberOfWeeks - startWeek.value + 1;

      const updatedEndWeekOptions = generateNDropdownOptionsFromStart(
        'Week',
        numRemainingEndWeeks,
        startingEndWeek
      );

      setEndWeekOptions(updatedEndWeekOptions);

      const resetEndWeek = endWeek && startWeek.value > endWeek.value;
      resetEndWeek && setValue('endWeek', null);
    }
  }, [startWeek, endWeek, numberOfWeeks, setValue]);

  // update the start day options based on the repeat days selected
  useEffect(() => {
    if (repeatingAssignmentDays.length) {
      const startOptions = generateNDropdownOptionsFromList(
        'Day',
        repeatingAssignmentDays.map(Number)
      );

      setStartDayOptions(startOptions);
      setValue('startDay', startOptions?.[0] ?? null);
    }
  }, [repeatingAssignmentDays, setValue]);

  // update the week frequency options based on the start and end weeks selected
  useEffect(() => {
    if (endWeek?.value && startWeek?.value) {
      let lastWeek = 1;

      if (endWeek.value - startWeek.value < 0 && numberOfWeeks) {
        lastWeek = numberOfWeeks - startWeek.value;
      } else if (endWeek.value - startWeek.value > 0) {
        lastWeek = endWeek.value - startWeek.value;
      }

      const frequencyOptions = generateNDropdownOptionsFromStart(
        'Week',
        lastWeek,
        1,
        true
      );

      if (!weekFrequency || weekFrequency.value > frequencyOptions.length) {
        setValue('weekFrequency', frequencyOptions?.[0] ?? null);
      }

      setWeekFrequencyOptions(frequencyOptions);
    }
  }, [startWeek, endWeek, weekFrequency, numberOfWeeks, setValue]);

  const renderAttachments = () => {
    if (
      maxNumberOfFiles === undefined ||
      onSuccessfulAttachment === undefined ||
      areFieldsDisabled
    ) {
      return null;
    }

    return (
      <div className={styles.Attachments}>
        <FileList
          className={styles.AttachmentsList}
          label="Attachments"
          files={attachments}
          maxFileNameLength={45}
          onDeleteFile={onDeleteAttachment}
          hideLabel
        />
        <FileUploader
          name="assignmentAttachmentsUploader"
          height="8rem"
          width="100%"
          note="Upload PDFs, text documents, presentations, spreadsheets, or images"
          maxNumberOfFiles={maxNumberOfFiles}
          onSuccess={onSuccessfulAttachment}
          checkStorageQuota
        />
      </div>
    );
  };

  return (
    <form onSubmit={onSubmit} className={styles.RepeatingAssignmentInfoForm}>
      <div className={styles.FormFields}>
        <div className={styles.Weeks}>
          <div className={styles.WeeksOptions}>
            <Controller
              name="startWeek"
              render={({ field: { value, name, onChange } }) => (
                <Dropdown
                  className={styles.StartWeek}
                  id="start-week"
                  label="From"
                  placeholder="Select Week"
                  options={startWeekOptions}
                  disabled={areFieldsDisabled}
                  showRequiredIndicator
                  errors={weeksError}
                  hideErrorMessage
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              rules={{
                validate: {
                  weeksAreValid,
                },
              }}
            />
            <Controller
              name="endWeek"
              render={({ field: { value, name, onChange } }) => (
                <Dropdown
                  className={styles.EndWeek}
                  id="end-week"
                  label="To"
                  placeholder="Select Week"
                  options={endWeekOptions}
                  disabled={areFieldsDisabled}
                  showRequiredIndicator
                  errors={weeksError}
                  hideErrorMessage
                  name={name}
                  value={value}
                  onChange={onChange}
                />
              )}
              control={control}
              rules={{
                validate: {
                  weeksAreValid,
                },
              }}
            />
          </div>
          <ErrorMessage message={weeksError?.message} />
        </div>
        <Controller
          name="startDay"
          render={({ field: { value, name, onChange } }) => (
            <Dropdown
              className={styles.StartDay}
              id="start day"
              label="Start Day"
              placeholder=""
              options={startDayOptions}
              disabled={areFieldsDisabled}
              showRequiredIndicator
              name={name}
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
          rules={{ required: true }}
        />
        <Controller
          name="weekFrequency"
          render={({ field: { value, name, onChange } }) => (
            <Dropdown
              className={styles.WeekFrequency}
              id="week-frequency"
              label="Repeat Every"
              placeholder=""
              options={weekFrequencyOptions}
              disabled={areFieldsDisabled}
              showRequiredIndicator
              name={name}
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
          rules={{ required: true }}
        />
        <Controller
          name="assignmentTypeOption"
          render={({ field: { value, name, onChange } }) => (
            <Dropdown
              className={styles.AssignmentType}
              id="assignment-type"
              label="Select Type"
              placeholder="Select Type"
              options={assignmentTypeOptions}
              disabled={areFieldsDisabled}
              showRequiredIndicator
              name={name}
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
          rules={{ required: true }}
        />
        <Controller
          name="graded"
          render={({ field: { value, name, onChange } }) => (
            <Checkbox
              className={styles.Graded}
              id="graded"
              name={name}
              isSelected={value}
              onChange={onChange}
              disabled={areFieldsDisabled}
            >
              <span>Graded</span>
            </Checkbox>
          )}
          control={control}
        />
        <InputField
          className={styles.Name}
          id={assignmentNameId}
          name="assignmentName"
          label="Assignment Name"
          placeholder="New Assignment"
          register={register('assignmentName', {
            required: 'Assignment name is required',
            maxLength: {
              value: 280,
              message:
                'Assignment names/patterns can not be more than 280 characters',
            },
          })}
          errors={errors.assignmentName}
          type={InputFieldType.Text}
          showRequiredIndicator={true}
          disabled={areFieldsDisabled}
        />
        <InputField
          className={styles.Duration}
          id="duration"
          name="duration"
          label="Due In"
          postfix="day(s)"
          helpMessage="Leave empty if due on the same day"
          register={register('duration', {
            valueAsNumber: true,
            min: {
              value: 0,
              message: 'Duration must be between 0 and 365',
            },
            max: {
              value: 365,
              message: 'Duration can be no more than 365 days',
            },
          })}
          type={InputFieldType.Number}
          errors={errors.duration}
          disabled={areFieldsDisabled}
        />
        <Controller
          name="description"
          render={({ field: { value, onChange } }) => (
            <RichTextArea
              className={styles.Description}
              id="description"
              label="Description"
              errors={errors.description}
              disabled={areFieldsDisabled}
              parentId={assignmentInfoFieldsId}
              value={value}
              onChange={onChange}
            />
          )}
          control={control}
          rules={{
            validate: {
              maxLength: (value) =>
                maxLengthRichTextArea(
                  value,
                  5_000,
                  'Description can be no more than 5,000 characters'
                ),
            },
          }}
        />
        <Tabset className={styles.Tabs}>
          <Tab title={`Attachments (${attachments?.length ?? 0})`}>
            {renderAttachments() ?? (
              <div className={styles.EmptyState}>
                Select repeating days to add attachments
              </div>
            )}
          </Tab>
          <Tab title="Teacher's Notes">
            <Controller
              name="teachersNote"
              render={({ field: { value, onChange } }) => (
                <RichTextArea
                  className={styles.TeachersNote}
                  label={"Teacher's Notes"}
                  id="teachers-note"
                  errors={errors.teachersNote}
                  disabled={areFieldsDisabled}
                  parentId={assignmentInfoFieldsId}
                  value={value}
                  onChange={onChange}
                  hideLabel
                />
              )}
              control={control}
              rules={{
                validate: {
                  maxLength: (value) =>
                    maxLengthRichTextArea(
                      value,
                      5_000,
                      "Teacher's notes can be no more than 5,000 characters"
                    ),
                },
              }}
            />
          </Tab>
        </Tabset>
      </div>
      <div className={styles.Buttons}>
        <Button
          className={styles.SecondaryButton}
          submit={false}
          color={ButtonColor.Normal}
          type={ButtonType.Secondary}
          onClick={() => dispatch(cancelCreatingRepeatingAssignments())}
          disabled={isLoading}
        >
          <span>Cancel</span>
        </Button>
        <Button
          className={styles.PrimaryButton}
          submit={true}
          color={ButtonColor.Normal}
          type={ButtonType.Primary}
          loading={isLoading}
          disabled={areFieldsDisabled}
        >
          <span>Create Assignments</span>
        </Button>
      </div>
    </form>
  );
}
