import { ApolloCache, MutationUpdaterFn, gql } from '@apollo/client';
import { produce } from 'immer';

import { AssignmentInfo } from 'graphql/fragments/assignment';
import { ActiveStudentCourseCardInfo } from 'graphql/fragments/studentCourse';
import { GET_COURSE_ASSIGNMENTS_SCHEDULE } from 'graphql/queries/getCourseAssignmentsSchedule';
import {
  GET_PLANNING_COURSE,
  GET_PLANNING_COURSE_FOR_PARROT,
} from 'graphql/queries/getPlanningCourse';
import { GET_STORAGE_USAGE } from 'graphql/queries/getStorageUsage';
import {
  ActiveStudentCourseCardInfoFragment,
  AssignmentInfoFragment,
  CreateAssignmentMutation,
  CreateAssignmentsMutation,
  GetCourseAssignmentsScheduleQuery,
  GetPlanningCourseForParrotQuery,
  GetPlanningCourseQuery,
  GetStorageUsageQuery,
  Maybe,
} from 'graphql/types/generated';

export const CREATE_ASSIGNMENT = gql`
  mutation CreateAssignment($assignment: assignments_insert_input!) {
    assignment: insert_assignment(object: $assignment) {
      ...AssignmentInfo
    }
  }
  ${AssignmentInfo}
`;

export const CREATE_ASSIGNMENTS = gql`
  mutation CreateAssignments($assignments: [assignments_insert_input!]!) {
    assignments: insert_assignments(objects: $assignments) {
      returning {
        ...AssignmentInfo
      }
    }
  }
  ${AssignmentInfo}
`;

const updatePlanningCourseCache = (
  cache: ApolloCache<CreateAssignmentsMutation>,
  assignments: AssignmentInfoFragment[],
  courseId: string
) => {
  const courseData = cache.readQuery<GetPlanningCourseQuery>({
    query: GET_PLANNING_COURSE,
    variables: {
      courseId,
    },
  });

  if (courseData) {
    cache.writeQuery<GetPlanningCourseQuery>({
      query: GET_PLANNING_COURSE,
      variables: {
        courseId,
      },
      data: produce(courseData, (dataDraft) => {
        dataDraft.course?.assignments?.push(...assignments);
      }),
    });
  }
};

const updateCourseAssignmentScheduleCache = (
  cache: ApolloCache<CreateAssignmentsMutation>,
  assignments: AssignmentInfoFragment[],
  courseId: string
) => {
  const courseAssignmentsScheduleData =
    cache.readQuery<GetCourseAssignmentsScheduleQuery>({
      query: GET_COURSE_ASSIGNMENTS_SCHEDULE,
      variables: {
        courseId,
      },
    });

  if (courseAssignmentsScheduleData) {
    cache.writeQuery<GetCourseAssignmentsScheduleQuery>({
      query: GET_COURSE_ASSIGNMENTS_SCHEDULE,
      variables: {
        courseId,
      },
      data: produce(courseAssignmentsScheduleData, (dataDraft) => {
        dataDraft.course?.assignments?.push(...assignments);
      }),
    });
  }
};

const updateParrotPlanningCourseCache = (
  cache: ApolloCache<CreateAssignmentsMutation>,
  assignments: AssignmentInfoFragment[],
  courseId: string,
  parrotRequestId?: Maybe<string>
) => {
  if (parrotRequestId) {
    const courseData = cache.readQuery<GetPlanningCourseForParrotQuery>({
      query: GET_PLANNING_COURSE_FOR_PARROT,
      variables: {
        courseId,
        parrotRequestId,
      },
    });
    if (courseData) {
      cache.writeQuery<GetPlanningCourseForParrotQuery>({
        query: GET_PLANNING_COURSE_FOR_PARROT,
        variables: {
          courseId,
          parrotRequestId,
        },
        data: produce(courseData, (dataDraft) => {
          dataDraft.course?.assignments?.push(...assignments);
        }),
      });
    }
  }
};

const updateActiveStudentCourseCardInfoCache = (
  cache: ApolloCache<CreateAssignmentsMutation>,
  assignments: AssignmentInfoFragment[]
) => {
  assignments?.forEach((assignment) => {
    assignment.studentAssignments.forEach((studentAssignment) => {
      const studentCourseCardInfoData =
        cache.readFragment<ActiveStudentCourseCardInfoFragment>({
          id: cache.identify(studentAssignment.studentCourse),
          fragment: ActiveStudentCourseCardInfo,
          fragmentName: 'ActiveStudentCourseCardInfo',
        });

      if (studentCourseCardInfoData) {
        cache.writeFragment<ActiveStudentCourseCardInfoFragment>({
          id: cache.identify(studentAssignment.studentCourse),
          fragment: ActiveStudentCourseCardInfo,
          fragmentName: 'ActiveStudentCourseCardInfo',
          data: produce(studentCourseCardInfoData, (dataDraft) => {
            dataDraft.studentAssignments.push(studentAssignment);
          }),
        });
      }
    });
  });
};

export const updateStorageUsageCache = (
  cache: ApolloCache<CreateAssignmentsMutation>,
  assignments: AssignmentInfoFragment[]
) => {
  const storageUsageData = cache.readQuery<GetStorageUsageQuery>({
    query: GET_STORAGE_USAGE,
  });
  if (storageUsageData) {
    const newFilesSize = assignments?.reduce((overallTotal, assignment) => {
      const assignmentTotal =
        assignment.attachments?.reduce(
          (assignmentTotal, attachment) => assignmentTotal + attachment.size,
          0
        ) ?? 0;
      return overallTotal + assignmentTotal;
    }, 0);

    cache.writeQuery<GetStorageUsageQuery>({
      query: GET_STORAGE_USAGE,
      data: produce(storageUsageData, (dataDraft) => {
        if (dataDraft.files.aggregate?.sum?.size) {
          dataDraft.files.aggregate.sum.size += newFilesSize;
        }
      }),
    });
  }
};

const updateCache = (
  cache: ApolloCache<CreateAssignmentsMutation>,
  assignments: AssignmentInfoFragment[],
  courseId: string,
  parrotRequestId?: Maybe<string>
) => {
  updatePlanningCourseCache(cache, assignments, courseId);
  updateCourseAssignmentScheduleCache(cache, assignments, courseId);
  updateParrotPlanningCourseCache(
    cache,
    assignments,
    courseId,
    parrotRequestId
  );
  updateActiveStudentCourseCardInfoCache(cache, assignments);
  updateStorageUsageCache(cache, assignments);
};

export const createAssignmentUpdate: MutationUpdaterFn<CreateAssignmentMutation> =
  (cache, { data }) => {
    const newAssignments = data?.assignment ? [data?.assignment] : [];

    if (!newAssignments?.length) {
      return;
    }

    const { courseId, parrotRequestId } =
      data?.assignment as AssignmentInfoFragment;

    updateCache(cache, newAssignments, courseId, parrotRequestId);
  };

export const createAssignmentsUpdate: MutationUpdaterFn<CreateAssignmentsMutation> =
  (cache, { data }) => {
    const newAssignments = data?.assignments?.returning || [];

    if (!newAssignments?.length) {
      return;
    }

    const courseId = newAssignments[0].courseId;
    const parrotRequestId = newAssignments[0].parrotRequestId;

    updateCache(cache, newAssignments, courseId, parrotRequestId);
  };
