import Tippy from '@tippyjs/react';
import classnames from 'classnames';
import React from 'react';
import { HelpCircle } from 'react-feather';
import { FieldError } from 'react-hook-form/dist/types/errors';
import InputMask from 'react-input-mask';
import 'tippy.js/dist/tippy.css';

import ErrorMessage from 'components/ErrorMessage';

import styles from './InputField.module.scss';

export enum InputFieldType {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  Number = 'number',
  Telephone = 'tel',
}

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  postfix?: string;
  defaultValue?: string | number;
  disabled?: boolean;
  register?: any;
  errors?: FieldError | undefined;
  type?: InputFieldType;
  helpMessage?: string | null;
  showRequiredIndicator?: boolean;
  hideLabel?: boolean;
  errorMessageClassName?: string;
  // For InputMask variants only
  mask?: string;
  alwaysShowMask?: boolean | undefined;
  maskChar?: string | null;
}

// TODO: make input not grow with one line error messages, but grow with multiline messages
export default function InputField({
  className,
  id,
  label,
  postfix,
  defaultValue,
  disabled,
  register,
  errors,
  type = InputFieldType.Text,
  helpMessage,
  showRequiredIndicator = false,
  hideLabel = false,
  errorMessageClassName = '',
  step,
  mask,
  alwaysShowMask,
  maskChar,
  ...restInputProps
}: InputFieldProps): JSX.Element {
  const isMaskedInput = !!mask || alwaysShowMask;

  const renderRequiredIndicator = (): JSX.Element | null =>
    showRequiredIndicator ? (
      <span className={styles.RequiredIndicator}>(Required)</span>
    ) : null;

  const renderHelpIcon = (): JSX.Element | null =>
    helpMessage ? (
      <Tippy
        content={
          <div
            dangerouslySetInnerHTML={{
              __html: helpMessage,
            }}
          />
        }
      >
        <div className={styles.HelpCircleWrapper}>
          <HelpCircle className={styles.HelpCircle}></HelpCircle>
        </div>
      </Tippy>
    ) : null;

  const labelClasses: string = classnames(className, styles.InputField, {
    [styles.HiddenLabel]: hideLabel,
    [styles.Disabled]: disabled,
  });

  const inputClasses: string = classnames(styles.InputFieldInput, {
    [styles.ErroredInputFieldInput]: errors,
  });

  const renderInput = () => {
    const sharedProps = {
      className: inputClasses,
      id,
      type,
      step: step ?? 1,
      autoComplete: 'off',
      defaultValue,
      disabled,
      ...restInputProps,
      ...register,
    };

    return isMaskedInput ? (
      <InputMask
        mask={mask}
        alwaysShowMask={alwaysShowMask}
        maskChar={maskChar}
        {...sharedProps}
      />
    ) : (
      <input {...sharedProps} />
    );
  };

  return (
    <div className={labelClasses}>
      <label className={styles.Label} htmlFor={id}>
        {label ? (
          <div className={styles.LabelContent}>
            {label} {renderRequiredIndicator()} {renderHelpIcon()}
          </div>
        ) : null}
        <div className={styles.Input}>
          {renderInput()}
          {postfix ? <div className={styles.Postfix}>{postfix}</div> : null}
        </div>
      </label>
      <ErrorMessage
        message={errors?.message}
        className={errorMessageClassName}
      />
    </div>
  );
}
