import classnames from 'classnames';
import { HTMLProps } from 'react';

import styles from './CircularLoader.module.scss';

export interface CircularLoaderProps
  extends Pick<HTMLProps<HTMLSpanElement>, 'className'> {}

export default function CircularLoader({
  className,
}: CircularLoaderProps): JSX.Element {
  return <span className={classnames(styles.CircularLoader, className)} />;
}
