import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { getUserEmail, getUserId } from 'redux/user';

import { version } from '../../package.json';

export default function useSentry() {
  const userId = useSelector(getUserId);
  const userEmail = useSelector(getUserEmail);

  if (userId && userEmail) {
    init({
      dsn: 'https://86f17fff488646ce89c4a9ca637004d9@o438453.ingest.sentry.io/5403170',
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          useEffect: useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      tracesSampleRate: 0.5,
      environment: import.meta.env.REACT_APP_DEPLOY_ENV ?? 'unknown',
      release: version,
      initialScope: {
        user: { id: userId, email: userEmail },
      },
    });
  }
}
