import { AlertCircle, CheckCircle, Info, X } from 'react-feather';
import { ToastContainer, TypeOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button, { ButtonSpacing, ButtonType } from 'components/Button';

import styles from './Toast.module.scss';

interface CloseButtonProps {
  closeToast: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const CloseButton = ({ closeToast }: CloseButtonProps): JSX.Element => (
  <Button
    className={styles.Close}
    spacing={ButtonSpacing.Compressed}
    type={ButtonType.Minimal}
    onClick={closeToast}
  >
    <X size={17} />
  </Button>
);

interface ToastBodyProps {
  title: string;
  type: TypeOptions;
  content?: string;
  onClick?: () => void;
  buttonText?: String;
}

const ToastBody = ({
  title,
  type = 'info',
  content,
  onClick,
  buttonText,
}: ToastBodyProps): JSX.Element => {
  const renderIcon = () => {
    if (type === 'success') {
      return <CheckCircle className={styles.IconSuccess} size={24} />;
    } else if (type === 'error') {
      return <AlertCircle className={styles.IconError} size={24} />;
    } else {
      return <Info className={styles.IconInfo} size={24} />;
    }
  };

  return (
    <div className={styles.ToastBody}>
      {renderIcon()}
      <div className={styles.Content}>
        <div className={styles.Text}>
          <h1>{title}</h1>
          {content ? <p>{content}</p> : null}
        </div>
        {onClick && buttonText ? (
          <div className={styles.Button}>
            <Button
              spacing={ButtonSpacing.Compressed}
              type={ButtonType.Primary}
              onClick={onClick}
            >
              <span>{buttonText}</span>
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default function Toast(): JSX.Element {
  return (
    <div className={styles.Toast}>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        closeButton={CloseButton}
        limit={3}
        icon={false}
      />
    </div>
  );
}

export const showToast = (
  toastId: string,
  title: string,
  content?: string,
  type: TypeOptions = 'info',
  autoClose?: number,
  onClick?: () => void,
  buttonText?: String
) => {
  toast(
    <ToastBody
      title={title}
      content={content}
      type={type}
      onClick={onClick}
      buttonText={buttonText}
    />,
    {
      toastId,
      type,
      autoClose: autoClose ? autoClose : false,
    }
  );
};
