import { Logtail } from '@logtail/browser';
import { ILogtailLog } from '@logtail/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getUserEmail,
  getUserId,
  getUserRole,
  getUserSessionId,
} from 'redux/user';

import { version } from '../../package.json';

export default function useLogtail() {
  const [logtailClient] = useState<Logtail>(
    new Logtail('Wve8XVnwE4sA1A3a7TuUTBCx')
  );

  const userId = useSelector(getUserId);
  const userEmail = useSelector(getUserEmail);
  const userRole = useSelector(getUserRole);
  const userSessionId = useSelector(getUserSessionId);
  const env = import.meta.env.REACT_APP_DEPLOY_ENV ?? 'unknown';

  useEffect(() => {
    const enrichLogs = async (log: ILogtailLog): Promise<ILogtailLog> => {
      return {
        ...log,
        env: env.toUpperCase(),
        version,
        sessionId: userSessionId,
        user: {
          id: userId,
          email: userEmail,
          role: userRole,
        },
      };
    };

    logtailClient.use(enrichLogs);
  }, [userId, userEmail, userRole, userSessionId, env, logtailClient]);

  return logtailClient;
}
