import { gql } from '@apollo/client';

export const StudentInfo = gql`
  fragment StudentInfo on students {
    id
    name
    schoolYear
    picture
    studentAccount {
      id
      email
    }
  }
`;

export const StudentOption = gql`
  fragment StudentOption on students {
    id
    name
    picture
  }
`;

export const StudentsEvents = gql`
  fragment StudentsEvents on students {
    id
    studentEvents {
      id
      event {
        id
        start
        end
      }
    }
  }
`;
